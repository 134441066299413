import React from 'react'

import ReactModal from 'react-modal'
import Modal from 'react-modal'
import { XCircle } from 'react-feather'
import { Translate, withLocalize } from 'react-localize-redux'

import './Modal.css'

class CBSModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showConfirmation: false,
    }
  }

  componentDidMount() {
    Modal.setAppElement('body')
  }

  handleContinueClick = () => {
    const { survey } = this.props

    window.open(survey.link, '_blank')

    this.setState({
      showConfirmation: true,
    })
  }

  handleDone = () => {
    this.props.handleAction()

    this.setState({
      showConfirmation: false,
    })
  }

  render() {
    const { showConfirmation } = this.state
    const { showModal, handleAction, onClose } = this.props

    return (
      <div>
        <ReactModal
          style={{
            overlay: { backgroundColor: 'rgba(33, 33, 33, 0.85)' },
            content: {
              maxWidth: 750,
              maxHeight: 400,
              width: '80%',
              margin: 'auto',
            },
          }}
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          closeTimeoutMS={200}
          onRequestClose={showConfirmation ? handleAction : onClose}
        >
          <XCircle
            size={32}
            onClick={showConfirmation ? handleAction : onClose}
            style={{ cursor: 'pointer', float: 'right' }}
          />
          <div className="container mt-5 text-center">
            {showConfirmation ? (
              <div>
                <h3>
                  <Translate id="CBSModal.cambridgeBrainSciences" />
                </h3>
                <p className="lead">
                  <Translate id="CBSModal.completeCambridgeSurvey" />
                </p>
                <button
                  className="btn btn-primary mt-5"
                  onClick={this.handleDone}
                >
                  <Translate id="CBSModal.doneBtn" />
                </button>
              </div>
            ) : (
              <div>
                <h3>
                  <Translate id="CBSModal.cambridgeBrainSciences" />
                </h3>
                <p className="lead">
                  <Translate id="CBSModal.bestExperience" />
                </p>
                <p className="muted-color">
                  <Translate id="CBSModal.surveyPrompt" />
                </p>
                <button
                  className="btn btn-primary mt-4"
                  onClick={this.handleContinueClick}
                >
                  <Translate id="CBSModal.continueBtn" />
                </button>
              </div>
            )}
          </div>
        </ReactModal>
      </div>
    )
  }
}

export default withLocalize(CBSModal)
