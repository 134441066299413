import React from 'react'

import ReactModal from 'react-modal'
import Modal from 'react-modal'
import { XCircle } from 'react-feather'

import Login from '../Account/Login'
import './Modal.css'

export class LoginModal extends React.Component {
  componentDidMount() {
    Modal.setAppElement('body')
  }

  render() {
    const { isAuthenticated, onClose, onSubmit } = this.props
    let { showModal } = this.props

    if (isAuthenticated) showModal = false

    return (
      <div>
        <ReactModal
          style={{
            overlay: { backgroundColor: 'rgba(33, 33, 33, 0.85)' },
            content: {
              maxWidth: 650,
              maxHeight: 375,
              width: '80%',
              margin: 'auto',
              overflow: 'hidden',
            },
          }}
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          closeTimeoutMS={200}
          onRequestClose={onClose}
        >
          <XCircle
            size={32}
            onClick={onClose}
            style={{ cursor: 'pointer', float: 'right' }}
          />
          <div className="text-center mt-5">
            <Login onClose={onClose} onSubmit={onSubmit} />
          </div>
        </ReactModal>
      </div>
    )
  }
}
