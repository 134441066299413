import React from 'react'
import ReactTooltip from 'react-tooltip'
import { HelpCircle } from 'react-feather'

export const Section = props => {
  const { heading, role, translate } = props
  const headArr = heading && heading.split(' ')
  let tip = ''

  if (role === 'parent') {
    if (heading === translate('section.header1'))
      tip = translate('section.tip1Parent')
    else if (heading === translate('section.header7'))
      tip = translate('section.tip2Parent')
    else if (heading === translate('section.header2'))
      tip = translate('section.tip3')
    else if (heading === translate('section.header3'))
      tip = translate('section.tip4')
    else if (heading === translate('section.header4'))
      tip = translate('section.tip5')
    else if (heading.includes(translate('section.header5')))
      tip = translate('section.tip6')
  } else if (role === 'child') {
    if (heading === translate('section.header1'))
      tip = translate('section.tip1Child')
    else if (heading === translate('section.header6'))
      tip = translate('section.tip7')
    else if (heading === translate('section.header7'))
      tip = translate('section.tip2Child')
    else if (heading === translate('section.header2'))
      tip = translate('section.tip3')
    else if (heading === translate('section.header3'))
      tip = translate('section.tip4')
    else if (heading === translate('section.header4'))
      tip = translate('section.tip8')
    else if (heading === translate('section.header8'))
      tip = translate('section.tip9')
    else if (heading === translate('section.header9'))
      tip = translate('section.tip10')
    else if (heading === translate('section.header10'))
      tip = translate('section.tip11')
    else if (heading === translate('section.header11'))
      tip = translate('section.tip12')
  }

  return (
    <div className="mt-4 mb-3">
      {headArr && (
        <div>
          {tip && (
            <ReactTooltip
              id="tooltip"
              place="right"
              effect="solid"
              type="light"
              multiline
              className="description-tooltip"
            />
          )}
          <h4
            className="d-inline-block mb-4 ml-2"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {headArr.shift() + ' '}
            <span className="text-primary">{headArr.join(' ')}</span>
          </h4>
          <HelpCircle
            size={22}
            className="ml-2 accent-color-1"
            style={{ cursor: 'pointer' }}
            data-tip={tip}
            data-for={'tooltip'}
          />
        </div>
      )}
    </div>
  )
}
