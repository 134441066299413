import React from 'react'

export class LondonConsent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      parent_name: '',
      name_of_person_obtaining_consent: '',
    }
  }

  validateComplete = () => {
    const { parent_name, name_of_person_obtaining_consent } = this.state

    let data = this.state

    if (parent_name && name_of_person_obtaining_consent) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (['parent_name', 'name_of_person_obtaining_consent'].includes(name)) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    }
  }

  render() {
    const { parent_name, name_of_person_obtaining_consent } = this.state

    return (
      <div className={'container-fluid'}>
        <div style={{ textAlign: 'center' }}>
          <h5 className="lead mt-3">
            Surveillance in High School to Reduce Concussions and Consequences
            of Concussions in Canadian Youth
          </h5>
          <h6 className="lead mt-3">
            <u>Letter of Information</u>
          </h6>
        </div>
        <br />
        <div className={'row'}>
          <div className={'col-5'}>
            <b>Dr. Carolyn Emery</b>
            <br />
            <b>Primary Investigator – ShRED</b>
            <br />
            <b>Professor</b>
            <br />
            <b>Sport Injury Prevention Research Centre</b>
            <br />
            <b>(SIPRC)</b>
            <br />
            <b style={{ color: 'blue' }}>caemery@ucalgary.ca</b>
            <br />
          </div>
          <div className={'col-7'}>
            <b>Stacey Wanlin</b>
            <br />
            <b>Research Coordinator</b>
            <br />
            <b style={{ color: 'blue' }}>swanlin@uwo.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-5'}>
            <b>Dr. Lisa Fischer</b>
            <br />
            <b>Primary Investigator – Western University</b>
            <br />
            <b>Professor</b>
            <br />
            <b>Schulich School of Medicine and Dentistry</b>
            <br />
            <b style={{ color: 'blue' }}>lfischer@uwo.ca</b>
            <br />
            <b>519-661-3011</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-7'}>
            <b>Michael Robinson</b>
            <br />
            <b>Research Assistant</b>
            <br />
            <b>Postdoctoral Fellow</b>
            <br />
            <b style={{ color: 'blue' }}>swanlin@uwo.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-5'}>
            <b>Dr. Ravi Menon</b>
            <br />
            <b>Co-Investigator</b>
            <br />
            <b style={{ color: 'blue' }}>rmenon@imaging.robarts.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-7'}>
            <b>Amy Robinson</b>
            <br />
            <b>Research Assistant</b>
            <br />
            <b style={{ color: 'blue' }}>arobin45@uwo.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-5'}>
            <b>Dr. Andrew Johnson</b>
            <br />
            <b>Co-Investigator</b>
            <br />
            <b style={{ color: 'blue' }}>ajohnson@uwo.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-7'}>
            <b>Clara Stafford</b>
            <br />
            <b>Research Assistant</b>
            <br />
            <b style={{ color: 'blue' }}>cstaffo2@uwo.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-5'}>
            <b>Dr. Adrian Owen</b>
            <br />
            <b>Co-Investigator</b>
            <br />
            <b style={{ color: 'blue' }}>uwocerc@uwo.ca</b>
            <br />
          </div>
        </div>

        <h5 className="lead mt-3">1. Invitation to Participate</h5>
        <p>
          You are being invited to participate in a research project lead by Dr.
          Lisa Fischer at Fowler Kennedy Sports Medicine Clinic because you are
          a high school-aged youth athlete who is at risk of obtaining a
          concussion.
        </p>
        <p>
          Take the time to read this carefully and to understand any
          accompanying information.
        </p>
        <p>
          In this Consent document, “you” always refers to the study
          participant. If you are a substitute decision maker (SDM) (i.e.
          someone who makes the decision of participation on behalf of a
          participant), please remember that “you” refers to the study patient.
          If an SDM is needed for this study, you will be asked to review and
          sign this consent form on behalf of the participant.
        </p>
        <p>
          Before you decide to participate in the Surveillance in High School to
          Reduce Concussions and Consequences of Concussions in Canadian Youth
          (SHRED), it is important you understand what is involved and what will
          be done with your information. This form contains answers to some of
          the questions you may have.
        </p>

        <h5 className="lead mt-3">2. Why is this study being done?</h5>
        <p>
          This Pan-Canadian research program, “Surveillance in High Schools to
          Reduce Concussions and Consequences of Concussions–SHRed Concussions,”
          is a 3-year study to evaluate concussion diagnostic tools, predictors
          of recovery, and prevention and management strategies among high
          school-aged youth athletes. Youth account for over 50% of the annual
          burden of over 3 million concussions in North America. SHRed will
          inform a reduction in the burden of sport-related concussions (SRC)
          and their consequences across all youth sport populations.
        </p>
        <span>SHRed has four main aims:</span>
        <br />
        <ol>
          <li>
            To establish a national evidence-informed concussion surveillance
            program in high schools to support evaluation of concussion burden
            and predictors of recovery (e.g., time to symptom recovery, return
            to sport/school).
          </li>
          <li>
            To integrate multiple types of tools for sport related concussion in
            high school-aged athletes to inform development and validation of
            models to detect concussion and predict recovery.
          </li>
          <li>
            To evaluate the implementation, effectiveness, and sustainability of
            sport-specific and school-based prevention strategies, including: a)
            policy changes (e.g., contact policy, management protocols); b)
            training interventions (e.g., neuromuscular, head contact load
            modification); and c) equipment recommendations/development (e.g.,
            helmet fit, mouth guards, wearable technologies).
          </li>
          <li>
            To provide a platform for recruitment for treatment studies (e.g.,
            physiotherapy, cognitive therapy, exercise therapy) aimed to prevent
            long-term consequences of SRC (e.g., reduced physical activity,
            recurrent concussion, mental health, impaired school performance,
            economic costs).
          </li>
        </ol>
        <p>
          You will be followed for three years using an injury surveillance
          system that was developed and validated in large-scale youth sport
          studies to collect annual baseline measurements, track sport
          participation, and assess recovery following concussion. Research team
          staff will coordinate concussion follow-ups, including assessment by
          study physicians, MRI, and blood biomarker analyses. Standardized
          concussion definitions and return to activity/school guidelines will
          be based on the 5th International consensus statement on concussion in
          sport and Canadian Concussion Guidelines.
        </p>

        <h5 className="lead mt-3">3. How long will you be in this study?</h5>
        <p>You will be in the study for 3 years.</p>

        <h5 className="lead mt-3">
          4. How many people will take part in this study?
        </h5>
        <p>
          Up to 6000 people will participate in this study and we anticipate
          that up to 100 will be enrolled at this institution.
        </p>

        <h5 className="lead mt-3">5. What are the study procedures?</h5>
        <p>
          <span>
            After providing parental consent and participant assent you will
            undergo a series of baseline tests:
          </span>
          <br />
          <ol>
            <li>
              <span>Baseline Questionnaire</span>
              <br />
              <ol type={'a'}>
                <li>
                  Includes questions regarding injury history, activity history
                  and concussion knowledge and beliefs
                </li>
              </ol>
            </li>
            <li>
              <span>Sport Specific Questionnaire</span>
              <br />
              <ol type={'a'}>
                <li>
                  Includes questions on sports that the participant may be
                  involved in within the next 12 months
                </li>
              </ol>
            </li>
            <li>
              <span>Psychosocial Questionnaires</span>
              <br />
              <ol type={'a'}>
                <li>
                  Connor-Davidson Resilience Scale (monitors how well one is
                  equipped to bounce back after trauma)
                </li>
                <li>
                  Strength and Difficulties Questionnaire (monitors mental
                  health)
                </li>
                <li>
                  Pediatric quality of Life Inventory (monitors chronic medical
                  conditions)
                </li>
                <li>
                  Patient-Reported Outcomes Measurement Information System
                  (monitors physical, mental, and social health)
                </li>
              </ol>
            </li>
            <li>
              Cambridge Sciences Neurocognitive Tests (computerized tests that
              evaluate cognitive function like short-term memory, reasoning,
              attention and verbal ability)
            </li>
            <li>
              <span>Clinical Assessments</span>
              <br />
              <ul type={'a'}>
                <li>Cervical Spine (an evaluation of your neck)</li>
                <li>
                  Vestibular Oculomotor Screening Test (an evaluation of your
                  eyes and balance)
                </li>
                <li>
                  Vestibular-ocular Test (an evaluation of your eyes and
                  balance)
                </li>
                <li>Dynamic Visual Acuity (an evaluation of your eyes)</li>
                <li>
                  Grip Strength (an evaluation of how strong your grip is)
                </li>
                <li>Vertical Jump (an evaluation of how high you can jump)</li>
                <li>
                  Walking While Talking Test (an evaluation of how well you can
                  do a physical and mental activity at the same time)
                </li>
                <li>
                  Functional Gait Assessment (an evaluation of how you walk)
                </li>
                <li>
                  Sport Concussion Assessment Tool 5th Edition (an evaluation of
                  symptoms associated with concussion)
                </li>
              </ul>
            </li>
            <li>
              <span>Fitness Testing</span>
              <br />
              <ul type={'a'}>
                <li>
                  Modified 20 m Shuttle Run (an evaluation of general
                  cardiovascular fitness)
                </li>
              </ul>
            </li>
            <li>
              <span>Fluid Biomarkers</span>
              <ul type={'a'}>
                <li>
                  20 ml of Blood will be taken by a member of the study team
                  trained in phlebotomy and stored for future analysis at the
                  University of British Columbia.
                </li>
              </ul>
            </li>
          </ol>
        </p>

        <p>
          <span>
            Every week or in the case of injury you will complete two sets of
            forms:
          </span>
          <br />
          <ol>
            <li>
              Sport participation exposure forms will be completed to track the
              participants sport related activity
            </li>
            <li>
              <span>Oslo Sport Trauma Centre Questionnaire</span>
              <br />
              <ul type={'a'}>
                <li>
                  Weekly self-report injury forms will be completed in the event
                  a participant suffers any type of injury.
                </li>
              </ul>
            </li>
          </ol>
        </p>

        <p>
          <span>
            In the event of a concussion you will undergo a series of clinical
            tests:
          </span>
          <br />
          <ol>
            <li>Sport Medicine Physician Assessment</li>
            <li>
              Cambridge Sciences Neurocognitive Tests (computerized tests that
              evaluate cognitive function)
            </li>
            <li>
              <span>Clinical Assessments</span>
              <br />
              <ul type={'a'}>
                <li>Cervical Spine (an evaluation of your neck)</li>
                <li>
                  Vestibular Oculomotor Screening Test (an evaluation of your
                  eyes and balance)
                </li>
                <li>
                  Vestibular-ocular Test (an evaluation of your eyes and
                  balance)
                </li>
                <li>Dynamic Visual Acuity (an evaluation of your eyes)</li>
                <li>
                  Grip Strength (an evaluation of how strong your grip is)
                </li>
                <li>Vertical Jump (an evaluation of how high you can jump)</li>
                <li>
                  Walking While Talking Test (an evaluation of how well you can
                  do a physical and mental activity at the same time)
                </li>
                <li>
                  Functional Gait Assessment (an evaluation of how you walk)
                </li>
                <li>
                  Sport Concussion Assessment Tool 5th Edition (an evaluation of
                  symptoms associated with concussion)
                </li>
              </ul>
            </li>
            <li>Magnetic Resonance Imaging (MRI)</li>
            <li>Physical Activity Monitoring</li>
            <li>
              <span>Sporting Equipment Review</span>
              <br />
              <ul type={'a'}>
                <li>Helmet fit assessment</li>
                <li>Mouthguard use</li>
              </ul>
            </li>
            <li>
              <span>
                KinArm Robot Tests (a robotic assessment used with an augmented
                reality system for the objective clinical assessment following a
                brain injury) (a robotic assessment used with an augmented
                reality system for the objective clinical assessment following a
                brain injury)
              </span>
              <br />
              <ul type={'a'}>
                <li>Visually Guided Reaching</li>
                <li>Object Hit Task</li>
                <li>Visuomotor Rotation</li>
              </ul>
            </li>
            <li>
              <span>Psychosocial Questionnaires</span>
              <br />
              <ul type={'a'}>
                <li>
                  Connor-Davidson Resilience Scale (monitors how well one is
                  equipped to bounce back after trauma)
                </li>
                <li>
                  Strength and Difficulties Questionnaire (monitors mental
                  health)
                </li>
                <li>
                  Pediatric quality of Life Inventory (monitors chronic medical
                  conditions)
                </li>
                <li>
                  Patient-Reported Outcomes Measurement Information System
                  (monitors physical, mental, and social health)
                </li>
              </ul>
            </li>
            <li>
              <span>Fluid Biomarkers</span>
              <br />
              <ul type={'a'}>
                <li>
                  Blood will be taken by a member of the study team trained in
                  phlebotomy and stored for future analysis at the University of
                  British Columbia.
                </li>
              </ul>
            </li>
          </ol>
        </p>

        <p>
          <span>
            One week after the concussion you will undergo the following:
          </span>
          <br />
          <ol>
            <li>
              <span>Fluid Biomarkers</span>
              <br />
              <ul type={'a'}>
                <li>
                  Blood will be taken by a member of the study team trained in
                  phlebotomy and stored for future analysis at the University of
                  British Columbia.
                </li>
              </ul>
            </li>
            <li>
              Cambridge Sciences Neurocognitive Tests (computerized tests that
              evaluate cognitive function)
            </li>
          </ol>
        </p>

        <p>
          <span>
            Every two weeks after the concussion the participants will undergo
            the following:
          </span>
          <br />
          <li>
            <span>Psychosocial Questionnaires</span>
            <br />
            <ul type={'a'}>
              <li>
                Connor-Davidson Resilience Scale (monitors how well one is
                equipped to bounce back after trauma)
              </li>
              <li>
                Strength and Difficulties Questionnaire (monitors mental health)
              </li>
              <li>
                Pediatric quality of Life Inventory (monitors chronic medical
                conditions)
              </li>
              <li>
                Patient-Reported Outcomes Measurement Information System
                (monitors physical, mental, and social health)
              </li>
            </ul>
          </li>
          <li>
            <span>Fluid Biomarkers</span>
            <br />
            <ul type={'a'}>
              <li>
                Blood will be taken by a member of the study team trained in
                phlebotomy and stored for future analysis at the University of
                British Columbia.
              </li>
            </ul>
          </li>
          <li>
            Cambridge Sciences Neurocognitive Tests (computerized tests that
            evaluate cognitive function)
          </li>
          <li>
            <span>Clinical Assessments</span>
            <br />
            <ul type={'a'}>
              <li>Cervical Spine (an evaluation of your neck)</li>
              <li>
                Vestibular Oculomotor Screening Test (an evaluation of your eyes
                and balance)
              </li>
              <li>
                Vestibular-ocular Test (an evaluation of your eyes and balance)
              </li>
              <li>Dynamic Visual Acuity (an evaluation of your eyes)</li>
              <li>Grip Strength (an evaluation of how strong your grip is)</li>
              <li>Vertical Jump (an evaluation of how high you can jump)</li>
              <li>
                Walking While Talking Test (an evaluation of how well you can do
                a physical and mental activity at the same time)
              </li>
              <li>
                Functional Gait Assessment (an evaluation of how you walk)
              </li>
              <li>
                Sport Concussion Assessment Tool 5th Edition (an evaluation of
                symptoms associated with concussion)
              </li>
            </ul>
          </li>
        </p>

        <p>
          <span>
            Thirty (30) days post-concussion you will undergo the following:
          </span>
          <br />
          <ol>
            <li>Magnetic Resonance Imaging (MRI)</li>
          </ol>
        </p>

        <p>
          <span>
            Once you have been cleared to return to sport you will undergo the
            following:
          </span>
          <br />
          <ol>
            <li>
              <span>Psychosocial Questionnaires</span>
              <br />
              <ul type={'a'}>
                <li>
                  Connor-Davidson Resilience Scale (monitors how well one is
                  equipped to bounce back after trauma)
                </li>
                <li>
                  Strength and Difficulties Questionnaire (monitors mental
                  health)
                </li>
                <li>
                  Pediatric quality of Life Inventory (monitors chronic medical
                  conditions)
                </li>
                <li>
                  Patient-Reported Outcomes Measurement Information System
                  (monitors physical, mental, and social health)
                </li>
              </ul>
            </li>
            <li>
              Cambridge Sciences Neurocognitive Tests (computerized tests that
              evaluate cognitive function)
            </li>
            <li>
              <span>Clinical Assessments</span>
              <br />
              <ul type={'a'}>
                <li>Cervical Spine (an evaluation of your neck)</li>
                <li>
                  Vestibular Oculomotor Screening Test (an evaluation of your
                  eyes and balance)
                </li>
                <li>
                  Vestibular-ocular Test (an evaluation of your eyes and
                  balance)
                </li>
                <li>Dynamic Visual Acuity (an evaluation of your eyes)</li>
                <li>
                  Grip Strength (an evaluation of how strong your grip is)
                </li>
                <li>Vertical Jump (an evaluation of how high you can jump)</li>
                <li>
                  Walking While Talking Test (an evaluation of how well you can
                  do a physical and mental activity at the same time)
                </li>
                <li>
                  Functional Gait Assessment (an evaluation of how you walk)
                </li>
                <li>
                  Sport Concussion Assessment Tool 5th Edition (an evaluation of
                  symptoms associated with concussion)
                </li>
              </ul>
            </li>
            <li>
              <span>Fitness Testing</span>
              <br />
              <ul type={'a'}>
                <li>
                  Modified 20 m Shuttle Run (an evaluation of general
                  cardiovascular fitness)
                </li>
              </ul>
            </li>
            <li>
              <span>Fluid Biomarkers</span>
              <br />
              <ul type={'a'}>
                <li>
                  Blood will be taken by a member of the study team trained in
                  phlebotomy and stored for future analysis at the University of
                  British Columbia.
                </li>
              </ul>
            </li>
            <li>
              <span>
                KinArm Robot Tests (a robotic assessment used with an augmented
                reality system for the objective clinical assessment following a
                brain injury)
              </span>
              <br />
              <ul type={'a'}>
                <li>Visually Guided Reaching</li>
                <li>Object Hit Task</li>
                <li>Visuomotor Rotation</li>
              </ul>
            </li>
          </ol>
        </p>

        <h5 className="lead mt-3">
          6. What are the risks and harms of participating in this study?
        </h5>
        <p>
          There are limited anticipated risks if you consent to participate in
          the SHRed Concussions Research Program. All outcome measures have been
          previously used in a youth sport setting with no negative side
          effects.
        </p>
        <p>
          Blood samples collected for SHRed participants, assent or consent will
          be voluntary and re-established for each blood draw. Fear of blood
          drawing can be effectively managed in adolescents, including those
          with anxiety disorder. Approximately 5-10% of adolescents have a fear
          of needles, which may trigger a normal reflex that slows the heart and
          reduces blood pressure, sometimes to the point of fainting. To address
          the psychological and physical risks associated with blood collection
          from 13-18 year old adolescents, we will adapt best practices for
          adolescent blood collection. Blood will be drawn in a private setting
          by a phlebotomist trained in adolescent collection best practices,
          including taking extra time for procedure explanation and preparation,
          understanding that the participant may be embarrassed to show fear or
          anxiety about the procedure.
        </p>
        <p>
          There are no known long-term effects of MR imaging; however, MRI
          carries a remote risk of injury for which participants will be
          carefully screened and monitored using a screening form before the
          imaging session.
        </p>

        <h5 className="lead mt-3">
          7. What are the benefits of participating in this study?
        </h5>
        <p>
          Potential Benefits of the research to human participants include those
          participants who sustained SRC and received assessment and management
          according to the Canadian Guideline on Concussion in Sport (CGCS).
          Teachers and coaches at participating schools will receive training on
          the use of the Concussion Recognition Tool 5 (CRT5). You will not
          receive any compensation for participating.
        </p>

        <h5 className="lead mt-3">
          8. Can participants choose to leave the study?
        </h5>
        <p>
          You can withdraw from the study for any reason at any time by
          contacting one of the research personnel listed above. Additionally,
          you can withdraw your stored blood samples at any time by contacting
          one of the research personnel listed above.
        </p>

        <h5 className="lead mt-3">
          9. How will participants’ information be kept confidential?
        </h5>
        <p>
          Participant’s information will be stored on a secure data collection
          system called Redcap. Redcap meets the Personal Health Information
          Protection and Privacy Act (PHIPPA) standards. All study data will be
          entered into the SHRed database directly by participant, study
          therapist, research coordinator, research assistants, and treating
          physician. The SHRed database is hosted and maintained by the
          University of Calgary in B.C.
        </p>
        <p>
          Your data will be stored for a period of 15 years and will be
          destroyed to ensure confidentiality of the information. Local study
          personnel, Carolyn Emory (University of Calgary - PI), Shane Easu
          (University of Calgary - Research Coordinator), National Clinic
          Coordinator, Quality Control personnel, clinical personnel, Western
          University’s Health Science Research Ethics Board (HSREB) and Lawson’s
          Quality Assurance will have access to identifiable information and
          your name and personal information will never be given out or used in
          any publications. University of Calgary has access to the master code
          which links your identifying information to your unique study ID to
          monitor quality control. Any biological specimens will be stored in a
          secure cold storage facility and specimens will be identified using an
          identifier code and will not have any personally identifiable
          information on them.
        </p>

        <h5 className="lead mt-3">10. Long Term Storage of Biospecimens</h5>
        <p>
          The blood samples that taken from you will be sent off site for long
          term storage in dedicated, alarmed and secured -80C freezers at the
          University of British Columbia and will be retained for up to 10 years
          after the end of the study to enable retrospective analyses, including
          using assays not yet available, by the investigative team and approved
          collaborators:
        </p>
        <p>
          Dr. Cheryl Wellington
          <br />
          Djavad Mowafaghian Centre for Brain Health
          <br />
          University of British Columbia
          <br />
          2215 Wesbrook Mall
          <br />
          Vancouver BC Canada V6T 1Z3
          <br />
          Email: wcheryl@mail.ubc.ca
          <br />
          Tel: 604-827-3769
        </p>

        <h5 className="lead mt-3">11. Cambridge Brain Sciences</h5>
        <p>
          To access the Cambridge Brain Sciences (CBS) website the researchers
          of this study will provide you with a random identifier, for example
          ‘abc123’ instead of using your personal email. No other personal
          information will be collected.
        </p>
        <p>
          It is also important to note that Cambridge Brain Sciences (CBS) will
          also record your internet protocol (IP) addresses. Storage of your IP
          address runs the risk of additional privacy breaches that is
          associated with your IP address for example your network, device or
          service. Your IP address also provides information on the following
          areas, online services for which an individual has registered;
          personal interests, based on websites visited; and organizational
          affiliations.
        </p>

        <h5 className="lead mt-3">
          12. Are participants compensated to be in this study?
        </h5>
        <p>
          You will not be compensated however any associated costs (parking)
          will be covered.
        </p>

        <h5 className="lead mt-3">13. What are the rights of participants?</h5>
        <p>You do not waive any legal right by signing this consent form</p>

        <h5 className="lead mt-3">
          14. Whom do participants contact for questions?
        </h5>
        <p>
          You can contact any of the members of the study team listed above if
          they have any questions. If you have any questions about your rights
          as a research participant or the conduct of this study, you may
          contact The Office of Human Research Ethics (519) 661-3036, 1-844-720-
          9816, email: ethics@uwo.ca. The REB is a group of people who oversee
          the ethical conduct of research studies. The HSREB is not part of the
          study team. Everything that you discuss will be kept confidential.
        </p>
        <hr />
        <div style={{ textAlign: 'center' }}>
          <h5 className="lead mt-3">
            <u>Consent</u>
          </h5>
        </div>
        <p>
          This study has been explained to me and any questions I had have been
          answered. I know that I may leave the study at any time. I agree to
          take part in this study.
        </p>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Parent/Representative Name:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_name"
              name="parent_name"
              value={parent_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <p>
          <b>
            My signature means that I have explained the study to the
            participant named above. I have answered all questions.
          </b>
        </p>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Name of Person Obtaining Consent:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="name_of_person_obtaining_consent"
              name="name_of_person_obtaining_consent"
              value={name_of_person_obtaining_consent}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <hr />
      </div>
    )
  }
}
