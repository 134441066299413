import React from "react";

import ReactModal from "react-modal";
import Modal from "react-modal";
import { XCircle } from "react-feather";
import { withLocalize, Translate } from "react-localize-redux";
import "./Modal.css";

class ConfirmationModal extends React.Component {
  componentDidMount() {
    Modal.setAppElement("body");
  }

  render() {
    const {
      showModal,
      onClose,
      handleAction,
      action,
      message,
      extra
    } = this.props;

    return (
      <div>
        <ReactModal
          style={{
            overlay: { backgroundColor: "rgba(33, 33, 33, 0.85)" },
            content: {
              maxWidth: 600,
              maxHeight: 350,
              width: "80%",
              margin: "auto"
            }
          }}
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          closeTimeoutMS={200}
          onRequestClose={onClose}
        >
          <XCircle
            size={32}
            onClick={onClose}
            style={{ cursor: "pointer", float: "right" }}
          />
          <div className="container mt-5 text-center">
            <h3>
              <Translate id="confirmationModal.confirmationMessage" />
              {action}?
            </h3>
            <span className="lead">{message}</span>
            {extra ? (
              <div>
                <br />
                <b>{extra}</b>
              </div>
            ) : null}
            <div style={{ height: "55px" }}></div>
            <button
              className="btn btn-primary"
              onClick={onClose}
              style={{ marginRight: "12px", width: "75px" }}
            >
              <Translate id="confirmationModal.no" />
            </button>
            <button
              className="btn btn-primary"
              onClick={handleAction}
              style={{ width: "75px" }}
            >
              <Translate id="confirmationModal.yes" />
            </button>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default withLocalize(ConfirmationModal);
