import { CancelToken } from 'axios'

import { handleAxiosError } from '../helpers'
import { instance } from '../helpers'

export const userServices = {
  create,
  update,
  get,
  destroy,
}

function create(params) {
  return instance
    .post('/users', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function update(params) {
  return instance
    .put('/users', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function get(cancel) {
  return instance
    .get('/users', { cancelToken: new CancelToken(c => (cancel.exec = c)) })
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function destroy(type) {
  return instance
    .delete('/users?' + type)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
