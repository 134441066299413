import React from 'react'

export class LondonAssent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      child_name: '',
      child_age: '',
      name_of_person_obtaining_assent: '',
    }
  }

  validateComplete = () => {
    const {
      child_name,
      child_age,
      name_of_person_obtaining_assent,
    } = this.state

    let data = this.state

    if (child_name && child_age && name_of_person_obtaining_assent) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (
      ['child_name', 'child_age', 'name_of_person_obtaining_assent'].includes(
        name
      )
    ) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    }
  }

  render() {
    const {
      child_name,
      child_age,
      name_of_person_obtaining_assent,
    } = this.state

    return (
      <div className={'container-fluid'}>
        <div style={{ textAlign: 'center' }}>
          <h5 className="lead mt-3">
            Surveillance in High School to Reduce Concussions and Consequences
            of Concussions in Canadian Youth
          </h5>
          <h6 className="lead mt-3">
            <u>Assent Letter</u>
          </h6>
        </div>
        <br />
        <div className={'row'}>
          <div className={'col-5'}>
            <b>Dr. Carolyn Emery</b>
            <br />
            <b>Primary Investigator – ShRED</b>
            <br />
            <b>Professor</b>
            <br />
            <b>Sport Injury Prevention Research Centre</b>
            <br />
            <b>(SIPRC)</b>
            <br />
            <b style={{ color: 'blue' }}>caemery@ucalgary.ca</b>
            <br />
          </div>
          <div className={'col-7'}>
            <b>Stacey Wanlin</b>
            <br />
            <b>Research Coordinator</b>
            <br />
            <b style={{ color: 'blue' }}>swanlin@uwo.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-5'}>
            <b>Dr. Lisa Fischer</b>
            <br />
            <b>Primary Investigator – Western University</b>
            <br />
            <b>Professor</b>
            <br />
            <b>Schulich School of Medicine and Dentistry</b>
            <br />
            <b style={{ color: 'blue' }}>lfischer@uwo.ca</b>
            <br />
            <b>519-661-3011</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-7'}>
            <b>Michael Robinson</b>
            <br />
            <b>Research Assistant</b>
            <br />
            <b>Postdoctoral Fellow</b>
            <br />
            <b style={{ color: 'blue' }}>swanlin@uwo.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-5'}>
            <b>Dr. Ravi Menon</b>
            <br />
            <b>Co-Investigator</b>
            <br />
            <b style={{ color: 'blue' }}>rmenon@imaging.robarts.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-7'}>
            <b>Amy Robinson</b>
            <br />
            <b>Research Assistant</b>
            <br />
            <b style={{ color: 'blue' }}>arobin45@uwo.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-5'}>
            <b>Dr. Andrew Johnson</b>
            <br />
            <b>Co-Investigator</b>
            <br />
            <b style={{ color: 'blue' }}>ajohnson@uwo.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-7'}>
            <b>Clara Stafford</b>
            <br />
            <b>Research Assistant</b>
            <br />
            <b style={{ color: 'blue' }}>cstaffo2@uwo.ca</b>
            <br />
          </div>
          <div style={{ marginTop: '1rem' }} className={'col-5'}>
            <b>Dr. Adrian Owen</b>
            <br />
            <b>Co-Investigator</b>
            <br />
            <b style={{ color: 'blue' }}>uwocerc@uwo.ca</b>
            <br />
          </div>
        </div>

        <h5 className="lead mt-3">1. Why are you here?</h5>
        <p>
          Dr. Fischer wants to tell you about a study that will look at
          concussions in high school athletes. She wants to see if you would
          like to be in this study. Other researchers will also work with Dr.
          Fischer on this study.
        </p>

        <h5 className="lead mt-3">2. Why are they doing this study?</h5>
        <p>
          This study is being done to evaluate concussion diagnostic tools, to
          identify things that may tell us how long athletes will take to
          recover from a concussion and help make the prevention and management
          strategies better.
        </p>

        <h5 className="lead mt-3">3. What will happen to you?</h5>
        <p>
          <u>
            After providing parental consent and participant assent participants
            will undergo a series of baseline tests:
          </u>
          <br />
          <ol>
            <li>
              <span>Baseline Questionnaire</span>
              <br />
              <ol type={'a'}>
                <li>
                  Includes questions regarding injury history, activity history
                  and concussion knowledge and beliefs
                </li>
              </ol>
            </li>
            <li>
              <span>Sport Specific Questionnaire</span>
              <br />
              <ol type={'a'}>
                <li>
                  Includes questions on sports that the participant may be
                  involved in within the next 12 months
                </li>
              </ol>
            </li>
            <li>
              <span>Psychosocial Questionnaires</span>
              <br />
              <ol type={'a'}>
                <li>
                  Connor-Davidson Resilience Scale (monitors how well one is
                  equipped to bounce back after trauma)
                </li>
                <li>
                  Strength and Difficulties Questionnaire (monitors mental
                  health)
                </li>
                <li>
                  Pediatric quality of Life Inventory (monitors chronic medical
                  conditions)
                </li>
                <li>
                  Patient-Reported Outcomes Measurement Information System
                  (monitors physical, mental, and social health)
                </li>
              </ol>
            </li>
            <li>
              Cambridge Sciences Neurocognitive Tests (computerized tests that
              evaluate cognitive function like short-term memory, reasoning,
              attention and verbal ability)
            </li>
            <li>
              <span>Clinical Assessments</span>
              <br />
              <ul type={'a'}>
                <li>Cervical Spine (an evaluation of your neck)</li>
                <li>
                  Vestibular Oculomotor Screening Test (an evaluation of your
                  eyes and balance)
                </li>
                <li>
                  Vestibular-ocular Test (an evaluation of your eyes and
                  balance)
                </li>
                <li>Dynamic Visual Acuity (an evaluation of your eyes)</li>
                <li>
                  Grip Strength (an evaluation of how strong your grip is)
                </li>
                <li>Vertical Jump (an evaluation of how high you can jump)</li>
                <li>
                  Walking While Talking Test (an evaluation of how well you can
                  do a physical and mental activity at the same time)
                </li>
                <li>
                  Functional Gait Assessment (an evaluation of how you walk)
                </li>
                <li>
                  Sport Concussion Assessment Tool 5th Edition (an evaluation of
                  symptoms associated with concussion)
                </li>
              </ul>
            </li>
            <li>
              <span>Fitness Testing</span>
              <br />
              <ul type={'a'}>
                <li>
                  Modified 20 m Shuttle Run (an evaluation of general
                  cardiovascular fitness)
                </li>
              </ul>
            </li>
            <li>
              <span>Fluid Biomarkers</span>
              <ul type={'a'}>
                <li>
                  Blood will be taken by a member of the study team trained in
                  phlebotomy and stored for future analysis at the University of
                  British Columbia.
                </li>
              </ul>
            </li>
          </ol>
        </p>

        <p>
          <u>
            Every week or in the case of injury you will complete two sets of
            forms:
          </u>
          <br />
          <ol>
            <li>
              Sport participation exposure forms will be completed to track the
              participants sport related activity
            </li>
            <li>
              <span>Oslo Sport Trauma Centre Questionnaire</span>
              <br />
              <ul type={'a'}>
                <li>
                  Weekly self-report injury forms will be completed in the event
                  a participant suffers any type of injury.
                </li>
              </ul>
            </li>
          </ol>
        </p>

        <p>
          <u>
            In the event of a concussion you will undergo a series of clinical
            tests:
          </u>
          <br />
          <ol>
            <li>Sport Medicine Physician Assessment</li>
            <li>
              Cambridge Sciences Neurocognitive Tests (computerized tests that
              evaluate cognitive function)
            </li>
            <li>
              <span>Clinical Assessments</span>
              <br />
              <ul type={'a'}>
                <li>Cervical Spine (an evaluation of your neck)</li>
                <li>
                  Vestibular Oculomotor Screening Test (an evaluation of your
                  eyes and balance)
                </li>
                <li>
                  Vestibular-ocular Test (an evaluation of your eyes and
                  balance)
                </li>
                <li>Dynamic Visual Acuity (an evaluation of your eyes)</li>
                <li>
                  Grip Strength (an evaluation of how strong your grip is)
                </li>
                <li>Vertical Jump (an evaluation of how high you can jump)</li>
                <li>
                  Walking While Talking Test (an evaluation of how well you can
                  do a physical and mental activity at the same time)
                </li>
                <li>
                  Functional Gait Assessment (an evaluation of how you walk)
                </li>
                <li>
                  Sport Concussion Assessment Tool 5th Edition (an evaluation of
                  symptoms associated with concussion)
                </li>
              </ul>
            </li>
            <li>Magnetic Resonance Imaging (MRI)</li>
            <li>Physical Activity Monitoring</li>
            <li>
              <span>Sporting Equipment Review</span>
              <br />
              <ul type={'a'}>
                <li>Helmet fit assessment</li>
                <li>Mouthguard use</li>
              </ul>
            </li>
            <li>
              <span>
                KinArm Robot Tests (a robotic assessment used with an augmented
                reality system for the objective clinical assessment following a
                brain injury) (a robotic assessment used with an augmented
                reality system for the objective clinical assessment following a
                brain injury)
              </span>
              <br />
              <ul type={'a'}>
                <li>Visually Guided Reaching</li>
                <li>Object Hit Task</li>
                <li>Visuomotor Rotation</li>
              </ul>
            </li>
            <li>
              <span>Psychosocial Questionnaires</span>
              <br />
              <ul type={'a'}>
                <li>
                  Connor-Davidson Resilience Scale (monitors how well one is
                  equipped to bounce back after trauma)
                </li>
                <li>
                  Strength and Difficulties Questionnaire (monitors mental
                  health)
                </li>
                <li>
                  Pediatric quality of Life Inventory (monitors chronic medical
                  conditions)
                </li>
                <li>
                  Patient-Reported Outcomes Measurement Information System
                  (monitors physical, mental, and social health)
                </li>
              </ul>
            </li>
            <li>
              <span>Fluid Biomarkers</span>
              <br />
              <ul type={'a'}>
                <li>
                  Blood will be taken by a member of the study team trained in
                  phlebotomy and stored for future analysis at the University of
                  British Columbia.
                </li>
              </ul>
            </li>
          </ol>
        </p>

        <p>
          <b>
            <u>
              One week after the concussion the participants will undergo the
              following:
            </u>
          </b>
          <br />
          <ol>
            <li>
              <span>Fluid Biomarkers</span>
              <br />
              <ul type={'a'}>
                <li>
                  Blood will be taken by a member of the study team trained in
                  phlebotomy and stored for future analysis at the University of
                  British Columbia.
                </li>
              </ul>
            </li>
            <li>
              Cambridge Sciences Neurocognitive Tests (computerized tests that
              evaluate cognitive function)
            </li>
          </ol>
        </p>

        <p>
          <u>
            Every two weeks after the concussion the participants will undergo
            the following:
          </u>
          <br />
          <li>
            <span>Psychosocial Questionnaires</span>
            <br />
            <ul type={'a'}>
              <li>
                Connor-Davidson Resilience Scale (monitors how well one is
                equipped to bounce back after trauma)
              </li>
              <li>
                Strength and Difficulties Questionnaire (monitors mental health)
              </li>
              <li>
                Pediatric quality of Life Inventory (monitors chronic medical
                conditions)
              </li>
              <li>
                Patient-Reported Outcomes Measurement Information System
                (monitors physical, mental, and social health)
              </li>
            </ul>
          </li>
          <li>
            <span>Fluid Biomarkers</span>
            <br />
            <ul type={'a'}>
              <li>
                Blood will be taken by a member of the study team trained in
                phlebotomy and stored for future analysis at the University of
                British Columbia.
              </li>
            </ul>
          </li>
          <li>
            Cambridge Sciences Neurocognitive Tests (computerized tests that
            evaluate cognitive function)
          </li>
          <li>
            <span>Clinical Assessments</span>
            <br />
            <ul type={'a'}>
              <li>Cervical Spine (an evaluation of your neck)</li>
              <li>
                Vestibular Oculomotor Screening Test (an evaluation of your eyes
                and balance)
              </li>
              <li>
                Vestibular-ocular Test (an evaluation of your eyes and balance)
              </li>
              <li>Dynamic Visual Acuity (an evaluation of your eyes)</li>
              <li>Grip Strength (an evaluation of how strong your grip is)</li>
              <li>Vertical Jump (an evaluation of how high you can jump)</li>
              <li>
                Walking While Talking Test (an evaluation of how well you can do
                a physical and mental activity at the same time)
              </li>
              <li>
                Functional Gait Assessment (an evaluation of how you walk)
              </li>
              <li>
                Sport Concussion Assessment Tool 5th Edition (an evaluation of
                symptoms associated with concussion)
              </li>
            </ul>
          </li>
        </p>

        <p>
          <u>
            Thirty (30) days post-concussion you will undergo the following:
          </u>
          <br />
          <ol>
            <li>Magnetic Resonance Imaging (MRI)</li>
          </ol>
        </p>

        <p>
          <u>
            Once you have been cleared to return to sport you will undergo the
            following:
          </u>
          <br />
          <ol>
            <li>
              <span>Psychosocial Questionnaires</span>
              <br />
              <ul type={'a'}>
                <li>
                  Connor-Davidson Resilience Scale (monitors how well one is
                  equipped to bounce back after trauma)
                </li>
                <li>
                  Strength and Difficulties Questionnaire (monitors mental
                  health)
                </li>
                <li>
                  Pediatric quality of Life Inventory (monitors chronic medical
                  conditions)
                </li>
                <li>
                  Patient-Reported Outcomes Measurement Information System
                  (monitors physical, mental, and social health)
                </li>
              </ul>
            </li>
            <li>
              Cambridge Sciences Neurocognitive Tests (computerized tests that
              evaluate cognitive function)
            </li>
            <li>
              <span>Clinical Assessments</span>
              <br />
              <ul type={'a'}>
                <li>Cervical Spine (an evaluation of your neck)</li>
                <li>
                  Vestibular Oculomotor Screening Test (an evaluation of your
                  eyes and balance)
                </li>
                <li>
                  Vestibular-ocular Test (an evaluation of your eyes and
                  balance)
                </li>
                <li>Dynamic Visual Acuity (an evaluation of your eyes)</li>
                <li>
                  Grip Strength (an evaluation of how strong your grip is)
                </li>
                <li>Vertical Jump (an evaluation of how high you can jump)</li>
                <li>
                  Walking While Talking Test (an evaluation of how well you can
                  do a physical and mental activity at the same time)
                </li>
                <li>
                  Functional Gait Assessment (an evaluation of how you walk)
                </li>
                <li>
                  Sport Concussion Assessment Tool 5th Edition (an evaluation of
                  symptoms associated with concussion)
                </li>
              </ul>
            </li>
            <li>
              <span>Fitness Testing</span>
              <br />
              <ul type={'a'}>
                <li>Modified 20 m Shuttle Run</li>
              </ul>
            </li>
            <li>
              <span>Fluid Biomarkers</span>
              <br />
              <ul type={'a'}>
                <li>
                  Blood will be taken by a member of the study team trained in
                  phlebotomy and stored for future analysis at the University of
                  British Columbia.
                </li>
              </ul>
            </li>
            <li>
              <span>
                KinArm Robot Tests (a robotic assessment used with an augmented
                reality system for the objective clinical assessment following a
                brain injury)
              </span>
              <br />
              <ul type={'a'}>
                <li>Visually Guided Reaching</li>
                <li>Object Hit Task</li>
                <li>Visuomotor Rotation</li>
              </ul>
            </li>
          </ol>
        </p>

        <h5 className="lead mt-3">4. Will there be any tests?</h5>
        <p>
          There will not be any tests or marks on the report card for this
          study.
        </p>

        <h5 className="lead mt-3">5. Will the study help you?</h5>
        <p>
          This study will not help you directly, but in the future, it might
          help others who suffer a concussion.
        </p>

        <h5 className="lead mt-3">6. Do you have to be in the study?</h5>
        <p>
          You do not have to be in the study. No one will be mad at you if you
          do not want to do this. If you do not want to be in the study, tell
          the study researchers or your parents. Even if you say yes, you can
          change your mind later. It is up to you.
        </p>

        <h5 className="lead mt-3">
          7. Who will be able to access my information?
        </h5>
        <p>
          Participants information will be stored on a secure data collection
          system called Redcap. Redcap meets the Personal Health Information
          Protection and Privacy Act (PHIPPA) standards. All study data will be
          entered into the SHRed database directly by participant, study
          therapist, research coordinator, research assistants, and treating
          physician. The SHRed database is hosted and maintained by the
          University of Calgary in B.C. Participant’s data will be stored for a
          period of 15 years and will be destroyed to ensure confidentiality of
          the information. Local study personnel, Carolyn Emory (University of
          Calgary - PI), Shane Easu (University of Calgary - Research
          Coordinator), National Clinic Coordinator, Quality Control personnel,
          clinical personnel, Western University’s Health Sciences Research
          Ethics Board (HSREB) and Lawson’s Quality Assurance will have access
          to identifiable information and your name and personal information
          will never be given out or used in any publications. University of
          Calgary has access to the master code which links your identifying
          information to your unique study ID to monitor quality control. Any
          biological specimens will be stored in a secure cold storage facility
          and specimens will be identified using an identifier code and will not
          have any personally identifiable information on them.
        </p>

        <h5 className="lead mt-3">8. What if you have any questions?</h5>
        <p>
          You can ask questions at any time, now or later. You can talk to your
          teachers, coaches, family or any of the study researchers.
        </p>

        <p>
          <b>Dr. Lisa Fischer</b>
          <br />
          <b>Primary Investigator – Western University</b>
          <br />
          <b>Professor</b>
          <br />
          <b>Schulich School of Medicine and Dentistry</b>
          <br />
          <b style={{ color: 'blue' }}>lfischer@uwo.ca</b>
          <br />
          <b>519-661-3011</b>
          <br />
        </p>
        <hr />
        <h5 className="lead mt-3">9. Consent</h5>
        <p>I want to participate in this study</p>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Name of Child:</label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_name"
              name="child_name"
              value={child_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Age:</label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_age"
              name="child_age"
              value={child_age}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Name of Person Obtaining Assent:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="name_of_person_obtaining_assent"
              name="name_of_person_obtaining_assent"
              value={name_of_person_obtaining_assent}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <hr />
      </div>
    )
  }
}
