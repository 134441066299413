import { handleAxiosError } from '../helpers'
import { instance } from '../helpers'

export const sportsEnrollmentServices = {
  post,
}

function post(params) {
  return instance
    .post('/sports-enrollment', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
