import React from 'react'
import { withRouter } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notify-toast'
import { renderToStaticMarkup } from 'react-dom/server'

import globalTranslations from '../../localize/global.json'

import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { Banner } from '../../components/Banner/Banner'

class App extends React.Component {
  constructor(props) {
    super(props)

    const languages = [
      { name: 'English', code: 'en' },
      { name: 'French', code: 'fr' },
    ]
    const defaultLanguage =
      sessionStorage.getItem('languageCode') || languages[0].code

    this.props.initialize({
      languages: languages,
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: defaultLanguage,
      },
    })
    this.props.addTranslation(globalTranslations)
  }

  componentDidUpdate(prevProps) {
    const prevLanguageCode =
      prevProps.activeLanguage && prevProps.activeLanguage.code
    const currentLanguageCode =
      this.props.activeLanguage && this.props.activeLanguage.code

    const hasLanguageChanged = prevLanguageCode !== currentLanguageCode

    if (hasLanguageChanged) {
      sessionStorage.setItem('languageCode', currentLanguageCode)
    }
  }

  render() {
    const landingPage = this.props.location
      ? this.props.location.pathname === '/'
      : false

    return (
      <div style={{ overflowX: 'hidden' }}>
        <Notifications />
        <Header />
        {!landingPage && <Banner />}
        <div
          className={!landingPage ? 'mt-page mb-page' : ''}
          style={{ minHeight: '50vh' }}
        >
          {this.props.children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default withLocalize(withRouter(App))
