import React from 'react'

import { AlertCircle } from 'react-feather/'
import { CheckCircle } from 'react-feather'
import { Clock } from 'react-feather'
import { BarChart2 } from 'react-feather'
import { Edit3 } from 'react-feather'

export class StatusIcon extends React.Component {
  render() {
    const { survey } = this.props

    return (
      <div>
        {survey.instrument === 'pbq_sports_enrollment' ? (
          <Edit3 size={30} className="warning-color" />
        ) : survey.availability && !survey.completed && !survey.partial ? (
          <BarChart2 size={30} className="primary-color" />
        ) : survey.availability && !survey.completed && survey.partial ? (
          <AlertCircle size={30} className="warning-color" />
        ) : survey.available > 0 ? (
          <Clock size={30} className="muted-color" />
        ) : (
          <CheckCircle size={30} className="success-color" />
        )}
      </div>
    )
  }
}
