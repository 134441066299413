import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Redirect } from 'react-router-dom'

import { store } from '../../helpers/index'
import { sessionActions } from '../../actions'

export function requireNoAuthentication(Component) {
  class RequireNoAuthentication extends React.Component {
    componentDidMount() {
      const { dispatch } = store

      if (this.props.isAuthenticated)
        dispatch(push('/profile/dashboard?childTab=0&contentTab=baseline'))
      else {
        const token = sessionStorage.getItem('token')
        if (token) dispatch(sessionActions.destroy())
      }
    }

    render() {
      return (
        <div>
          {this.props.isAuthenticated ? (
            <Redirect to="/profile/dashboard?childTab=0&contentTab=baseline" />
          ) : (
            <Component {...this.props} />
          )}
        </div>
      )
    }
  }

  function mapStateToProps(state) {
    return {
      isAuthenticated: state.session.isAuthenticated,
    }
  }
  return connect(mapStateToProps)(RequireNoAuthentication)
}
