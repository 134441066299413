import React from 'react'
import { Link } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

export const NotFound = () => (
  <div style={{ height: '90vh', display: 'flex' }}>
    <div style={{ margin: '0 auto', marginTop: '45vh' }}>
      <h2>
        <Translate id="notFound.h2" />
      </h2>
      <p>
        <Translate id="notFound.p1" />
      </p>

      <div className="text-center">
        <p>
          <Link to="/">
            <Translate id="notFound.link" />
          </Link>
        </p>
      </div>
    </div>
  </div>
)
