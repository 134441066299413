import React from 'react'

import ReactModal from 'react-modal'

export const CloseSurvey = () => (
  <div>
    <ReactModal
      style={{
        overlay: { backgroundColor: '#fff', transition: 'none', transform: 'none', opacity: 1 },
        content: {
          width: '100%',
          height: '100%',
          border: 'none',
          transition: 'none',
          transform: 'none',
          opacity: 1
        }
      }}
      isOpen={true}
    ></ReactModal>
  </div>
)
