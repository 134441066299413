import React from "react";
import ReactModal from "react-modal";
import Modal from "react-modal";
import { XCircle } from "react-feather";
import { Plus } from "react-feather";
import { Transition, animated } from "react-spring/renderprops";
import { ClipLoader } from "react-spinners";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";

import WESportForm from "./WESportForm";
import { store } from "../../../helpers/index";
import "../Modal.css";
import "./WE.css";
import { recordsActions, alertActions, sportActions } from "../../../actions";
import { weeklyExposureServices } from "../../../services";

class WEModal extends React.Component {
  targetElement = null;
  constructor(props) {
    super(props);

    this.state = {
      data: { portal_id: "", sport_forms: [] },
      otherSports: [],
      formValid: true,
      loading: false,
      addSport: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.validate = this.validate.bind(this);
    this.filterOtherSports = this.filterOtherSports.bind(this);
  }
  componentDidMount() {
    Modal.setAppElement("body");
    this.targetElement = document.querySelector("#WEModal");
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = store;
    const { currentSports, baseline_portal_id, showModal, sports } = this.props;

    if (showModal && !prevProps.showModal) {
      if (!sports) dispatch(sportActions.get());
      let data = { portal_id: baseline_portal_id, sport_forms: [] };
      if (currentSports) {
        let j = 0;
        for (let i = 0; i < currentSports.length; i++) {
          if (currentSports[i].vip) {
            data.sport_forms[j] = {
              key: currentSports[i].id,
              sport_id: currentSports[i].code,
              sport_name: currentSports[i].name,
              total_training: "",
              total_training_hours: "",
              total_games: "",
              total_game_hours: ""
            };
            j++;
          }
        }
      }

      this.setState(
        {
          data: data
        },
        this.validate
      );
      this.filterOtherSports();
    }
  }

  filterOtherSports() {
    const { data } = this.state;
    const allSports = this.props.sports;

    let i = 0;
    let filteredSports = [];

    if (allSports) {
      while (i < allSports.length) {
        let j = 0;
        let match = false;

        while (j < data.sport_forms.length) {
          if (allSports[i].name === data.sport_forms[j].sport_name) {
            match = true;
            break;
          }
          j = j + 1;
        }

        if (!match) filteredSports.push(allSports[i]);

        i = i + 1;
      }
    }

    this.setState({
      otherSports: filteredSports
    });
  }

  handleChange(params) {
    const { data } = this.state;
    const { sports } = this.props;

    const index = data.sport_forms.findIndex(
      i => i.sport_name === params.sportName
    );

    if (index >= 0) {
      data.sport_forms[index][params.field] = params.value;

      if (params["field"] === "sport_name") {
        const n = sports.findIndex(i => i.sport_name === params.value);

        if (n >= 0) {
          data.sport_forms[index].id = sports[n].id;
        }
      }

      this.setState(
        {
          data: data
        },
        this.validate
      );
    }
  }

  handleRemove(sportName) {
    const { data } = this.state;

    const index = data.sport_forms.findIndex(i => i.sport_name === sportName);

    if (index >= 0) {
      data.sport_forms.splice(index, 1);

      this.setState(
        {
          data: data
        },
        this.validate
      );
      this.filterOtherSports();
    }
  }

  handleAdd() {
    const { data } = this.state;

    data.sport_forms.push({
      key: "other" + Math.random(),
      sport_id: "-1",
      sport_name: "other",
      total_training: "",
      total_training_hours: "",
      total_games: "",
      total_game_hours: ""
    });

    this.setState({
      data: data,
      formValid: false
    });
    this.filterOtherSports();
    this.validate();
  }

  validate() {
    const { data } = this.state;
    let valid = data.sport_forms.length ? true : false;
    let sportButton = true;

    for (let i = 0; i < data.sport_forms.length; i++) {
      if (data.sport_forms[i].sport_name === "other") {
        valid = false;
        sportButton = false;
        break;
      }
    }
    this.setState({
      formValid: valid,
      addSport: sportButton
    });
  }

  handleSubmit() {
    const { dispatch } = store;
    const { data } = this.state;
    const { baseline_portal_id } = this.props;

    this.setState({
      loading: true
    });

    weeklyExposureServices.post(data).then(
      response => {
        const surveyPath = "baseline";
        const surveyType = this.props.translate("WEModal.surveyType");
        const ssqSurveys = response.data["surveys"];
        if (ssqSurveys) {
          let records = JSON.parse(sessionStorage.getItem("records"));
          const index = records.findIndex(
            i => i.baseline_portal_id === baseline_portal_id
          );
          if (index >= 0) {
            const j = records[index].surveys[surveyPath].findIndex(
              i => i.type_name === surveyType
            );

            if (j >= 0) {
              records[index].surveys[surveyPath][j].surveys = ssqSurveys;
            } else {
              records[index].surveys[surveyPath].push({
                type_name: surveyType,
                surveys: ssqSurveys
              });
            }
            dispatch(recordsActions.refresh(records));
          }
        }

        this.setState({
          loading: false
        });
        dispatch(
          alertActions.success(
            this.props.translate("WEModal.weeklyActivitiesSubmitted")
          )
        );
        this.props.handleAction();
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors;
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e));
            });
          }
        }
        this.setState({
          loading: false
        });
      }
    );
  }

  render() {
    const { showModal, onClose } = this.props;
    const { data, otherSports, formValid, loading, addSport } = this.state;

    const getWEformlist = data => {
      const items = [];
      data.map((sport, index) =>
        items.push(
          <div key={sport.key} className="mb-3">
            <WESportForm
              sportName={sport.sport_name}
              otherSports={otherSports}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handleRemove={this.handleRemove}
            />
          </div>
        )
      );
      return (
        <Transition
          native={true}
          items={items}
          keys={item => item.key}
          from={{
            transform: "translate3d(-100%,0,0)",
            opacity: "0"
          }}
          enter={{
            transform: "translate3d(0,0,0)",
            opacity: "1"
          }}
          leave={{
            transform: "translate3d(75%,0,0)",
            opacity: "0"
          }}
          trail={75}
        >
          {item => props => <animated.div style={props}>{item}</animated.div>}
        </Transition>
      );
    };

    return (
      <div id="WEModal">
        <ReactModal
          style={{
            overlay: { backgroundColor: "rgba(33, 33, 33, 0.85)" },
            content: {
              maxWidth: "100%",
              margin: "auto",
              backgroundColor: "rgba(0, 0, 0, 0)",
              border: "none"
            }
          }}
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          closeTimeoutMS={200}
          onRequestClose={onClose}
        >
          <div className="container">
            <XCircle
              size={40}
              onClick={onClose}
              style={{ cursor: "pointer", color: "#ffffff", float: "right" }}
            />
            <h2 className="mt-3 mb-3" style={{ color: "#fff" }}>
              <Translate id="WEModal.weeklyExposure" />
            </h2>
            {data &&
              data.sport_forms.length > 0 &&
              getWEformlist(data.sport_forms)}
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {addSport && (
                <button
                  style={{ display: "flex", alignItems: "center" }}
                  className="btn-custom-add"
                  onClick={this.handleAdd}
                >
                  <Plus size={22} className="mr-2" />
                  <Translate id="WEModal.addAnotherSport" />
                </button>
              )}
            </div>
            <hr />
            <hr />
            <hr />

            <div className="row justify-content-center">
              <button
                className="btn btn-primary col-lg-2 text-center"
                onClick={this.handleSubmit}
                disabled={!formValid || loading}
              >
                {loading && (
                  <ClipLoader
                    loading={loading}
                    color={"#fff"}
                    css={{ marginRight: "0.65rem" }}
                    size={18}
                  />
                )}
                <Translate id="WEModal.submitBtn" />
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sports } = state.sport;

  return {
    sports
  };
}

export default withLocalize(connect(mapStateToProps)(WEModal));
