import React from 'react'

export const OttawaConsent = props => {
  return (
    <div>
      <h4>{props.translate('ottawaConsent.t1')}</h4>
      <p>{props.translate('ottawaConsent.t2')}</p>
      <p>{props.translate('ottawaConsent.t3')}</p>
      <p>{props.translate('ottawaConsent.t4')}</p>
      <p>{props.translate('ottawaConsent.t5')}</p>
      <p>{props.translate('ottawaConsent.t6')}</p>
      <p>{props.translate('ottawaConsent.t7')}</p>
      <p>{props.translate('ottawaConsent.t8')}</p>
    </div>
  )
}
