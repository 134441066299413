import { CancelToken } from 'axios'

import { handleAxiosError } from '../helpers'
import { instance } from '../helpers'

export const activateServices = {
  create,
  update,
  get,
}

function create(params, token) {
  return instance
    .post('/activations/' + token, params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function get(token, cancel) {
  return instance
    .get('/activations/' + token, {
      cancelToken: new CancelToken(c => (cancel.exec = c)),
    })
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function update(params) {
  return instance
    .put('/activations', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
