import { handleAxiosError } from '../helpers'
import { instance } from '../helpers'

export const surveyServices = {
  update,
}

function update(params) {
  return instance
    .put('/survey', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
