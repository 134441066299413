import React from 'react'

import Children from './Children'
import { NoChildren } from './NoChildren'

export const Parent = props => {
  const { records, role, location } = props

  return (
    <div>
      {records && records.length ? (
        <Children children={records} role={role} location={location} />
      ) : (
        <NoChildren />
      )}
    </div>
  )
}
