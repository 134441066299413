import { handleAxiosError } from '../helpers'
import { instance } from '../helpers'

export const recoverableServices = {
  create,
  update,
}

function create(params) {
  return instance
    .post('/recoverable', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function update(params, token) {
  return instance
    .put('/recoverable/' + token, params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
