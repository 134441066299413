import { handleAxiosError } from '../helpers'
import { instance } from '../helpers'

export const sessionServices = {
  create,
  destroy,
}

function create(params) {
  return instance
    .post('/sessions', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function destroy() {
  return instance
    .delete('/sessions')
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
