import React, { Component } from 'react'

import header from '../../images/Consent/Winnipeg/header.png'

export class WinnipegConsent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      receive_copy_of_results: null,
      agree_to_be_contacted: null,
      name_of_guardian: '',
      parent_email: '',
      parent_cell_number: '',
      child_name: '',
      name_person_obtaining_assent: '',
      role_person_obtaining_assent: '',
      relationship_to_study_members: '',
    }
  }

  validateComplete = () => {
    const {
      receive_copy_of_results,
      agree_to_be_contacted,
      name_of_guardian,
      parent_email,
      parent_cell_number,
      child_name,
      name_person_obtaining_assent,
      role_person_obtaining_assent,
    } = this.state

    let data = this.state

    if (
      name_of_guardian &&
      parent_email &&
      parent_cell_number &&
      child_name &&
      name_person_obtaining_assent &&
      role_person_obtaining_assent &&
      receive_copy_of_results !== null &&
      agree_to_be_contacted !== null
    ) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (
      [
        'name_of_guardian',
        'parent_email',
        'parent_email',
        'parent_cell_number',
        'child_name',
        'name_person_obtaining_assent',
        'role_person_obtaining_assent',
        'relationship_to_study_members',
      ].includes(name)
    ) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    } else if (
      ['receive_copy_of_results', 'agree_to_be_contacted'].includes(name)
    ) {
      this.setState(
        {
          [name]: value === 'true',
        },
        this.validateComplete
      )
    }
  }

  render() {
    const { translate } = this.props

    const {
      name_of_guardian,
      parent_email,
      parent_cell_number,
      child_name,
      name_person_obtaining_assent,
      role_person_obtaining_assent,
      relationship_to_study_members,
    } = this.state

    return (
      <div>
        <h5 className="text-center">
          Surveillance in High Schools to Reduce Concussions and Consequences of
          Concussions–SHRed Concussions
        </h5>
        <img
          src={header}
          alt={'header logo'}
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '25%',
          }}
        />
        <h4 className="text-center">
          RESEARCH PARTICIPANT INFORMATION AND CONSENT FORM
          <br />
          Parent
        </h4>
        <h5 className="lead mt-3">Title of Study:</h5>
        <b>
          “Surveillance in High Schools to Reduce Concussions and Consequences
          of Concussions–SHRed Concussions”
        </b>
        <br />
        <br />
        <p>
          <h5>{translate('winnipegConsent.t5')}:</h5>
          Dr Kelly Russell <br />
          Winnipeg, MB, 204-480-1312 email:{' '}
          <span style={{ color: 'blue' }}>krussell@chrim.ca</span>
        </p>
        <p>
          <h5>{translate('winnipegConsent.t7')}:</h5>
          Dr. Michael Ellis <br />
          Winnipeg, MB, 204-927-2766
        </p>
        <p>
          <b>{translate('winnipegConsent.t8')}:</b>{' '}
          {translate('winnipegConsent.t9')}
        </p>
        <p>
          You are being asked to participate in a human research study. Please
          take your time to review this consent form and discuss any questions
          you may have with the study staff. You may take your time to make your
          decision about participating in this study and you may discuss it with
          your friends, family or (if applicable) your doctor before you make
          your decision. This consent form may contain words that you do not
          understand. Please ask the study staff to explain any words or
          information that you do not clearly understand. The study doctor
          institution is receiving professional fees and financial support to
          conduct this study.
        </p>
        <p>
          <i>
            When we say “you”: in this consent form, we mean you or your child;
            “we” means the doctors and other staff.
          </i>
        </p>
        <h5 className="lead mt-3">
          <u>Purpose of Study</u>
        </h5>
        <p>
          This research study is being conducted to establish a national
          concussion surveillance program in schools, study concussion
          prevention strategy effectiveness, and develop concussion detection
          tools.
        </p>
        <p>
          A total of 600 Manitoba athletes will participate in this study over
          three years.
        </p>
        <h5 className="lead mt-3">
          <u>Study Procedures</u>
        </h5>
        <p>
          This study will last three years. If you choose to participate, you
          will be asked to take part in annual baseline testing before the start
          of your sports season begins. This testing will include surveys
          designed to measure your mental and physical health as well as
          physical testing (including balance and grip testing, a shuttle run).
        </p>
        <p>
          If during the course of the three years you sustain a sports-related
          concussion, you will undergo the same treatment that you otherwise
          would have received and will repeat the same type of testing initially
          done at baseline until you have healed completely.
        </p>
        <p>
          Communications and reminders will be sent via email, phone call, or
          text to the email addresses and cell phone numbers provided by you on
          the consent and assent forms.
        </p>
        <p>
          Enrollment and some testing procedures may be conducted virtually,
          either over the phone or via an encrypted video chat. These will be
          conducted on a secure platform, and in a private location to ensure
          your privacy. All information stored is on secure servers only
          accessible by study staff.
        </p>
        <p>
          If during the course of the study it becomes apparent that you are
          suffering from severe depression or have expressed a desire to
          self-harm, a referral will be made to psychiatric services.
        </p>
        <p>
          A study staff member will ask if you have read and understood this
          consent document, and if you have any questions about the study or
          about participating.
        </p>
        <p>
          You can stop participating at any time. However, if you decide to stop
          participating in the study, please talk to the study staff first.
          There will be no consequences if you suddenly withdraw from the study.
        </p>
        <p>
          The study results will not automatically be provided to you. However,
          if you would like a copy of the results, please let us know at the
          time you agree to participate and we will mail you a copy of the
          results. Results are expected to be available in the summer of 2024.
          You will only be receiving aggregate results.
        </p>
        <h5 className="lead mt-3">
          <u>Risks and Discomforts</u>
        </h5>
        <p>
          Beyond the risk that you may injure yourself when doing the baseline
          physical testing there are no risks to you for participating in this
          study. Every effort will be made by study staff to ensure that you
          complete the exercises safely to avoid injury.
        </p>
        <p>
          There is a slight increase in risk of loss of confidentiality in doing
          aspects of the study virtually. All information collected is stored
          securely, and every effort possible is made to ensure privacy and
          confidentially.
        </p>
        <h5 className="lead mt-3">
          <u>Benefits</u>
        </h5>
        <p>
          By participating in this study, you will be providing information to
          the study staff that will help prevent concussions and help us
          understand outcomes and risk. There may or may not be direct medical
          benefit to you from participating in this study. We hope the
          information learned from this study will benefit other participants
          with sport-related concussions in the future.
        </p>
        <h5 className="lead mt-3">
          <u>Costs</u>
        </h5>
        <p>
          There will be no cost for the study treatment that you will receive.
        </p>
        <h5 className="lead mt-3">
          <u>Payment for Participation</u>
        </h5>
        <p>
          You will receive no payment for your participation in this study but
          parking charges of up to $15/session will be reimbursed.
        </p>
        <p>
          To thank you for your participation, your child will receive a $20
          gift card after they complete baseline testing. Each week that your
          child reports their sport participation, they will be entered into a
          draw for two tickets to a local sporting event.
        </p>
        <h5 className="lead mt-3">
          <u>Confidentiality</u>
        </h5>
        <p>
          Information gathered in this research study may be published or
          presented in public forums; however, your name and other identifying
          information will not be used or revealed. All study related documents
          will bear only your assigned study number and/or initials. Despite
          efforts to keep you and your personal information confidential,
          absolute confidentiality cannot be guaranteed. Your personal
          information may be disclosed if required by law. All study documents
          related to you will bear only your assigned patient number.
        </p>
        <p>
          The University of Manitoba Health Research Ethics Board may review
          records related to the study for quality assurance purposes.
        </p>
        <p>
          All electronic records will be stored on secure servers and any paper
          records will be kept in a locked secure area and only those persons
          identified will have access to these records. If any of your research
          records need to be copied to any of the above, your name and all
          identifying information will be removed. No information revealing any
          personal information such as your name, address or telephone number
          will leave the University of Manitoba.
        </p>
        <h5 className="lead mt-3">
          <u>Voluntary Participation/Withdrawal from the Study</u>
        </h5>
        <p>
          Your decision to take part in this study is voluntary. You may refuse
          to participate or withdraw from the study at any time. Your decision
          not to participate or to withdraw from the study will not affect your
          care at this site. If the study staff feels that it is in your best
          interest to withdraw you from the study, they will remove you without
          your consent. We will tell you about any new information that may
          affect your health, welfare, or willingness to stay in this study.
        </p>
        <h5 className="lead mt-3">
          <u>Questions</u>
        </h5>
        <p>
          You are free to ask any questions that you may have about your rights
          as a research participant. If any questions come up during or after
          the study or if you have a research-related injury, contact the study
          doctor and the study staff:
          <br />
          Dr Kelly Russell, 204-480-1312 or krussell@chrim.ca.
        </p>
        <p>
          For questions about your rights as a research participant, you may
          contact The University of Manitoba, Bannatyne Campus Research Ethics
          Board Office at 204-789-3389.
        </p>
        <p>
          Do not sign this consent form unless you have had a chance to ask
          questions and have received satisfactory answers to all of your
          questions.
        </p>
        <h5 className="lead mt-3">
          <u>Statement of Consent</u>
        </h5>
        <p>
          I have read this consent form. I have had the opportunity to discuss
          this research study with Dr Kelly Russell or her study staff. I have
          had my questions answered by them in language I understand. The risks
          and benefits have been explained to me. I believe that I have not been
          unduly influenced by any study team member to participate in the
          research study by any statements or implied statements. Any
          relationship (such as employer, supervisor or family member) I may
          have with the study team has not affected my decision to participate.
          I understand that I will be given a copy of this consent form after
          signing it. I understand that my participation in this study is
          voluntary and that I may choose to withdraw at any time. I freely
          agree to participate in this research study.
          <br />I understand that information regarding my personal identity
          will be kept confidential, but that confidentiality is not guaranteed.
        </p>
        <p>
          By signing this consent form, I have not waived any of the legal
          rights that I have as a participant in a research study.
        </p>
        <ol>
          <li>
            I agree to be contacted for future follow-up relation to this study
            <div className="form-check" style={{ marginTop: '1rem' }}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="agree_to_be_contacted"
                  id="agree_to_be_contacted_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes
              </label>
            </div>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="agree_to_be_contacted"
                  id="agree_to_be_contacted_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No
              </label>
            </div>
          </li>
          <li>
            I would like a copy of the study results mailed to me
            <div className="form-check" style={{ marginTop: '1rem' }}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="receive_copy_of_results"
                  id="receive_copy_of_results_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes
              </label>
            </div>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="receive_copy_of_results"
                  id="receive_copy_of_results_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No
              </label>
            </div>
          </li>
        </ol>
        <br />
        <br />
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            <b>Parent/legal guardian's name</b>
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="name_of_guardian"
              name="name_of_guardian"
              value={name_of_guardian}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            <b>Parent Email Address</b>
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_email"
              name="parent_email"
              value={parent_email}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            <b>Parent Cell Phone Number</b>
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_cell_number"
              name="parent_cell_number"
              value={parent_cell_number}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <br />
        <br />
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            <b>Child's Name</b>
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_name"
              name="child_name"
              value={child_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <br />
        <hr />
        <p>
          I, the undersigned, have fully explained the relevant details of this
          research study to the participant named above and believe that the
          participant has understood and has knowingly given their consent
        </p>
        <br />
        <br />
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Printed Name</label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="name_person_obtaining_assent"
              name="name_person_obtaining_assent"
              value={name_person_obtaining_assent}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Role in the study</label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="role_person_obtaining_assent"
              name="role_person_obtaining_assent"
              value={role_person_obtaining_assent}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Relationship (if any) to study team members
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="relationship_to_study_members"
              name="relationship_to_study_members"
              value={relationship_to_study_members}
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    )
  }
}
