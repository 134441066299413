import React from 'react'

export class EdmontonConsent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      understand_allow_child: '',
      received_copy: '',
      understand_benefits_risks: '',
      ask_questions: '',
      understand_free_to_leave: '',
      confidential_explained: '',
      understand_info_access: '',
      inform_family_doctor: '',
      dr_name: '',
      who_explained: '',
      formValid: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.validateComplete = this.validateComplete.bind(this)
  }

  handleChange(event) {
    const { name, value } = event.target

    if (name === 'dr_name' || name === 'who_explained') {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    } else {
      this.setState(
        {
          [name]: value === 'true',
        },
        this.validateComplete
      )
    }
  }

  validateComplete() {
    const {
      understand_allow_child,
      received_copy,
      understand_benefits_risks,
      ask_questions,
      understand_free_to_leave,
      confidential_explained,
      understand_info_access,
    } = this.state
    let data = this.state

    if (
      understand_allow_child &&
      received_copy &&
      understand_benefits_risks &&
      ask_questions &&
      understand_free_to_leave &&
      confidential_explained &&
      understand_info_access
    ) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  render() {
    const { dr_name, who_explained } = this.state

    return (
      <div>
        <h4>CONSENT FORM FOR PARENTS/GUARDIANS</h4>
        <h5 className="lead mt-3">TITLE:</h5>
        SHRED - Surveillance in High Schools to Reduce Injury and Concussion
        through Prevention and Management
        <h5 className="lead mt-3">INVESTIGATORS:</h5>
        <p>
          <b>Principal Investigator:</b> Dr. Carolyn Emery
        </p>
        <p>
          <b>Co-Investigators:</b>
          <br />
          Shelina Babul (University of British Columbia), Kathy Belton
          (University of Alberta), Amanda Black (University of Calgary), William
          Bridel (University of Calgary), Lara Boyd (UBC), Brian Brooks
          (University of Calgary), Tyler Cluff (University of Calgary), Chantel
          Debert (University of Calgary), Sean Dukelow (University of Calgary),
          Paul Eliason (UCalgary), Mike Ellis (University of Manitoba), Pierre
          Frémont (Université Laval), Pamela Fuselli (Parachute), Isabelle
          Gagnon (McGill University), Bradley Goodyear (University of Calgary),
          Claude Goulet (Université Laval), Brent Hagel (University of Calgary),
          Sarah Kenny (University of Calgary), Constance Lebrun (University of
          Alberta), Alison Macpherson (York University), Brad McFadden
          (Université Laval), Catherine Mercier (Université Laval), Martin
          Mrazik (University of Alberta), Luc Nadeau (Université Laval), Alberto
          Nettel-Aguirre (University of Calgary), Luz Palacios-Derflingher
          (University of Calgary), William Panenka (UBC), Kati Pasanen
          (University of Calgary), Ian Pike (University of British Columbia),
          Nick Reed (University of Toronto), Kelly Russell (University of
          Manitoba), Kathryn Schneider (University of Calgary), Ian Shrier
          (McGill University), Jon Smirl (University of British Columbia),
          Cheryl Wellington (University of British Columbia), Paul vanDonkelaar
          (University of British Columbia), Jackie Whittaker (University of
          Alberta), Keith Yeates (University of Calgary)`, Roger Zemek
          (University of Ottawa)
        </p>
        <p>
          <i>
            This consent form is only part of the process of informed conse nt.
            It should give you the basic idea of what the research is about and
            what your participation will involve if you would like more detail
            about something mentioned here, or information not included here,
            please ask. Take the time to read this carefully and to understand
            any accompanying information. You may choose to download a copy for
            your records.
          </i>
        </p>
        <h5 className="lead mt-3">BACKGROUND:</h5>
        <p>
          Sport and recreational activities are good for youth, but there is
          always a chance of getting injured. One of the injuries that can
          happen is a concussion. There has been research on concussion in some
          Canadian youth sports, such as hockey. This type of research has
          looked at why some youth may be more likely to get a concussion, what
          affects the amount of time it takes to recover from a concussion, and
          ways to prevent concussions in sports. There is also a need to
          research the same questions in other sport and recreational
          activities, and even in schools so that it can help lower the risk of
          concussion in all youth. This will help researchers develop strategies
          that can be used to educate teachers, coaches, parents, and students
          in schools about concussions.
        </p>
        <h5 className="lead mt-3">WHAT IS THE PURPOSE OF THIS STUDY?</h5>
        <p>
          There are six major goals of this study: (1) Collect information on
          injuries and concussions in high school students (2) Determine why
          some high school students may have a higher chance of getting a
          concussion than others (3) Look at high school students' recovery
          after getting a concussion (4) Evaluate what high school students,
          teachers, coaches, and parents know about concussions, and how they
          manage concussions (5) Create a concussion education program for high
          school teachers, coaches, and students, and evaluate if it works (6)
          Create injury prevention programs to use in a high school athletics,
          and evaluate if these programs work.
        </p>
        <h5 className="lead mt-3">WHAT WOULD I HAVE TO DO?</h5>
        <p>
          At the beginning of the study, your child will be asked to complete a
          survey, which asks about their participation in physical activity, and
          about any past injuries. As part of the baseline assessment, the
          research team will help administer the sport concussion assessment
          tool 5 (a tool used to help recognize and manage concussion). The test
          assesses symptoms, memory, coordination, concentration and balance.
          They will then be asked to report all injuries they get during the
          school year, even if they already received treatment for it outside of
          the study. They will also be asked to report what sport and physical
          activities they participate in during the study. If they get a
          concussion, they may be tested using the sport concussion assessment
          tool as part of their concussion management. You may be called if you
          do not complete these forms, to help us get complete data.
        </p>
        <p>
          A study therapist (Certified Athletic Therapist and/or a
          physiotherapist) will be visiting the school on a weekly basis. If
          your child suffers an injury during the school year, they will be able
          to have the injury assessed by the study therapist at the school. If
          the study therapist suspects that they may have a concussion, they
          will be referred by the study therapist to a local sport medicine
          doctor. The researchers may also choose the school's athletic team to
          evaluate an injury prevention program.
        </p>
        <h5 className="lead mt-3">WHAT ARE THE BENEFITS TO MY CHILD?</h5>
        <p>
          There are no direct benefits to your child. The information we get
          from this study will give researchers a better understanding of high
          school students' injuries. This information will help develop
          strategies that can be used to prevent sport and recreational injuries
          in the future.
        </p>
        <p>
          It is possible that you may learn more about concussions and injuries.
          If your child get injured during the study, they will be assessed by
          the study therapist. If the study therapist suspects that they may
          have a concussion, they will refer you to a local sport medicine
          doctor.
        </p>
        <h5 className="lead mt-3">
          WILL I BE PAID FOR PARTICIPATING, OR DO I HAVE TO PAY FOR ANYTHING?
        </h5>
        <p>
          You do not have to pay to participate in the study, and you will not
          be paid anything if you decide to participate.
        </p>
        <h5 className="lead mt-3">DO I HAVE TO PARTICIPATE?</h5>
        <p>
          No, your child does not have to be in the study. Participation in this
          study is voluntary and they may leave the study at any time by
          contacting the study coordinator. If they leave the study, you may
          also request to withdraw their data so long as the request is made
          before two weeks after data collection is complete in the activity
          they participated in. Their involvement with your team and/or class
          will not be affected if you choose not to consent to take part in the
          study. The researchers will tell you if there is new information
          available through this study period.
        </p>
        <p>
          You may be contacted in the future and invited to take part in other
          research studies that are similar to this study, or which look at any
          injuries they may have. Data collected during this study may be
          combined and reported with data from other studies conducted by this
          research team. We will not share your contact information with anyone
          outside the research team.
        </p>
        <h5 className="lead mt-3">WILL MY CHILD'S RECORDS BE KEPT PRIVATE?</h5>
        <p>
          All of the information collected throughout the study period will be
          de-identified and will remain strictly confidential. Only the
          investigators responsible for this study, the research staff, the
          statistician who will analyze the data, and the Conjoint Health
          Research Ethics Board will have access to this information.
          Confidentiality will be protected by using only study identification
          numbers in the data base. Any results of the study, which are
          reported, will in no way identify your child. These online surveys may
          ask for personal identifiers or information that may be used to
          identify them. However, no direct link is made between their
          information and their data.
        </p>
        <h5 className="lead mt-3">
          WHAT HAPPENS IF MY CHILD IS INJURED BECAUSE OF THIS RESEARCH?
        </h5>
        <p>
          In the unlikely event that your child suffers an injury because of
          participating in this research, he/she will receive necessary medical
          treatment, at no additional cost to you. By signing this consent form
          you are not releasing the investigator(s), institution(s) and/or
          sponsor(s) from their legal and professional responsibilities.
        </p>
        <h5 className="lead mt-3">QUESTIONS:</h5>
        <p>
          You are free to leave the study at any time without jeopardizing your
          health care. If you have further questions concerning matters related
          to this research, please contact: <br />
          Project Research Coordinator: SHRED@ualberta.ca <br />
          or <br />
          Dr. Carolyn Emery (Principal Investigator) 403-220-4608 (Calgary){' '}
          <br />
          Dr. Martin Mrazik (Co-Investigator) 780-492-8052 (Edmonton)
        </p>
        <h5 className="lead mt-3">SIGNATURE & AUTHORIZATION:</h5>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            Do you understand that you have been asked to allow your child to be
            in a research study?
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="understand_allow_child"
                  id="understand_allow_child_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="understand_allow_child"
                  id="understand_allow_child_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            Have you read and received a copy of the attached information sheet?
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="received_copy"
                  id="received_copy_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="received_copy"
                  id="received_copy_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            Do you understand the benefits and risks involved in taking part in
            this research study?
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="understand_benefits_risks"
                  id="understand_benefits_risks_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="understand_benefits_risks"
                  id="understand_benefits_risks_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            Have you had the opportunity to as questions and discuss this study?
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="ask_questions"
                  id="ask_questions_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="ask_questions"
                  id="ask_questions_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            Do you understand that your child is free to leave the study at any
            time, without having to give a reason and without affecting his/her
            future medical care?
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="understand_free_to_leave"
                  id="understand_free_to_leave_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="understand_free_to_leave"
                  id="understand_free_to_leave_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            Has the issue of confidentiality been explained to you?
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="confidential_explained"
                  id="confidential_explained_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="confidential_explained"
                  id="confidential_explained_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            Do you understand who will have access to your child's records,
            including personally identifiable health information?
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="understand_info_access"
                  id="understand_info_access_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="understand_info_access"
                  id="understand_info_access_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            Do you want the researcher(s) to inform your family doctor that your
            child is participating in this research study?
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inform_family_doctor"
                  id="inform_family_doctor_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inform_family_doctor"
                  id="inform_family_doctor_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">
            If yes, give Dr's name
          </label>
          <div className="col-lg-4 mb-2">
            <input
              type="text"
              className="form-control"
              id="dr_name"
              name="dr_name"
              value={dr_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">
            Who explained this to you?
          </label>
          <div className="col-lg-4 mb-2">
            <input
              type="text"
              className="form-control"
              id="who_explained"
              name="who_explained"
              value={who_explained}
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    )
  }
}
