import React from 'react'

export class QuebecCityAssent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      child_name: '',
      will_participate: null,
      formValid: false,
    }
  }

  handleChange = event => {
    const { name, value } = event.target
    if (['child_name'].includes(name)) {
      this.setState({ [name]: value }, this.validateComplete)
    } else if (['will_participate'].includes(name)) {
      this.setState({ [name]: value === 'true' }, this.validateComplete)
    }
  }

  validateComplete = () => {
    const { child_name, will_participate } = this.state

    let data = this.state

    if (child_name && will_participate !== null) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  render() {
    const { translate } = this.props

    return (
      <div>
        <h4 style={{ textAlign: 'center' }}>
          {translate('quebecCityAssent.t1')}
        </h4>
        <h5 style={{ textAlign: 'center' }} className="lead mt-3 center">
          {translate('quebecCityAssent.t2')}
        </h5>
        <br />
        <br />
        <p>
          <b>{translate('quebecCityAssent.t3')}</b>
          <br />
          <b>{translate('quebecCityAssent.t4')}</b>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t5')}</b>
          <br />
          <b>{translate('quebecCityAssent.t6')} </b>
          <span>{translate('quebecCityAssent.t7')}</span>
          <br />
          <b>{translate('quebecCityAssent.t8')} </b>
          <span>{translate('quebecCityAssent.t9')}</span>
          <br />
          <b>{translate('quebecCityAssent.t10')} </b>
          <span>{translate('quebecCityAssent.t11')}</span>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t12')}</b>
          <br />
          <span>{translate('quebecCityAssent.t13')}</span>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t14')}</b>
          <br />
          <span>{translate('quebecCityAssent.t15')}</span>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t16')}</b>
          <br />
          <span>{translate('quebecCityAssent.t17')}</span>
          <br />
          <span>{translate('quebecCityAssent.t18')}</span>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t19')}</b>
          <br />
          <ul>
            <li>{translate('quebecCityAssent.t20')}</li>
            <li>{translate('quebecCityAssent.t21')}</li>
            <li>{translate('quebecCityAssent.t22')}</li>
            <li>{translate('quebecCityAssent.t23')}</li>
            <li>{translate('quebecCityAssent.t24')}</li>
            <li>{translate('quebecCityAssent.t25')}</li>
            <li>{translate('quebecCityAssent.t26')}</li>
            <li>{translate('quebecCityAssent.t27')}</li>
            <li>{translate('quebecCityAssent.t28')}</li>
            <li>{translate('quebecCityAssent.t29')}</li>
            <li>{translate('quebecCityAssent.t30')}</li>
          </ul>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t35')}</b>
          <br />
          <span>{translate('quebecCityAssent.t36')}</span>
        </p>
        <p>{translate('quebecCityAssent.t37')}</p>
        <p>{translate('quebecCityAssent.t38')}</p>
        <p>
          <b>{translate('quebecCityAssent.t39')}</b>
          <br />
          <ul>
            <li>{translate('quebecCityAssent.t40')}</li>
            <li>{translate('quebecCityAssent.t41')}</li>
          </ul>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t42')}</b>
          <br />
          <span>{translate('quebecCityAssent.t43')}</span>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t44')}</b>
          <p>{translate('quebecCityAssent.t45')}</p>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t46')}</b>
          <br />
          <span>{translate('quebecCityAssent.t47')}</span>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t48')}</b>
          <br />
          <span>{translate('quebecCityAssent.t49')}</span>
          <br />
          <span>{translate('quebecCityAssent.t49-1')}</span>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t50')}</b>
          <br />
          <span>{translate('quebecCityAssent.t51')}</span>
        </p>
        <p>
          <b>{translate('quebecCityAssent.t52')}</b>
          <br />
          <span>{translate('quebecCityAssent.t53')}</span>
          <br />
          <span>{translate('quebecCityAssent.t54')}</span>
        </p>
        <p>{translate('quebecCityAssent.t55')}</p>
        <hr />
        <b>{translate('quebecCityAssent.t56')}</b>
        <br />
        <br />
        <b>{translate('quebecCityAssent.t57')}</b>
        <br />
        <p>{translate('quebecCityAssent.t57-1')}</p>
        <div className="form-row">
          <label className="col-lg-4 col-form-label">
            {translate('quebecCityAssent.t58')}
          </label>
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              name="will_participate"
              id="will_participate_yes"
              value={true}
              onClick={this.handleChange}
            />
          </label>
        </div>
        <br />
        <div className="form-row">
          <label className="col-lg-4 col-form-label">
            {translate('quebecCityAssent.t59')}
          </label>
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              name="will_participate"
              id="will_participate_no"
              value={false}
              onClick={this.handleChange}
            />
          </label>
        </div>
        <br />
        <div className="form-group row">
          <label className="col-lg-4 col-form-label">
            {translate('quebecCityAssent.t60')}
          </label>
          <div className="col-lg-4 mb-2">
            <input
              type="text"
              className="form-control"
              id="child_name"
              name="child_name"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <hr />
      </div>
    )
  }
}
