import { handleAxiosError } from '../helpers'
import { instance } from '../helpers'

export const recordServices = {
  create,
  get,
  update,
  destroy,
}

function create(params) {
  return instance
    .post('/records', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function get() {
  return instance
    .get('/records', { headers: { Pragma: 'no-cache' } })
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function update(params) {
  return instance
    .put('/records', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function destroy(params) {
  return instance
    .delete('/records?' + params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
