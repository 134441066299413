import React from 'react'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'

import { Personal } from '../../components/Profile/Settings/Personal'
import Password from '../../components/Profile/Settings/Password'
import { Delete } from '../../components/Profile/Settings/Delete'

export class Settings extends React.Component {
  render() {
    const { role } = this.props

    return (
      <div className="container">
        <div>
          <h1 className="mb-4">
            <Translate id="profile.settings.personal.header" />
          </h1>
          <Personal {...this.props} />
          <Password {...this.props} />
          <Delete role={role} {...this.props} />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { role } = state.session

  return {
    role,
  }
}

export default withLocalize(connect(mapStateToProps)(Settings))
