import React from "react";
import { push } from "react-router-redux";
import qs from "qs";
import { withLocalize, Translate } from "react-localize-redux";

import NewConcussionForm from "./NewConcussionForm";
import { Plus } from "react-feather";
import NewInjuryModal from "../../Modals/NewInjuryModal";
import NewConcussionModal from "../../Modals/NewConcussionModal";
import { store } from "../../../helpers/index";
import { newInjuryServices } from "../../../services";
import { alertActions } from "../../../actions";
import { Surveys } from "../../Surveys";
import "./Child.css";
import globalTranslations from "../../../localize/global";

let cancel = { exec: null };

class Child extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "baseline",
      concussionPending: false,
      showConcussionModal: false,
      showInjuryModal: false
    };
    this.toggleConcussionModal = this.toggleConcussionModal.bind(this);
    this.handleConcussionContinueClick = this.handleConcussionContinueClick.bind(
      this
    );
    this.handleInjuryContinueClick = this.handleInjuryContinueClick.bind(this);
    this.handleInjuryChange = this.handleInjuryChange.bind(this);
    this.toggleInjuryModal = this.toggleInjuryModal.bind(this);
    this.handleConcussionSubmit = this.handleConcussionSubmit.bind(this);
    this.props.addTranslation(globalTranslations);
  }

  componentDidMount() {
    const { location } = this.props;

    if (location) {
      const search = qs.parse(location.search.slice(1));

      if (search && search.contentTab) {
        this.setState({
          activeTab: search.contentTab
        });
      }
    }
    this.handleInjuryChange();
  }

  componentWillUnmount() {
    if (cancel.exec) cancel.exec();
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = props;

    if (location) {
      const search = qs.parse(location.search.slice(1));

      if (search && search.contentTab) {
        state.activeTab = search.contentTab;
      }
    }

    return state;
  }

  dynamicHeightCalc() {
    const { record } = this.props;
    let height = 800;

    const x = record ? record.surveys["baseline"] : null;

    if (x && x.length === 3) {
      if (x[2]["surveys"].length > 5) {
        let i = x[2]["surveys"].length;
        i = i - 5;
        height = height + i * 80;
        height = String(height) + "px";
      }
    } else if (x && x.length === 4) {
      height = 1000;
      let i = Math.max(x[2].length, x[3].length);
      if (i > 5) {
        i = i - 5;
        height = height + i * 80;
        height = String(height) + "px";
      }
    }
    return height;
  }

  handleTabChange = tab => {
    const { dispatch } = store;
    const { location } = this.props;

    if (location) {
      const search = qs.parse(location.search.slice(1));

      if (search && search.childTab) {
        dispatch(
          push({
            pathname: "/profile/dashboard",
            search: "?childTab=" + search.childTab + "&contentTab=" + tab
          })
        );

        this.setState({
          activeTab: tab
        });
      }
    }
  };

  toggleConcussionModal() {
    const { showConcussionModal } = this.state;

    this.setState({
      showConcussionModal: !showConcussionModal
    });
  }

  toggleInjuryModal() {
    const { showInjuryModal } = this.state;

    this.setState({
      showInjuryModal: !showInjuryModal
    });
  }

  handleConcussionContinueClick() {
    const { dispatch } = store;

    dispatch(
      push({
        pathname: "/profile/dashboard",
        search: "?childTab=0&contentTab=newConcussion"
      })
    );
    this.toggleConcussionModal();
  }

  handleInjuryContinueClick() {
    const { dispatch } = store;

    dispatch(
      push({
        pathname: "/profile/dashboard",
        search: "?childTab=0&contentTab=newInjury"
      })
    );
    this.toggleInjuryModal();
  }

  handleConcussionSubmit() {
    this.setState({
      activeTab: "concussion"
    });
  }

  handleInjuryChange() {
    const { dispatch } = store;

    newInjuryServices.get(cancel).then(
      response => {
        const concussionPending = response.data["concussion_pending"];
        const concussionIrfComplete = response.data["concussion_irf"];
        const injuryPending = response.data["injury_pending"];
        const injuryIrfComplete = response.data["injury_irf"];

        if (concussionPending && !concussionIrfComplete) {
          this.toggleConcussionModal();
        } else if (injuryPending && !injuryIrfComplete) {
          this.toggleInjuryModal();
        }

        this.setState({
          concussionPending: concussionPending
        });
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors;
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e));
            });
          }
        }
      }
    );
  }

  render() {
    const {
      activeTab,
      concussionPending,
      showConcussionModal,
      showInjuryModal
    } = this.state;
    const { record, role, translate } = this.props;
    const surveyTypes = record ? record.surveys[activeTab] : null;

    const currentSports = record ? record.sports : null;
    const concussionRecords = record ? record.surveys["concussion"] : null;

    const dynamicGridHeight = this.dynamicHeightCalc();

    return (
      <div>
        <div className="row mb-2">
          <ul
            className="col-lg-12 col-sm-12 col-12 nav nav-tabs"
            style={{ marginLeft: "14px" }}
          >
            <li className="nav-item">
              <span
                key="baseline"
                className={
                  activeTab === "baseline" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.handleTabChange("baseline")}
              >
                <Translate id="childTabController.baselineTab" />
              </span>
            </li>
            {((concussionRecords && concussionRecords.length > 0) ||
              concussionPending) && (
              <li className="nav-item">
                <span
                  key="concussion"
                  className={
                    activeTab === "concussion" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => this.handleTabChange("concussion")}
                >
                  <Translate id="childTabController.concussionTab" />
                </span>
              </li>
            )}
            <li className="nav-item">
              <span
                key="newConcussion"
                className={
                  activeTab === "newConcussion" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.handleTabChange("newConcussion")}
              >
                <span className="primary-color">
                  <Plus
                    className="mr-1"
                    style={{ height: "17px", paddingTop: "3px" }}
                  />
                  <Translate id="childTabController.newConcussionTab" />
                </span>
              </span>
            </li>
            <li className="nav-item">
              <span
                key="newInjury"
                className={
                  activeTab === "newInjury" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.handleTabChange("newInjury")}
              >
                <span className="primary-color">
                  <Plus
                    className="mr-1"
                    style={{ height: "17px", paddingTop: "3px" }}
                  />
                  <Translate id="childTabController.newNonConcussionInjuryTab" />
                </span>
              </span>
            </li>
          </ul>
        </div>
        {activeTab === "newConcussion" || activeTab === "newInjury" ? (
          <NewConcussionForm
            handleConcussionSubmit={this.handleConcussionSubmit}
            handleInjuryChange={this.handleInjuryChange}
            injuryType={activeTab}
            role={role}
          />
        ) : activeTab === "concussion" && concussionPending ? (
          <div
            style={{
              width: "100%",
              height: "600px",
              display: "flex"
            }}
          >
            <h4
              style={{
                maxWidth: "500px",
                margin: "0 auto",
                marginTop: "150px"
              }}
            >
              <Translate id="childTabController.concussionPendingHeader" />
            </h4>
          </div>
        ) : (
          <div
            className={activeTab === "concussion" ? "injuryGrid" : "grid"}
            style={{
              height: activeTab === "concussion" ? "" : dynamicGridHeight
            }}
          >
            {surveyTypes &&
              surveyTypes.length > 0 &&
              surveyTypes.map((surveyType, index) => {
                return (
                  <div className="grid-surveys" key={index}>
                    <Surveys
                      key={index}
                      surveyType={surveyType.type_name}
                      surveys={surveyType.surveys}
                      record={record}
                      currentSports={currentSports}
                      surveyPath={activeTab}
                      activeTab={activeTab}
                      handleInjuryChange={this.handleInjuryChange}
                      role={role}
                      translate={translate}
                    />
                  </div>
                );
              })}
          </div>
        )}
        <NewConcussionModal
          showModal={showConcussionModal}
          onClose={this.toggleConcussionModal}
          onContinue={this.handleConcussionContinueClick}
        />
        <NewInjuryModal
          showModal={showInjuryModal}
          onClose={this.toggleInjuryModal}
          onContinue={this.handleInjuryContinueClick}
        />
      </div>
    );
  }
}

export default withLocalize(Child);
