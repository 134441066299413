export const recordConstants = {
  RECORD_REQUEST: 'RECORD_REQUEST',
  RECORD_SUCCESS: 'RECORD_SUCCESS',
  RECORD_FAILURE: 'RECORD_FAILURE',
  RECORD_REFRESH: 'RECORD_REFRESH',
}

export const sessionConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  SESSION_UPDATE_SUCCESS: 'SESSION_UPDATE_SUCCESS',
}

export const sportConstants = {
  SPORT_SUCCESS: 'SPORT_SUCCESS',
  SPORT_FAILURE: 'SPORT_FAILURE',
}

export const friendlyEnums = {
  active: 'active',
  inactive: 'inactive',
  return_play: 'return_play',
  finished: 'finished',
}

export const mobileSurveys = {
  'Pre-Season Questionnaire': 'PBQ',
  'OSTR-C Questionnaire': 'OSTRC',
  'Weekly Exposure': 'Weekly Exposure',
  'Connor Davidson Resilience Scale': 'Resilience Scale',
  'Strengths and Difficulties Questionnaire': 'Strengths and Difficulties',
  'Pediatric Quality of Life Inventory': 'Quality of Life',
  'PROMIS Questionnaire': 'PROMIS',
  'Illness Perceptions Questionnaire': 'Illness Perceptions',
  'Illness Perceptions Questionnaire (version française)':
    'Illness Perceptions',
  'Behavioral Avoidance Scale': 'Behavioral Avoidance',
  'Behavioral Avoidance Scale (version française)': 'Behavioral Avoidance',
  'Behavioral Response to Illness': 'Response to Illness',
  'Behavioral Response to Illness (version française)': 'Response to Illness',
  'Sports Performance Class (Yoga, Weights, etc)': 'Sports Performance',
  'Physical Education Class': 'Phys Ed Class',
  'PBQ Injury Concussion History': 'PBQ Injury History',
  'PBQ Physical Activity Participation': 'PBQ Physical Activity',
}
