import React from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'

import { store } from '../../helpers'

export class About extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    event.preventDefault()
    const { dispatch } = store

    dispatch(push('/signup'))
  }

  render() {
    const { isAuthenticated } = this.props

    return (
      <div>
        <div className="container mb-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  <h2 className="heading mb-5">
                    <Translate id="about.headerAn" />
                    <span className="text-primary">
                      <Translate id="about.headerIntroduction" />
                    </span>
                  </h2>
                </div>
                <div className="col-lg-3 col-sm-12 mb-5">
                  {!isAuthenticated && (
                    <div className="text-center">
                      <h5 className="mb-2">
                        <Translate id="about.signUpNow" />
                      </h5>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this.handleClick}
                      >
                        <Translate id="about.clickToSignUp" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <h5 className="mb-4">
                <Translate id="about.p1Header" />
              </h5>
              <p className="text-justify mb-5">
                <Translate id="about.p1Body" />
              </p>
              <h5 className="mb-4">
                <Translate id="about.p2Header" />
              </h5>

              <p className="mb-5 text-justify">
                <Translate id="about.p2Body" />
                {/* As well, Research
                Ethics Boards associated with the 9 other sites (UBC, UBC-O, U
                of A, UMAN, York, CHEO, UOttawa, McGill, & Laval) have approved
                this research project. */}
              </p>
              <div className="col-lg-6 col-sm-12 pl-0">
                <div className="well pl-3 pr-3">
                  <h5 className={'mb-3 mt-3 text-primary'}>
                    <Translate id="about.howToSignup.header" />
                  </h5>
                  <ol>
                    <li className="mb-1">
                      <Translate id="about.howToSignup.p1" />
                      <b>
                        <Translate id="about.howToSignup.p1b" />
                      </b>
                    </li>
                    <li className="mb-1">
                      <Translate id="about.howToSignup.p2" />
                      <b>
                        <Translate id="about.howToSignup.p2b" />
                      </b>
                    </li>
                    <ul>
                      <li>
                        <Translate id="about.howToSignup.p2i1" />
                      </li>
                      <li>
                        <Translate id="about.howToSignup.p2i2" />
                      </li>
                    </ul>
                    <li className="mb-1">
                      <Translate id="about.howToSignup.p3" />
                    </li>
                    <li className="mb-1">
                      <Translate id="about.howToSignup.p4" />
                    </li>
                    <ul>
                      <li>
                        <Translate id="about.howToSignup.p4i1" />
                      </li>
                    </ul>
                    <li className="mb-1">
                      <Translate id="about.howToSignup.p5" />
                      <b>
                        <Translate id="about.howToSignup.p5b" />
                      </b>
                      <Translate id="about.howToSignup.p5_" />
                    </li>
                    <ul>
                      <li>
                        <Translate id="about.howToSignup.p5i1" />
                      </li>
                    </ul>
                    <li className="mb-1">
                      <Translate id="about.howToSignup.p6" />
                    </li>
                    <ul>
                      <li>
                        <b>
                          <Translate id="about.howToSignup.p6i1" />
                        </b>
                      </li>
                      <li>
                        <Translate id="about.howToSignup.p6i2" />
                      </li>
                    </ul>
                    <li className="mb-1">
                      <Translate id="about.howToSignup.p7" />
                      <b>
                        <Translate id="about.howToSignup.p7b" />
                      </b>
                    </li>
                    <ul>
                      <li>
                        <Translate id="about.howToSignup.p7i1" />
                      </li>
                      <li>
                        <Translate id="about.howToSignup.p7i2" />
                      </li>
                    </ul>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { isAuthenticated } = state.session

  return {
    isAuthenticated,
  }
}

export default withLocalize(connect(mapStateToProps)(About))
