import React from 'react'
import DatePicker from 'react-datepicker'

import header from '../../images/Consent/Quebec/header.png'
import image_one from '../../images/Consent/Quebec/image_one.png'

export class QuebecCityConsent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      child_name: '',
      child_dob: '',
      child_phone: '',
      parent_name: '',
      parent_dob: '',
      parent_phone: '',
      receive_copy_of_results: null,
      agree_to_be_contacted: null,
      formValid: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.validateComplete = this.validateComplete.bind(this)
  }

  handleDobChange = (field, date) => {
    this.setState({ [field]: date }, this.validateComplete)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (
      ['parent_name', 'parent_phone', 'child_name', 'child_phone'].includes(
        name
      )
    ) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    } else if (
      ['receive_copy_of_results', 'agree_to_be_contacted'].includes(name)
    ) {
      this.setState(
        {
          [name]: value === 'true',
        },
        this.validateComplete
      )
    }
  }

  validateComplete = () => {
    const {
      parent_name,
      parent_dob,
      parent_phone,
      child_name,
      child_dob,
      child_phone,
      receive_copy_of_results,
      agree_to_be_contacted,
    } = this.state

    let data = this.state

    if (
      parent_name &&
      parent_phone &&
      parent_dob &&
      child_name &&
      child_phone &&
      child_dob &&
      receive_copy_of_results !== null &&
      agree_to_be_contacted !== null
    ) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  render() {
    const {
      child_name,
      child_phone,
      child_dob,
      parent_name,
      parent_phone,
      parent_dob,
    } = this.state
    const { translate } = this.props

    return (
      <div>
        <img
          src={header}
          alt="header"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
          }}
        />
        <div style={{ textAlign: 'center' }}>
          <h3 className="lead mt-3">
            <b>{translate('quebecCityConsent.t1')}</b>
          </h3>
          <h5>{translate('quebecCityConsent.t2')}</h5>
        </div>
        <br />
        <h5 className="lead mt-3">{translate('quebecCityConsent.t2-1')}</h5>
        <br />
        <h5 className="lead mt-3">{translate('quebecCityConsent.t3')}</h5>
        <p>
          <b>{translate('quebecCityConsent.t4')}</b>
        </p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t4-1')}</h5>
        <b>{translate('quebecCityConsent.t4-2')}</b>
        <span>{translate('quebecCityConsent.t4-3')}</span>
        <br />
        <b>{translate('quebecCityConsent.t5')}</b>
        <span>{translate('quebecCityConsent.t6')}</span>
        <br />
        <b>{translate('quebecCityConsent.t6-1')}</b>
        <span>{translate('quebecCityConsent.t6-2')}</span>
        <br />
        <br />
        <b>{translate('quebecCityConsent.t7')}</b>
        <span>{translate('quebecCityConsent.t8')}</span>
        <br />

        <h5 className="lead mt-3">{translate('quebecCityConsent.t9')}</h5>
        <p>{translate('quebecCityConsent.t10')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t11')}</h5>
        <p>{translate('quebecCityConsent.t12')}</p>
        <p>{translate('quebecCityConsent.t13')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t14')}</h5>
        <p>{translate('quebecCityConsent.t15')}</p>

        <span>{translate('quebecCityConsent.t16')}</span>
        <br />
        <ol>
          <li>{translate('quebecCityConsent.t17')}</li>
          <li>{translate('quebecCityConsent.t18')}</li>
          <li>{translate('quebecCityConsent.t19')}</li>
          <li>{translate('quebecCityConsent.t20')}</li>
          <li>{translate('quebecCityConsent.t21')}</li>
          <li>{translate('quebecCityConsent.t22')}</li>
        </ol>

        <p>{translate('quebecCityConsent.t23')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t24')}</h5>
        <p>{translate('quebecCityConsent.t25')}</p>

        <p>{translate('quebecCityConsent.t26')}</p>

        <span>{translate('quebecCityConsent.t27')}</span>
        <br />
        <br />

        <h5 className="lead mt-3">{translate('quebecCityConsent.t28')}</h5>

        <ol type="a">
          <li>{translate('quebecCityConsent.t29')}</li>
          <li>{translate('quebecCityConsent.t30')}</li>
          <li>{translate('quebecCityConsent.t31')}</li>
          <li>{translate('quebecCityConsent.t32')}</li>
          <li>{translate('quebecCityConsent.t33')}</li>
          <li>{translate('quebecCityConsent.t34')}</li>
          <li>{translate('quebecCityConsent.t35')}</li>
          <li>{translate('quebecCityConsent.t36')}</li>
          <li>{translate('quebecCityConsent.t37')}</li>
        </ol>

        <p>{translate('quebecCityConsent.t38')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t39')}</h5>
        <p>{translate('quebecCityConsent.t40')}</p>
        <p>{translate('quebecCityConsent.t40-1')}</p>
        <p>{translate('quebecCityConsent.t41')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t42')}</h5>
        <p>{translate('quebecCityConsent.t43')}</p>

        <p>{translate('quebecCityConsent.t44')}</p>
        <ol type="a">
          <li>{translate('quebecCityConsent.t45')}</li>
          <li>{translate('quebecCityConsent.t46')}</li>
          <li>{translate('quebecCityConsent.t47')}</li>
          <li>{translate('quebecCityConsent.t48')}</li>
          <li>{translate('quebecCityConsent.t49')}</li>
          <li>{translate('quebecCityConsent.t50')}</li>
          <li>{translate('quebecCityConsent.t51')}</li>
          <li>{translate('quebecCityConsent.t52')}</li>
          <li>{translate('quebecCityConsent.t53')}</li>
          <li>{translate('quebecCityConsent.t54')}</li>
          <li>{translate('quebecCityConsent.t55')}</li>
          <li>{translate('quebecCityConsent.t56')}</li>
          <li>{translate('quebecCityConsent.t56-1')}</li>
        </ol>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t57')}</h5>
        <p>{translate('quebecCityConsent.t58')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t59')}</h5>
        <p>{translate('quebecCityConsent.t60')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t61')}</h5>
        <p>{translate('quebecCityConsent.t62')}</p>
        <p>{translate('quebecCityConsent.t63')}</p>
        <p>{translate('quebecCityConsent.t64')}</p>
        <p>{translate('quebecCityConsent.t65')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t66')}</h5>
        <p>{translate('quebecCityConsent.t67')}</p>
        <p>{translate('quebecCityConsent.t68')}</p>
        <p>{translate('quebecCityConsent.t69')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t70')}</h5>
        <p>{translate('quebecCityConsent.t71')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t72')}</h5>
        <p>{translate('quebecCityConsent.t73')}</p>
        <p>{translate('quebecCityConsent.t74')}</p>
        <p>{translate('quebecCityConsent.t75')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t76')}</h5>
        <p>{translate('quebecCityConsent.t77')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t78')}</h5>
        <p>{translate('quebecCityConsent.t79')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t80')}</h5>
        <p>{translate('quebecCityConsent.t81')}</p>
        <p>{translate('quebecCityConsent.t82')}</p>
        <p>{translate('quebecCityConsent.t83')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t84')}</h5>
        <p>
          {translate('quebecCityConsent.t84-1')}
          <br />
          {translate('quebecCityConsent.t85')}
        </p>
        <p>{translate('quebecCityConsent.t86')}</p>

        <h5 className="lead mt-3">{translate('quebecCityConsent.t87')}</h5>
        <p>{translate('quebecCityConsent.t88')}</p>
        <img
          src={image_one}
          alt="image one"
          style={{
            display: 'block',
            width: '30%',
          }}
        />
        <p>{translate('quebecCityConsent.t89')}</p>
        <hr />
        <h5 className="lead mt-3">{translate('quebecCityConsent.t90')}</h5>
        <p>
          <b>{translate('quebecCityConsent.t90-1')}</b>{' '}
          <span>{translate('quebecCityConsent.t91')}</span>
          <br />
          <br />
          <ol>
            <li>{translate('quebecCityConsent.t92')}</li>
            <li>{translate('quebecCityConsent.t93')}</li>
            <li>{translate('quebecCityConsent.t94')}</li>
            <li>{translate('quebecCityConsent.t95')}</li>
            <li>{translate('quebecCityConsent.t96')}</li>
            <li>{translate('quebecCityConsent.t97')}</li>
            <li>{translate('quebecCityConsent.t98')}</li>
            <li>{translate('quebecCityConsent.t99')}</li>
            <li>{translate('quebecCityConsent.t100')}</li>
            <li>{translate('quebecCityConsent.t101')}</li>
            <li>
              {translate('quebecCityConsent.t102')}
              <div className="form-check" style={{ marginTop: '1rem' }}>
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="receive_copy_of_results"
                    id="receive_copy_of_results_yes"
                    value={true}
                    onClick={this.handleChange}
                  />
                  {translate('quebecCityConsent.t103')}
                </label>
              </div>
              <div className="form-check" style={{ marginBottom: '1rem' }}>
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="receive_copy_of_results"
                    id="receive_copy_of_results_no"
                    value={false}
                    onClick={this.handleChange}
                  />
                  {translate('quebecCityConsent.t104')}
                </label>
              </div>
            </li>
            <li>
              {translate('quebecCityConsent.t105')}
              <div className="form-check" style={{ marginTop: '1rem' }}>
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="agree_to_be_contacted"
                    id="agree_to_be_contacted_yes"
                    value={true}
                    onClick={this.handleChange}
                  />
                  {translate('quebecCityConsent.t103')}
                </label>
              </div>
              <div className="form-check" style={{ marginBottom: '1rem' }}>
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="agree_to_be_contacted"
                    id="agree_to_be_contacted_no"
                    value={false}
                    onClick={this.handleChange}
                  />
                  {translate('quebecCityConsent.t104')}
                </label>
              </div>
            </li>
          </ol>
        </p>
        <p>
          <i>{translate('quebecCityConsent.t106')}</i>
        </p>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            {translate('quebecCityConsent.t107')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_name"
              name="child_name"
              value={child_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-4 col-form-label">
            {translate('quebecCityConsent.t108')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <DatePicker
              id="sessionDate"
              className="form-control"
              name="sessionDate"
              maxDate={new Date()}
              placeholderText="mm/dd/yyyy"
              dropdownMode="select"
              showYearDropdown
              scrollableYearDropdown
              selected={child_dob}
              onChange={dt => this.handleDobChange('child_dob', dt)}
              onChangeRaw={dt => this.handleDobChange('child_dob', dt)}
              autoComplete="off"
            ></DatePicker>
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            {translate('quebecCityConsent.t109')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_phone"
              name="child_phone"
              value={child_phone}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            {translate('quebecCityConsent.t110')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_name"
              name="parent_name"
              value={parent_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-4 col-form-label">
            {translate('quebecCityConsent.t111')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <DatePicker
              id="sessionDate"
              className="form-control"
              name="sessionDate"
              maxDate={new Date()}
              placeholderText="mm/dd/yyyy"
              dropdownMode="select"
              showYearDropdown
              scrollableYearDropdown
              selected={parent_dob}
              onChange={dt => this.handleDobChange('parent_dob', dt)}
              onChangeRaw={dt => this.handleDobChange('parent_dob', dt)}
              autoComplete="off"
            ></DatePicker>
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            {translate('quebecCityConsent.t112')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_phone"
              name="parent_phone"
              value={parent_phone}
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    )
  }
}
