import React from 'react'

import header from '../../images/Consent/Vancouver/header.png'
import table_1 from '../../images/Consent/Vancouver/table_1.png'

export class KelownaConsent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      advise_childs_physician: false,
      childs_physician_names: '',
      childs_medical_clinic: '',
      parent_initials_one: '',
      do_not_advise_childs_physician: false,
      parent_initials_two: '',
      child_has_no_physician: false,
      parent_initials_three: '',
      parent_initials_four: '',
      parent_initials_five: '',
      contacted_in_future: false,
      participant_name: '',
      parent_name: '',
      name_of_person_obtaining_consent: '',
      study_role: '',
    }
  }

  validateComplete = () => {
    const {
      participant_name,
      parent_name,
      name_of_person_obtaining_consent,
    } = this.state

    let data = this.state

    if (participant_name && parent_name && name_of_person_obtaining_consent) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (
      [
        'childs_physician_names',
        'childs_medical_clinic',
        'parent_initials_one',
        'parent_initials_two',
        'parent_initials_three',
        'parent_initials_four',
        'parent_initials_five',
        'participant_name',
        'parent_name',
        'name_of_person_obtaining_consent',
        'study_role',
      ].includes(name)
    ) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    } else if (
      [
        'advise_childs_physician',
        'do_not_advise_childs_physician',
        'child_has_no_physician',
        'contacted_in_future',
      ].includes(name)
    ) {
      this.setState(
        {
          [name]: event.target.checked,
        },
        this.validateComplete
      )
    }
  }

  render() {
    const {
      childs_physician_names,
      childs_medical_clinic,
      parent_initials_one,
      parent_initials_two,
      parent_initials_three,
      parent_initials_five,
      participant_name,
      parent_name,
      name_of_person_obtaining_consent,
      study_role,
    } = this.state

    return (
      <div>
        <img
          src={header}
          alt="header"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
          }}
        />
        <div style={{ textAlign: 'center' }}>
          BC Children’s Hospital Research Institute | The University of British
          Columbia
          <br />
          F508 4480 Oak Street | Vancouver BC | V6H 3V4 Canada
          <br />
          Phone +1 (604) 875-3776 | Fax +1 (604) 875-3569
          <br />
          bcinjury1@cw.bc.ca | www.injuryresearch.bc.ca
        </div>
        <h5 className="lead mt-3">Parent Information and Consent Form</h5>
        <h5 className="lead mt-3">Title:</h5>
        <b>
          Surveillance in High Schools to Reduce Concussions and Consequences of
          Concussions in Canadian Youth – SHRed Concussions
        </b>
        <h5 className="lead mt-3">Short Title: </h5>
        <b>SHRed Concussions</b>
        <h5 className="lead mt-3">Sponsor: </h5>
        <b>National Football League’s Scientific Advisory Board</b>
        <h5 className="lead mt-3">Principal Investigators: </h5>
        <p>
          Dr. Ian Pike, PhD (BC Injury Research and Prevention Unit, BC
          Children’s Hospital, University of British Columbia) Contact: (604)
          875-3425
        </p>
        <p>
          Dr. Shelina Babul, PhD (BC Injury Research and Prevention Unit, BC
          Children’s Hospital, University of British Columbia) Contact: (604)
          875-3682
        </p>
        <p>
          Dr. Carolyn Emery, PhD (Sport Injury Prevention Research Centre
          Faculty of Kinesiology, University of Calgary) Contact: (403) 220-4608
        </p>
        <h5 className="lead mt-3">UBC Co-Investigators: </h5>
        <p>
          Dr. Paul van Donkelaar, PhD (Sport Concussion Research Lab, University
          of British Columbia Okanagan)
        </p>
        <p>
          Dr. Cheryl Wellington, PhD (Centre for Brain Health, University of
          British Columbia)
        </p>
        <p>
          Dr. Jackie Whittaker, PT, PhD (Department of Physical Therapy,
          University of British Columbia)
        </p>
        <p>
          Dr. Bruce Bjornson, FRCPC, MD (Brain Mapping & Neurotechnology
          Laboratory, BC Children’s Hospital, University of British Columbia)
        </p>
        <h5 className="lead mt-3">Study Coordinator: </h5>
        <p>
          Sierra Sanchez (Sport Concussion Research Lab)
          <br />
          Contact: sierra.sanchez@ubc.ca
        </p>
        <h5 className="lead mt-3">
          <u>Invitation</u>
        </h5>
        <p>
          Sport is good for youth, but there is always a chance of getting
          injured. One of the injuries that can happen is a concussion, which is
          a type of injury to the brain. Past research has looked at why some
          youth may be more likely to get a concussion, what affects the amount
          of time it takes to recover from concussion, and ways to prevent
          concussions in sports. This helps researchers and health professionals
          develop strategies that can be used to educate teachers, coaches,
          parents, and students in schools about concussions.
        </p>
        <p>
          Your child is being invited to participate in this study because they
          are a high school student who plays at least one sport that carries a
          higher risk of concussion injury.
        </p>
        <h5 className="lead mt-3">
          <u>Your participation is voluntary</u>
        </h5>
        <p>
          You have the right to refuse to have your child participate in this
          study. If you decide to participate, you may still choose to withdraw
          your child from the study at any time by contacting the Study
          Coordinator. If you decide to withdraw your child from the study, you
          can also request to withdraw your child’s data from the study as well.
          Your child’s involvement in their school and their team(s) will not be
          affected if you or your child decide not to take part in this study
        </p>
        <p>
          You should be aware that there is a difference between being a patient
          and being a research participant. As a patient all medical procedures
          and treatments are carried out for your child’s benefit only according
          to standard accepted practice. As a research participant you and your
          doctor also must take into account the requirements for the research
          study. These may include tests that are not part of standard practice.
          This consent form describes the extra tests that are being carried out
          for research purposes. Please review the consent document carefully
          when deciding whether or not you wish for your child to be part of the
          research and sign this consent only if you accept your child being a
          research participant.
        </p>
        <p>
          If your child wishes to participate in this study, you will be asked
          to sign this form. Please take time to read the following information
          carefully and to discuss it with your child before you decide.
        </p>
        <h5 className="lead mt-3">
          <u>Who is conducting the study?</u>
        </h5>
        <p>
          SHRed Concussions is a national study being led by Dr. Carolyn Emery
          at the University of Calgary, where all identifying information and
          study data will be electronically stored on a secure server. In BC,
          this study is being conducted by Co-Principal Investigators Dr. Ian
          Pike and Dr. Shelina Babul who are being supported by Co-Investigators
          located at the University of British Columbia in Vancouver and UBC
          Okanagan. Local participants’ identifying data will also be stored in
          a locked cabinet, in a locked office, at UBCO/BrainTrust. Collection
          and storage of identifying information is explained in detail in the
          ‘confidentiality’ section of this consent form. This study is funded
          by the National Football League’s Scientific Advisory Board.
        </p>
        <h5 className="lead mt-3">
          <u>Background</u>
        </h5>
        <p>
          Concussion is a form of mild traumatic brain injury (TBI). Among
          youth, concussions often happen during sport participation. Past
          studies of concussion among youth athletes are limited and therefore
          it is still not well understood why some youth recover quickly and
          others continue to have symptoms for a long time. Symptoms may include
          headaches, dizziness, foggy thinking, sleep problems, and emotional
          distress. These symptoms can make it difficult for youth to return to
          school and sports. We need better information about sport-related
          concussion in youth to improve the ways we recognize, manage and
          prevent concussions.
        </p>
        <h5 className="lead mt-3">
          <u>What is the purpose of the study?</u>
        </h5>
        <p>
          The purpose of this study is to establish a national picture of the
          burden of sport-related concussion among youth in Canada, and to
          improve the ways in which concussions are prevented, recognized,
          managed and treated.
        </p>
        <h5 className="lead mt-3">
          <u>Who can participate in this study?</u>
        </h5>
        <p>
          Students are eligible if they are between the ages of 13 and 17,
          presently enrolled in a public or Catholic or private high school in
          British Columbia, and participate in at least one of the following
          sports: basketball, football, ice hockey, ringette, lacrosse, rugby,
          soccer, volleyball, cheerleading, alpine skiing, sledge hockey, or
          wrestling.
        </p>
        <h5 className="lead mt-3">
          <u>Who should not participate in this study?</u>
        </h5>
        <p>
          Students who have a health condition (disease, recent surgery or
          injury) that prevents them from participating in one of the above
          sports, or students whose parents do not agree for them to be in the
          study.
        </p>
        <h5 className="lead mt-3">
          <u>What does the study involve?</u>
        </h5>
        <p>
          This study will involve 6,000 high school athletes from across Canada
          (1,200 from BC – 1000 from Vancouver and 200 from Kelowna) who will be
          in the study for 2-3 years. If your child decides to join the study,
          the activities that they will be asked to do depend on whether or not
          they get a concussion during the school year.
        </p>
        <h5 className="lead mt-3">
          <u>Activities for all participants</u>
        </h5>
        <p>
          If your child is eligible for the study, you will be asked to provide
          us with contact information that includes your first name, your
          preferred phone number, and your child’s full name. We will use this
          information to contact you and explain the details of the study as
          well as allow you time to ask any questions you may have. Once you
          have agreed to participate in this study, you and your child will be
          enrolled.
        </p>
        <p>
          Once enrolled, you will be given a website link to an online data
          portal called REDCap. This portal/website allows you to create an
          account for you and your child where you will fill out information
          regarding your demographics, your child’s injury/medical history and
          your child’s sport participation. Moving forward, this portal will be
          where you or your child will report injuries to the SHRed concussion
          team.
        </p>
        <p>
          Your child will be asked to complete a single 2-hour baseline testing
          session at the start of each school year for 3 years (or until your
          child graduates). The baseline testing will be at UBCO, BrainTrust
          Canada, or at participating school/club facilities in two separate
          visits. The first will be at BrainTrust or at participating
          school/club facilities (approximately 2.5 hours) on a weekend day and
          the second will occur at UBC Okanagan (approximately 1.5 hours) on
          either a weekday evening or weekend day. The baseline tests are
          described in the table below.
        </p>
        <p>
          After the baseline has been done, all participants will be asked to
          report how much time they spent in practices and games during the
          week, as well as any sport-related injuries they might have had that
          week, every week for 3 years. This part is completed online on a
          secure portal and will take about 5 minutes per week to do. If your
          child forgets to fill out their form, you or your child may get a
          phone call, text or email from a study staff member to remind them to
          fill it out. The maximum time to participate in the baseline and
          weekly online reporting will be 8 hours per year, for a total of 24
          hours over the entire study period. As a study participant, your child
          will also have access to a physiotherapist or athletic therapist who
          will come to their school once a week to assess any injuries that they
          may have sustained while playing sports during the school year. SHRed
          study doctors will not be conducting medical assessments with
          participants who do not get a concussion during the study (i.e., care
          for any other injuries/conditions should be sought from your general
          practitioner).
        </p>
        <p>
          You will be asked to complete two short questionnaires about your
          child that will take approximately 5 minutes each at the time of
          baseline. You can do them online at home.
        </p>
        <p>
          Optional blood draws: Blood collection is an optional portion of this
          study meaning your child is not required to participate if you/your
          child do not want to. Blood samples will be drawn by a trained
          phlebotomist or nurse, who can help answer any questions, concerns or
          anxiety you or your child may have about the blood test. If you/ your
          child are uncomfortable or anxious about the blood test, or if they
          want to stop for any reason, they may do so. A maximum of two attempts
          will be made at each session.
        </p>
        <p>
          Blood will be processed within 4 hours of collection into serum and
          plasma. DNA and genetic material will be removed from the sample,
          meaning only the serum and plasma will be used for analysis. The
          serum/plasm samples will be stored in a biobank for analysis. If you
          and your child consent to blood draw, your child’s samples will be
          stored for up to 7 years following the study.
        </p>
        <p>
          As concussion research surrounding fluid biomarkers from blood is
          still evolving and new types of blood tests will be available later,
          SHRed researchers will be asking your permission to store serum/plasma
          specimens in a biobank for as long as they can be used for research. A
          separate consent form will be provided to you for this biobank if you
          are interested in this component of the study.
        </p>
        <h5 className="lead mt-3">
          <u>What are my responsibilities?</u>
        </h5>
        <p>
          It is very important that we are notified as soon as possible in the
          event that your child sustains a sport-related concussion during the
          study period so that we can arrange their first follow up visit within
          72 hours.
        </p>
        <p>
          To report a concussion and book your first study visit, call the Study
          Coordinator at: (250) 863-0255.
        </p>
        <b>
          If your child experiences any of the following red flag symptoms, call
          911 immediately:
        </b>
        <br />
        <ul>
          <li>neck pain or tenderness</li>
          <li>double vision</li>
          <li>weakness or tingling/burning in your arms or legs</li>
          <li>severe or increasing headache</li>
          <li>seizure or convulsion</li>
          <li>loss of consciousness</li>
          <li>deteriorating conscious state</li>
          <li>vomiting</li>
          <li>increasingly restless, agitated or combative</li>
        </ul>
        <p>
          These are signs that your child needs <b>immediate</b> medical
          attention at the nearest Emergency Department. After they get out of
          the hospital you can report your child’s concussion and book their
          first study visit by calling the Study Coordinator.
        </p>

        <u>
          Activities that apply only to those who sustain a sport-related
          concussion:
        </u>
        <p>
          If your child gets a sports-related concussion, contact the study
          coordinator at the earliest possible time after a concussion has
          occurred, to schedule your child’s appointment with the study doctor –
          who is a sports concussion specialist – within 72 hours of their
          injury.
        </p>
        <p>
          If you choose, you may take your child to their family doctor
          following their concussion (prior to seeing our study doctor). The
          SHRed study doctor is experienced in concussion management and will
          take over clinical care, related to their concussion, if you and your
          child agree. If you would like clinical care related to the concussion
          to continue with your family doctor, you may meet with the study
          doctor solely for research purposes. In cases where the study doctor
          will be assuming clinical care for your child’s concussion, we would
          like to emphasize that the study doctors have two roles: 1) clinical
          care and 2) gathering research information. Clinical care of your
          child will <b>always</b> be first priority. The study doctor will
          inform you and your child which assessments are being conducted as
          part of standard clinical concussion care, and which are being done
          for research purposes.
        </p>
        <p>
          Your child will see this doctor 3 times following their injury: (1)
          within approximately 72 hours post injury; (b) 1-2 weeks post injury;
          and (3) 30 days post concussion. If your child’s recovery takes longer
          than 30 days, they will be asked to come to the clinic every 2 weeks
          until their doctor clears them to return to playing sports. During
          these visits, your child will be assessed by the study doctor, and the
          baseline tests will be repeated. Visits 1 & 3 will last about 2 hours
          each. Visit 2 and the bi-weekly visits will take less than 1 hour
          each. You will be asked to repeat the same two questionnaires about
          your child at Visit 1 and Visit 3. You can choose to do these inperson
          at the clinic, or you can do them online at home.
        </p>
        <p>
          If you and your child choose, the study doctors will assume full
          responsibility of coordinating all of the appropriate follow-up and
          medical management of the concussion injury. All other unrelated (to
          the concussion) medical care should be sought by your family
          physician.
        </p>
        <p>
          This study does not involve any new treatments. The study doctor will
          provide your child with the same medical care that your child would
          receive if they saw a concussion doctor outside of the study. If at
          any time during the testing your child feels unwell, or their symptoms
          increase, the tests will be stopped immediately. They can refuse any
          tests they don’t want to do and still be part of the study.
        </p>
        <p>
          This table shows the tests your child will be asked to do if they
          decide to be part of the study:
        </p>
        <img
          src={table_1}
          alt="table one"
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
        />
        <br />
        <small>
          <i>
            *If your child is experiencing symptoms beyond 30 days, we will ask
            them to come in for assessments every 2 weeks until they are cleared
            to return to their activities.
          </i>
        </small>
        <br />
        <small>
          <i>
            **A certified Athletic Therapist or Physiotherapist will come to
            your child’s school once a week to assess any reported injuries in
            person
          </i>
        </small>
        <br />
        <h5 className="lead mt-3">
          <u>Optional Studies</u>
        </h5>
        <p>
          The following studies are optional. For each optional study, you will
          be provided with a separate consent that describes the details, and
          which you will be required to sign if your child wishes to
          participate. Your child can take part in the main study and not take
          part in these optional studies. If you decide not to take part in any
          or all of the optional studies, your child’s care will not be
          affected.
        </p>
        <ol type="A">
          <li>
            <b>Biobank: </b>
            All participants in SHRed will be asked if they would like to make
            their blood samples available to a biobank at UBC for future
            concussion studies. This sub-study uses leftover blood samples from
            the main study and does NOT require additional blood draws.
          </li>
          <li style={{ marginTop: '1rem' }}>
            <b>Fluid bio-markers sub-study: </b>
            Your child might be invited to partake in an optional blood draw if
            they “match” (by age, sex, and primary sport) another participant
            who sustained a concussion.
          </li>
          <li>
            <b>KINARM sub-study: </b>
            Your child might be invited to partake in optional KINARM
            assessments if they “match” (by age, sex, and primary sport) another
            participant who sustained a concussion.
          </li>
          <li>
            <b>Long-term use of data for future studies: </b>
            With your permission, de-identified data from this study may be used
            to answer sports-related concussion research questions in future
            studies. No medical data outside of study data collected in this
            study will be accessed by the research team. You can withdraw your
            consent for future use of data at any time by contacting the Study
            Coordinator without affecting your child’s participation in SHRed.
          </li>
        </ol>
        <h5 className="lead mt-3">
          <u>What are the possible harms and discomforts?</u>
        </h5>
        <i>Physical Assessments</i>
        <br />
        <p>
          All physical assessments will be done under close supervision and
          every effort will be made to ensure your child’s safety. As with any
          physical activity, there is the possibility of a muscle strain for
          tests, such as running. Some testing may result in dizziness or muscle
          fatigue for a short time following the tests. The neck, balance,
          vestibular, eye movement and other clinical tests are ones that are
          typically used in clinical practice. If an increase in discomfort
          occurs above what is typically expected during testing, if your child
          has any injury symptoms at any time, or if they wish to stop testing,
          they should let the study staff know right away and the tests will be
          stopped.
        </p>
        <i>Blood tests</i>
        <br />
        <p>
          The blood tests will be done following standardized laboratory
          procedures by a trained nurse or phlebotomist. Although very rare,
          there is a possibility of local infection within days of having blood
          taken. There is a possibility of a slight bruise at the needle site.
          There is also a remote possibility of fainting. If your child feels
          dizzy or faint, they should tell the nurse or phlebotomist right away.
        </p>
        <i>Brain Imaging</i>
        <br />
        <p>
          Transcranial Doppler sonography is a type of ultrasound imaging that
          produces pictures of the brain and blood flow through the brain. It is
          painless, non-invasive and involves no exposure to radiation. There
          are no associated risks. The technician will place a small amount of
          water-soluble gel on certain portions of your child’s face and head to
          enhance the image, and this gel will wipe off easily after the scan is
          finished.
        </p>
        <h5 className="lead mt-3">
          <u>What are the potential benefits of participating?</u>
        </h5>
        <p>
          There may or may not be direct benefits to your child from taking part
          in this study. Your child will have access to follow-up with a study
          doctor with expertise in youth sport-related concussion within
          approximately 72 hours of sustaining a suspected concussion. It is
          possible that you may learn more about sport-related injuries and
          concussions, and that staff at your child’s school may become more
          knowledgeable about concussions.
        </p>
        <h5 className="lead mt-3">
          <u>Incidental findings</u>
        </h5>
        <p>
          In the unlikely scenario that a researcher observes a suspected
          abnormality in your child’s results (i.e. images, questionnaires), the
          study doctor will be notified immediately. He or she will make a
          determination of its potential significance to your child’s health and
          welfare. If considered to be a finding of potential clinical
          significance, you will be informed and the physician will make
          recommendations for follow-up, including contacting your child’s
          family doctor and working with him or her to make sure that your child
          receives the appropriate medical care. If you do not have a family
          doctor, the study doctors will make appropriate referrals for your
          child’s care.
        </p>
        <h5 className="lead mt-3">
          <u>Primary care physician (family doctor) notification</u>
        </h5>
        <p>
          Please indicate, by checking the applicable box, whether you want us
          to notify your child’s primary care physician(s) of their
          participation in this study. This is not a consent to release medical
          information.
        </p>
        <hr />
        <div className={'form-group row'}>
          <label className="col-lg-8 col-form-label">
            Yes, I want the study investigator to advise my child’s primary care
            physician(s) of my child’s participation in this study:
          </label>
          <div className={'col-lg-2 mb-2'}>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="advise_childs_physician"
                  id="advise_childs_physician"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            My child’s primary care physician(s) name(s) is/are:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="childs_physician_names"
              name="childs_physician_names"
              value={childs_physician_names}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            The name of the medical clinic my child attends is:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="childs_medical_clinic"
              name="childs_medical_clinic"
              value={childs_medical_clinic}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Parent Initials:</label>
          <div className={'col-lg-1 mb-2'}>
            <input
              maxLength={3}
              type="text"
              className="form-control"
              id="parent_initials_one"
              name="parent_initials_one"
              value={parent_initials_one}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-8 col-form-label">
            No, I do not want the study investigator to advise my child’s
            primary care physician(s) of my child’s participation in this study:
          </label>
          <div className={'col-lg-2 mb-2'}>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="do_not_advise_childs_physician"
                  id="do_not_advise_childs_physician"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Parent Initials:</label>
          <div className={'col-lg-1 mb-2'}>
            <input
              maxLength={3}
              type="text"
              className="form-control"
              id="parent_initials_two"
              name="parent_initials_two"
              value={parent_initials_two}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-8 col-form-label">
            My child does not have a primary care physician or specialist:
          </label>
          <div className={'col-lg-2 mb-2'}>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="child_has_no_physician"
                  id="child_has_no_physician"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Parent Initials:</label>
          <div className={'col-lg-1 mb-2'}>
            <input
              maxLength={3}
              type="text"
              className="form-control"
              id="parent_initials_three"
              name="parent_initials_three"
              value={parent_initials_three}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <p>
          I understand that if I choose not to advise my child’s primary care
          physician(s) of my child’s participation in this study, there may be
          potential medical consequences which may affect my child’s
          comprehensive medical care or treatment. I understand that the study
          investigator may not be responsible for these consequences.
        </p>
        <hr />
        <h5 className="lead mt-3">
          <u>What are the alternatives to the study treatment?</u>
        </h5>
        <p>
          This study does not involve treatments beyond the standard of care. If
          you choose not to take part in this study, your child’s medical care
          will not be affected.
        </p>
        <h5 className="lead mt-3">
          <u>
            What if new information becomes available that may affect my
            decision to participate?
          </u>
        </h5>
        <p>
          You will be informed if new information becomes available that may
          affect your willingness to remain in this study.
        </p>
        <h5 className="lead mt-3">
          <u>What happens if I decide to withdraw my consent to participate?</u>
        </h5>
        <p>
          You can decide to withdraw your child from the study at any time and
          you do not have to give a reason. Contact the Study Coordinator to
          withdraw from the study. The Study Coordinator will communicate with
          you to confirm that your child has been withdrawn and if you have
          requested that your child’s data be withdrawn and any samples
          destroyed, the Study Coordinator will communicate with you to confirm
          that this has been done also.
        </p>
        <h5 className="lead mt-3">
          <u>Can my child be asked to leave the study?</u>
        </h5>
        <p>
          If your child is not able to follow the requirements of the study or
          for any other reason, the study doctor may withdraw your child from
          the study and, if they are receiving concussion follow ups at that
          time, the doctor will arrange for your child’s care to continue. If
          your child is asked to leave the study, the reasons for this will be
          explained to you and you will have the opportunity to ask questions
          about this decision.
        </p>
        <h5 className="lead mt-3">
          <u>
            How will my and my child’s information in this study be kept
            confidential?
          </u>
        </h5>
        <p>
          You and your child’s privacy will be respected. Unless you allow them
          to, the study team will not disclose that your child is in or has been
          a part of this study. They will not release any information that could
          be used to identify your child, unless they are required to do so by
          law. For example, researchers are required to report if a participant
          is believed to be at risk for harming him/herself or others. As part
          of the study your child will fill out some questionnaires that will
          ask about how he/she is feeling. If the study doctors are worried
          about your child they will contact your child’s family doctor if you
          have given them permission to do so and they will contact you to make
          sure that your child gets any additional care that is needed.
        </p>
        <p>
          You and your child will each be identified by specific study codes
          that apply only to you or him/her. Any paper forms that are part of
          the study will use this code rather than you or your child’s name. The
          Principal Investigators will keep the key file linking you and your
          child’s identifying information to this code at the local study site
          (UBCO), in a locked file cabinet. Computer files are password
          protected and stored in a secure server at UBCO, accessible to the
          research team at UBCO only.
        </p>
        <p>
          A larger than usual number of identifiers are being collected and
          stored in a secure, online data server (REDCap). This is to allow for
          interface personalization (e.g. your name to appear on the online
          portal), the central storage of data, and data quality. REDCap uses
          authentication for users, encryption, and password protection in
          accordance with Personal Health Information Protection and Privacy Act
          (HIPPA) guidelines, and in accordance with University of Calgary
          information Security Control Requirements approval, and stored on an
          OVH Canada dedicated server in compliance with University of Calgary
          requirements. REDCap is housed by the University of Calgary and can
          only be accessed by Dr. Carolyn Emery and her research staff, as well
          as the UBCO SHRed Research Team. Members of the University of Calgary
          team are required to sign a confidentiality agreement, and research
          staff at UBC complete Privacy and Information Security training.
        </p>
        <p>
          For this study we will be collecting personal identifiers which
          include: Parent and child full name, age, month and year of birth,
          phone number, email, city, and school name for the research purposes
          described in this consent form. Although steps have been taken to
          protect your privacy, this could increase the risk of your
          re-identification. Identifying information will be accessible to UBCO
          and University of Calgary SHRed study personnel only. Researchers
          outside of the local site would not normally have access to personal
          identifiers; this is unique to this study. Your personal information
          will not be used to contact you for recruitment into future research
          studies without your consent and all future contact will be made
          directly by the BC research team. All identifying information will be
          removed from the Calgary-based REDCap portal by August 31, 2024, which
          is the earliest possible date to do so. The local investigators
          (BrainTrust/UBCO) will retain the file that links identifiers to the
          study ID code for 5 years following the end of the study, in a locked
          cabinet in a locked office, as per UBC policy requirements.
        </p>
        <p>
          We are asking to collect your email address because it is required for
          creation of an online SHRed Concussions account, and access to the
          portal. We will also use your email to contact you about scheduling
          calls/appointments when necessary. Although you may not be aware of
          this fact, emails sent to some webmail services (e.g. Gmail, Hotmail,
          etc.), may be stored/routed outside of Canada (for example, in the
          United States). Due to the fact that future emails will contain
          personal information about you, including your name, the Freedom of
          Information and Protection of Privacy Act requires that we obtain your
          consent before we continue. We will only send your personal
          information to the email address you have provided to us. All of the
          information which you provide to us will be kept completely
          confidential. Providing your email address means that you voluntarily
          agree and give your consent for the study team to email your personal
          information to you.
        </p>
        <p>
          <b>
            I have read, and understand and approve that my and my child’s
            identifying information will be available via a secure online portal
            to SHRed researchers at UBCO (the Principal Investigators and study
            coordinators) and the University of Calgary (Principal
            Investigators, national coordinator, data management coordinator,
            technical coordinator, and statistician).
          </b>
        </p>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            <b>Parent Initials:</b>
          </label>
          <div className={'col-lg-1 mb-2'}>
            <input
              maxLength={3}
              type="text"
              className="form-control"
              id="parent_initials_five"
              name="parent_initials_five"
              value={parent_initials_five}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <h5 className="lead mt-3">
          <u>What happens if something goes wrong?</u>
        </h5>
        <p>
          By signing this form, you do not give up any of your legal rights and
          you do not release the principal investigator, participating
          institutions, or anyone else from their legal and professional duties.
          In the event that your child suffers an injury as a direct result of
          participating in this study, the research team will ensure that they
          are connected with medical care. The costs of your medical treatment
          will be paid by your provincial medical plan.
        </p>
        <h5 className="lead mt-3">
          <u>What will the study cost me?</u>
        </h5>
        <p>
          There will be no financial costs to you as a participant in this
          study. We will reimburse you for parking or transit when your child
          attends study visits. Juice and snacks will be offered to your child
          at the time of each blood test. You will not be paid for participating
          in this study.
        </p>
        <h5 className="lead mt-3">
          <u>
            Who do I contact if I have questions about the study during my
            child’s participation or to report an injury?
          </u>
        </h5>
        <p>
          UBC Co-Investigators: Dr. Ian Pike (604) 875-3425; Dr. Shelina Babul
          (604) 875-3682; Dr. Paul van Donkelaar (250) 807-8858
          <br />
          Study Coordinator: Sierra Sanchez (250) 863-0255
        </p>
        <h5 className="lead mt-3">
          <u>
            Who do I contact if I have questions or concerns about my child’s
            rights as a research participant?
          </u>
        </h5>
        <p>
          If you have any concerns or complaints about your child’s rights as a
          research participant and/or your child’s experiences while
          participating in this study, contact the Research Participant
          Complaint Line in the University of British Columbia Office of
          Research Ethics by e-mail at RSIL@ors.ubc.ca or by phone at
          604-822-8598 (Toll Free: 1-877-822-8598). Please reference the study
          number (H19-00037) when contacting the Complaint Line so the staff can
          better assist you.
        </p>
        <h5 className="lead mt-3">
          <u>After the study is finished</u>
        </h5>
        <p>
          All identifying information for all participants will be removed from
          the REDCap portal by the earliest possible date, August 31, 2024. All
          study-related documents will be maintained after the study ends at
          UBCO, or in an off-site secure storage location such as is used by
          legal and government firms, for 7 years; after that the documents will
          be destroyed. Study results will be communicated to schools in annual
          progress updates, or as required by the schools or school districts.
        </p>
        <hr />
        <p>My signature on this consent form means:</p>
        <ul>
          <li>
            I have read and understood the information on this consent form.
          </li>
          <li>
            I have had enough time to think about the information provided and
            discussed it with my child.
          </li>
          <li>
            I have been able to ask questions and have had satisfactory
            responses to my questions.
          </li>
          <li>
            I understand that all of the information collected will be kept
            confidential and that the results will only be used for scientific
            purposes.
          </li>
          <li>
            I understand that my child’s participation in this study is
            voluntary.
          </li>
          <li>
            I understand that I am completely free at any time to refuse to
            participate or to withdraw my child from this study at any time, and
            that this will not change the quality of care that my child
            receives, or affect their involvement in their school or team(s).
          </li>
          <li>
            I understand that I am not waiving any of my child’s legal rights as
            a result of signing this consent form.
          </li>
          <li>
            I understand that there is no guarantee that this study will provide
            any benefits to my child.
          </li>
          <li>I have received a copy of this form for my own records.</li>
          <li>I consent to my child’s participation in this study.</li>
        </ul>
        <div className={'form-group row'}>
          <label className="col-lg-8 col-form-label">
            Please check this box if you are willing to be contacted by
            investigators at the local site for future studies about
            sport-related concussion among youth. Checking this box does NOT
            obligate you to participate:
          </label>
          <div className={'col-lg-2 mb-2'}>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="contacted_in_future"
                  id="contacted_in_future"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <br />
        <p>
          This consent form was read by the parent(s)/guardian(s)/substitute
          decision-maker (legally authorized representative), and both the
          person reading this consent form and the investigator are satisfied
          that:
        </p>
        <ul>
          <li>
            The study information was accurately explained to, and apparently
            understood by, the child/participant.
          </li>
          <li>
            The child/participant was given an opportunity to ask questions, and
            all questions have been answered.
          </li>
          <li>
            The child/participant assents to participating in the research.
          </li>
        </ul>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Participant Name:</label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="participant_name"
              name="participant_name"
              value={participant_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Parent/Representative Name:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_name"
              name="parent_name"
              value={parent_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Name of Person Obtaining Consent:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="name_of_person_obtaining_consent"
              name="name_of_person_obtaining_consent"
              value={name_of_person_obtaining_consent}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Study Role:</label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="study_role"
              name="study_role"
              value={study_role}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <hr />
      </div>
    )
  }
}
