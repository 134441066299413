import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

import reducers from '../reducers'
import { routeMiddleware } from './history'

const middlewares = []

middlewares.push(thunkMiddleware)
middlewares.push(routeMiddleware)

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`)
  middlewares.push(logger)
}

export const store = createStore(reducers, applyMiddleware(...middlewares))
