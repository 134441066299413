import ReactGA from 'react-ga'
import jwtDecode from 'jwt-decode'

export const analyticsActions = {
  loginEvent,
}

function loginEvent() {
  return () => {
    if (process.env.NODE_ENV !== `development`) {
      const token = sessionStorage.getItem('token')

      if (token) {
        const userId = jwtDecode(token).user_claims.id

        if (userId) {
          ReactGA.set({
            dimension1: userId,
          })
        }
      }

      ReactGA.event({
        category: 'User',
        action: 'Logged in',
        label: '',
      })
    }
  }
}
