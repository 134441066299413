import { notify } from 'react-notify-toast'

const successColour = { background: '#4CAF50', text: '#ffffff' }
const errorColour = { background: '#F44336', text: '#ffffff' }

export const alertActions = {
  success,
  error,
}

function success(message) {
  return () => {
    notify.show(message, 'custom', 4000, successColour)
  }
}

function error(message) {
  return () => {
    notify.show(message, 'custom', 4000, errorColour)
  }
}
