import React from 'react'

import ReactModal from 'react-modal'
import Modal from 'react-modal'
import { XCircle } from 'react-feather'
import { Translate, withLocalize } from 'react-localize-redux'

import './Modal.css'

class ConfirmationSentModal extends React.Component {
  componentDidMount() {
    Modal.setAppElement('body')
  }

  render() {
    const { showModal, onClose } = this.props

    return (
      <div>
        <ReactModal
          style={{
            overlay: { backgroundColor: 'rgba(33, 33, 33, 0.85)' },
            content: {
              maxWidth: 700,
              maxHeight: 350,
              width: '80%',
              margin: 'auto',
            },
          }}
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          closeTimeoutMS={200}
          onRequestClose={onClose}
        >
          <XCircle
            size={32}
            onClick={onClose}
            style={{ cursor: 'pointer', float: 'right' }}
          />
          <div className="container mt-5 text-center">
            <h3>
              <Translate id="confirmationSentModal.thankYou" />
            </h3>
            <p className="lead text-muted">
              <Translate id="confirmationSentModal.verifyEmail" />
            </p>
            <p>
              <Translate id="confirmationSentModal.emailSent" />
            </p>
          </div>
        </ReactModal>
      </div>
    )
  }
}

export default withLocalize(ConfirmationSentModal)
