import React from 'react'

import { Translate } from 'react-localize-redux'

import AddChild from './AddChild'

export class NoChildren extends React.Component {
  render() {
    return (
      <div>
        <div>
          <div className="text-center">
            <h1>
              <Translate id="noChildren.thankYou" />
            </h1>
            <p className="lead">
              <Translate id="noChildren.registerYourChild" />
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <AddChild />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
