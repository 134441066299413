import { handleAxiosError } from '../helpers'
import { instance } from '../helpers'

export const sportServices = {
  get,
}

function get() {
  return instance
    .get('/sports')
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
