import React from "react";
import DatePicker from "react-datepicker";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";

import { newInjuryServices } from "../../../services";
import { store } from "../../../helpers/index";
import { alertActions, sportActions } from "../../../actions";
import "./Child.css";
import { Section } from "../SectionHeading/Section";

class NewConcussionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      injuryDate: "",
      validated: false,
      symptomValue: "",
      preTreatedValue: "",
      sportValue: "",
      irfAbleToReturn: "",
      irfEvents: ""
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkValid = this.checkValid.bind(this);
    this.handleSymptomChange = this.handleSymptomChange.bind(this);
    this.handlePreTreatedChange = this.handlePreTreatedChange.bind(this);
    this.handleSportChange = this.handleSportChange.bind(this);
    this.handleIrfAbleToReturnChange = this.handleIrfAbleToReturnChange.bind(
      this
    );
    this.handleIrfEventsChange = this.handleIrfEventsChange.bind(this);
  }

  componentDidMount() {
    const { dispatch } = store;

    if (!this.props.sports) dispatch(sportActions.get());
  }

  handleDateChange(date) {
    if (date) {
      this.setState(
        {
          injuryDate: date
        },
        this.checkValid
      );
    }
  }

  handleDateChangeRaw = e => {
    e.preventDefault();
  };

  handleSportChange(e) {
    let x = e.currentTarget.value;
    if (e.currentTarget.value === "select..") {
      x = "";
    } else {
      x = e.currentTarget.value;
    }
    this.setState(
      {
        sportValue: x
      },
      this.checkValid
    );
  }

  handleSymptomChange(e) {
    this.setState(
      {
        symptomValue: e.currentTarget.value
      },
      this.checkValid
    );
  }

  handlePreTreatedChange(e) {
    this.setState(
      {
        preTreatedValue: e.currentTarget.value
      },
      this.checkValid
    );
  }

  handleIrfAbleToReturnChange(e) {
    this.setState(
      {
        irfAbleToReturn: e.currentTarget.value
      },
      this.checkValid
    );
  }

  handleIrfEventsChange(e) {
    this.setState(
      {
        irfEvents: e.target.value
      },
      this.checkValid
    );
  }

  handleSubmit() {
    const { dispatch } = store;
    const { injuryType } = this.props;
    const {
      validated,
      injuryDate,
      symptomValue,
      preTreatedValue,
      sportValue,
      irfAbleToReturn,
      irfEvents
    } = this.state;

    if (validated) {
      newInjuryServices
        .create({
          injury_date: injuryDate,
          symptom: symptomValue,
          pre_treated: preTreatedValue,
          sport: sportValue,
          irf_able_to_return: irfAbleToReturn,
          irf_events: irfEvents,
          injury_type: injuryType === "newConcussion" ? "concussion" : "injury"
        })
        .then(
          response => {
            if (injuryType === "newConcussion") {
              dispatch(
                alertActions.success(
                  this.props.translate(
                    "newConcussionForm.newConcussionSubmitted"
                  )
                )
              );
              this.props.handleInjuryChange();
              this.props.handleConcussionSubmit();
            } else
              dispatch(
                alertActions.success(
                  this.props.translate(
                    "newConcussionForm.newNonConcussionSubmitted"
                  )
                )
              );

            this.setState({
              injuryDate: "",
              validated: false,
              sportValue: ""
            });
            dispatch(
              push({
                pathname: "/profile/dashboard",
                search: "?childTab=0&contentTab=baseline"
              })
            );
          },
          error => {
            if (error.response) {
              const errors = error.response.data.errors;
              if (errors) {
                errors.forEach(e => {
                  dispatch(alertActions.error(e));
                });
              }
            }
          }
        );
    }
  }

  checkValid() {
    const {
      injuryDate,
      preTreatedValue,
      symptomValue,
      sportValue,
      irfAbleToReturn,
      irfEvents
    } = this.state;
    const { translate } = this.props;
    if (
      injuryDate &&
      preTreatedValue &&
      symptomValue &&
      irfAbleToReturn &&
      irfEvents &&
      sportValue !== translate("newConcussionForm.selectSport") + "..."
    ) {
      this.setState({
        validated: true
      });
    } else {
      this.setState({
        validated: false
      });
    }
  }

  render() {
    const { injuryType, sports, role, translate } = this.props;
    const { validated, injuryDate, sportValue } = this.state;

    const text =
      injuryType === "newConcussion"
        ? translate("newConcussionForm.concussion")
        : translate("newConcussionForm.injury");

    return (
      <div>
        <Section
          heading={
            injuryType === "newConcussion"
              ? translate("newConcussionForm.sectionHeader1")
              : translate("newConcussionForm.sectionHeader2")
          }
          role={role}
          translate={translate}
        />
        <div
          style={{
            minHeight: "70vh",
            maxWidth: "700px",
            paddingLeft: "15px"
          }}
        >
          <div>
            <div className="form-group row">
              <label className="col-lg-6 col-form-label">
                {translate("newConcussionForm.form.label1") + text}
              </label>
              <div className="col-lg-4 mb-2">
                <DatePicker
                  id="injuryDate"
                  className="form-control"
                  name="injuryDate"
                  selected={injuryDate}
                  placeholderText={translate(
                    "newConcussionForm.form.datePickerFormat"
                  )}
                  maxDate={new Date()}
                  peekNextMonth
                  dropdownMode="select"
                  onChange={this.handleDateChange}
                  onChangeRaw={this.handleDateChangeRaw}
                  autoComplete="off"
                  dateFormat="yy/MM/dd"
                ></DatePicker>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-6 col-form-label">
                <Translate id="newConcussionForm.sport" />
              </label>
              <div className="col-lg-4 mb-2">
                <select
                  className="form-control"
                  onChange={this.handleSportChange}
                  value={sportValue}
                >
                  <option>
                    {translate("newConcussionForm.selectSport")}...
                  </option>
                  {sports &&
                    sports.map((sport, index) => {
                      return (
                        <option value={sport.id} key={index}>
                          {sport.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-6 col-form-label">
                {translate("newConcussionForm.form.label2") +
                  text +
                  translate("newConcussionForm.form.label3") +
                  text +
                  "?"}
              </label>

              <div className="col-lg-4 mb-2">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="symptom"
                      id="symptom_yes"
                      value="1"
                      onClick={this.handleSymptomChange}
                    />
                    <Translate id="newConcussionForm.form.valueYes" />
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="symptom"
                      id="symptom_no"
                      value="0"
                      onClick={this.handleSymptomChange}
                    />
                    <Translate id="newConcussionForm.form.valueNo" />
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-6 col-form-label">
                {translate("newConcussionForm.form.label4") +
                  text +
                  translate("newConcussionForm.form.label5") +
                  text +
                  "?"}
              </label>

              <div className="col-lg-4 mb-2">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pre_treated"
                      id="pre_treated_yes"
                      value="1"
                      onClick={this.handlePreTreatedChange}
                    />
                    <Translate id="newConcussionForm.form.valueYes" />
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pre_treated"
                      id="pre_treated_no"
                      value="0"
                      onClick={this.handlePreTreatedChange}
                    />
                    <Translate id="newConcussionForm.form.valueNo" />
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label
                className="col-lg-12 col-form-label"
                style={{ margin: "1rem 0" }}
              >
                <Translate id="newConcussionForm.form.label6" />
              </label>

              <div className="col-lg-12 mb-2">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="irf_able_to_return"
                      id="irf_able_to_return_1"
                      value="1"
                      onClick={this.handleIrfAbleToReturnChange}
                    />
                    <Translate id="newConcussionForm.form.label7" />
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="irf_able_to_return"
                      id="irf_able_to_return_2"
                      value="2"
                      onClick={this.handleIrfAbleToReturnChange}
                    />
                    <Translate id="newConcussionForm.form.label8" />
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="irf_able_to_return"
                      id="irf_able_to_return_3"
                      value="3"
                      onClick={this.handleIrfAbleToReturnChange}
                    />
                    <Translate id="newConcussionForm.form.label9" />
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="irf_able_to_return"
                      id="irf_able_to_return_4"
                      value="4"
                      onClick={this.handleIrfAbleToReturnChange}
                    />
                    <Translate id="newConcussionForm.form.label10" />
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="irf_able_to_return"
                      id="irf_able_to_return_5"
                      value="5"
                      onClick={this.handleIrfAbleToReturnChange}
                    />
                    <Translate id="newConcussionForm.form.label11" />
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label
                className="col-lg-12 col-form-label"
                style={{ margin: "1rem 0" }}
              >
                <Translate id="newConcussionForm.form.label12" />
                <br />
                <small>
                  <i>
                    <Translate id="newConcussionForm.form.label13" />
                  </i>
                </small>
              </label>
              <div className="col-lg-12 mb-2">
                <textarea
                  style={{
                    width: "100%",
                    border: "1px solid grey",
                    borderRadius: "5px",
                    padding: "1rem"
                  }}
                  placeholder="..."
                  onChange={this.handleIrfEventsChange}
                />
              </div>
            </div>

            <br />
            <br />
            <button
              style={{ float: "left" }}
              type="button"
              className={validated ? "btn btn-primary" : "btn btn-disabled"}
              onClick={this.handleSubmit}
            >
              <Translate id="newConcussionForm.form.submit" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sports } = state.sport;

  return {
    sports
  };
}

export default withLocalize(connect(mapStateToProps)(NewConcussionForm));
