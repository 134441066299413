import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  requireNoAuthentication,
  requireAuthentication,
  checkAuthentication,
} from '../hocs/Access'
import { NotFound } from '../containers/NotFound'
import { About } from '../containers/About'
import {
  ActivateChild,
  ResetPassword,
  Confirmation,
  Signup,
} from '../containers/Account'
import { ContactUs } from '../containers/ContactUs'
import { Home } from '../containers/Home'
import { Dashboard, Settings } from '../containers/Profile'
import { default as ForgotPassword } from '../containers/Account/ForgotPassword'
import { CloseSurvey } from '../containers/CloseSurvey'

export default (
  <Switch>
    <Route exact path="/" component={checkAuthentication(Home)} />
    <Route path="/about" component={checkAuthentication(About)} />
    <Route path="/signup" component={requireNoAuthentication(Signup)} />
    <Route path="/contact-us" component={checkAuthentication(ContactUs)} />
    <Route
      path="/forgot-password"
      component={requireNoAuthentication(ForgotPassword)}
    />
    <Route
      exact
      path="/confirmation/:token"
      component={requireNoAuthentication(Confirmation)}
    />
    <Route
      exact
      path="/recoverable/:token"
      component={requireNoAuthentication(ResetPassword)}
    />
    <Route
      exact
      path="/activation/:token"
      component={requireNoAuthentication(ActivateChild)}
    />
    <Route
      path="/profile/dashboard"
      component={requireAuthentication(Dashboard)}
    />
    <Route
      path="/profile/settings"
      component={requireAuthentication(Settings)}
    />
    <Route path="/profile/*" component={NotFound} />
    <Route path="/close-survey" component={CloseSurvey} />
    <Route path="*" component={NotFound} />
  </Switch>
)
