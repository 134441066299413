import React from 'react'
import DatePicker from 'react-datepicker'
import header from '../../images/Consent/Calgary/header.png'

export class CalgaryConsent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      agree_to_be_contacted: false,
      parent_name: '',
      parent_email: '',
      parent_phone_number: '',
    }
  }

  validateComplete = () => {
    const { parent_name, parent_email, parent_phone_number } = this.state

    let data = this.state

    if (parent_name && parent_email && parent_phone_number) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (['parent_name', 'parent_email', 'parent_phone_number'].includes(name)) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    } else if (['agree_to_be_contacted'].includes(name)) {
      this.setState(
        {
          [name]: event.target.checked,
        },
        this.validateComplete
      )
    }
  }

  render() {
    const { parent_name, parent_email, parent_phone_number } = this.state

    const { translate } = this.props

    return (
      <div>
        <img
          src={header}
          alt="header"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
          }}
        />
        <h4>{translate('calgaryConsent.t1')}</h4>
        <h5 className="lead mt-3">{translate('calgaryConsent.t2')}:</h5>
        {translate('calgaryConsent.t4')}
        <h5 className="lead mt-3">{translate('calgaryConsent.t5')}:</h5>
        {translate('calgaryConsent.t6')}
        <h5 className="lead mt-3">{translate('calgaryConsent.t7')}:</h5>
        <p>{translate('calgaryConsent.t8')}</p>
        <p>
          <i>{translate('calgaryConsent.t9')}</i>
        </p>
        <h5 className="lead mt-3">{translate('calgaryConsent.t10')}:</h5>
        <p>{translate('calgaryConsent.t11')}</p>
        <h5 className="lead mt-3">{translate('calgaryConsent.t12')}</h5>
        <div>
          {translate('calgaryConsent.t13')}
          <ol>
            <li>{translate('calgaryConsent.t14')}</li>
            <li>{translate('calgaryConsent.t15')}</li>
            <li>{translate('calgaryConsent.t16')}</li>
            <li>{translate('calgaryConsent.t17')}</li>
            <li>{translate('calgaryConsent.t18')}</li>
            <li>{translate('calgaryConsent.t19')}</li>
          </ol>
          <p>{translate('calgaryConsent.t20')}</p>
        </div>
        <h5 className="lead mt-3">{translate('calgaryConsent.t21')}</h5>
        <div>
          <p>{translate('calgaryConsent.t22')}</p>
          <p>{translate('calgaryConsent.t23')}</p>
          <div>
            {translate('calgaryConsent.t24')}:
            <ol>
              <li>
                {translate('calgaryConsent.t25')}
                <ul>
                  <li>{translate('calgaryConsent.t26')}</li>
                  <li>{translate('calgaryConsent.t27')}</li>
                  <li>
                    Connor-Davidson Resilience Scale (this measures the “ability
                    to "thrive in the face of adversity."). Completed by your
                    child only.
                  </li>
                  <li>{translate('calgaryConsent.t28')}</li>
                  <li>{translate('calgaryConsent.t29')}</li>
                  <li>{translate('calgaryConsent.t29-1')}</li>
                  <li>{translate('calgaryConsent.t30')}</li>
                  <li>{translate('calgaryConsent.t31')}</li>
                  <li>{translate('calgaryConsent.t33')}</li>
                </ul>
              </li>
              <li>
                {translate('calgaryConsent.t34')}
                <ul>
                  <li>{translate('calgaryConsent.t35')}</li>
                  <li>{translate('calgaryConsent.t36')}</li>
                  <li>{translate('calgaryConsent.t37')}</li>
                  <li>{translate('calgaryConsent.t38')}</li>
                  <li>{translate('calgaryConsent.t39')}</li>
                  <li>{translate('calgaryConsent.t40')}</li>
                  <li>{translate('calgaryConsent.t41')}</li>
                  <li>{translate('calgaryConsent.t42')}</li>
                </ul>
                <div>
                  {translate('calgaryConsent.t43')}:
                  <ul>
                    <li>{translate('calgaryConsent.t44')}</li>
                    <li>{translate('calgaryConsent.t45')}</li>
                  </ul>
                </div>
                <p>{translate('calgaryConsent.t46')}</p>
              </li>
              <li>
                {translate('calgaryConsent.t47')}:<br />
                <br />
                <p>{translate('calgaryConsent.t48')}</p>
                <div>
                  {translate('calgaryConsent.t49')}
                  <ul>
                    <li>{translate('calgaryConsent.t50')},</li>
                    <li>{translate('calgaryConsent.t51')},</li>
                    <li>{translate('calgaryConsent.t52')}</li>
                    <li>{translate('calgaryConsent.t53')}</li>
                    <li>{translate('calgaryConsent.t54')}</li>
                    <li>{translate('calgaryConsent.t55')}</li>
                    <li>{translate('calgaryConsent.t56')}</li>
                    <li>{translate('calgaryConsent.t57')}</li>
                    <li>{translate('calgaryConsent.t58')}</li>
                  </ul>
                </div>
                <br />
              </li>
              <li>
                {translate('calgaryConsent.t59')}
                <p>{translate('calgaryConsent.t60')}</p>
              </li>
            </ol>
          </div>
        </div>
        <h5 className="lead mt-3">{translate('calgaryConsent.t61')}</h5>
        <p>
          Due to the COVID-19 pandemic, all of the research, with the exception
          of clinic related activities due to concussion or injury, will be
          moving online. As such, we wanted to inform you that with the shift to
          online, research related risks may include privacy and security of the
          IT/communication platforms used. With the switching to online meeting
          tools the research team will be enhancing the data security provisions
          through the use of institutional online platform accounts which
          require a password for meetings and if recording, ensure data is
          stored locally.
        </p>
        <p>
          As the research team will be instituting virtual meetings with
          participants, there are no new risks related to transmission of the
          COVID-19 virus. If the participant requires clinical assessment due to
          a concussion or other injury, there are risks of transmission of the
          COVID-19 virus. The research team and clinic staff will take all
          precautions necessary, including use of PPE by team members and
          participants to mitigate the possibility of transmission.
        </p>
        <p>
          For those participants who are undergoing concussion or injury
          assessments, and when face to face interactions are allowed by both
          the University of Calgary and your school board or sport governing
          body (where applicable), the following are the risks to participants:
        </p>
        <p>
          COVID-19 - There are risks of transmission of the COVID-19 virus.
          Researchers and clinic staff will mitigate the possibility of
          transmission through:
        </p>
        <ul>
          <li>use of secure, remote interactions/methods where feasible</li>
          <li>screening those people attending in-person appointments</li>
          <li>
            use/provision of PPE for both research staff and research
            participants (e.g., masks, gloves)
          </li>
          <li>
            use/provision of hand sanitizer for both research staff and research
            participants (e.g., masks, gloves)
          </li>
          <li>single use research apparatus where possible</li>
          <li>physical distancing measures</li>
          <li>
            sanitization of surfaces and multi-use equipment between
            patients/participants
          </li>
        </ul>
        <p>
          Physical Assessments – All physical assessments will be done under
          close supervision and every effort will be made to ensure the safety
          of your child. As with any physical activity, there is the possibility
          of a muscle strain for tests, such as running. Some testing may result
          in dizziness or muscle fatigue for a short time following the tests.
          Your child may also experience mild discomfort when the tester
          prepares the skin for electrode placement. Some also find breathing
          through a mouthpiece while exercising slightly more difficult than
          breathing without a mouthpiece. The risk of injury will be reduced by
          careful supervision by trained research team members during testing
          procedures. The neck, balance, vestibular, eye movement and other
          tests are ones that are typically used in clinical practice. These
          tests will all be done by clinicians who have training in the tests.
          If an increase in discomfort occurs above what is typically expected
          during testing or if your child wishes to stop testing, your child
          should let the tester know. If your child does have any symptoms at
          any time during testing, they should let the tester know and the test
          will be stopped.
        </p>
        <p>
          Blood tests - The blood tests will be done following standardized
          laboratory procedures. The person taking your child’s blood will be a
          trained technician. Although very rare, there is a possibility of
          local infection within days of having blood taken. There is also a
          remote possibility of fainting. There is a possibility of a slight
          bruise at the needle site.
        </p>
        <p>
          Magnetic resonance imaging (MRI) is a technique that uses magnets and
          radio waves, not radiation, to take pictures of the body. MRI has no
          known harmful effects as long as your child has none of the risk
          factors that will be screened for by the MRI technologist.
          Specifically, your child should not have an MRI if they have a
          pacemaker or certain other metal objects inside their body (including
          dental braces), because the strong magnets in the MR scanner might
          cause these to heat up or move, causing harm. Your child will also
          need to remove all metal from their clothing and pockets; otherwise
          these objects could be pulled into the magnet and cause harm. No metal
          can be brought into the magnet room at any time, since the magnet is
          always “on”. During the MRI session, your child will lie on a padded
          table and be asked to hold as still as possible while pictures are
          being taken. The MRI technologist will be carefully monitoring the
          session and will answer any questions or concerns that you or your
          child may have during the session. When the scan begins, your child
          will hear a loud knocking noise (like a drum beat) that can change at
          times during the scan. If your child cannot lie still enough to
          complete a high-quality scan, is uncomfortable or anxious, or wants to
          stop for any reason, they can be removed from the scanner immediately.
          Further, MRI will not be performed if your child feels too
          claustrophobic to enter the scanner.
        </p>
        <p>
          Near-Infrared (NIR) light can be used to measure blood flow responses
          in the brain. Near-infrared spectroscopy (NIRS) systems offer a
          non-invasive and safe way to measure oxygen content in the brain. This
          is done by shining light into the tissue. Different light is absorbed
          differently by blood. The light is measured using fiber optics. These
          fiber optics will be placed on the head using a head cap.
        </p>
        <p>
          Electroencephalography (EEG) can be used to measure neuronal activity
          in the brain. EEG offers a non-invasive and safe way to measure brain
          activity. This is done by attaching electrodes to the scalp. These
          electrodes are sensitive to electric signals and thereby record
          electrical signals in their vicinity. The electrodes will be placed on
          the head using a head cap. An electrocardiogram (ECG) monitors heart
          function by measuring electrical activity of the heart. This is
          achieved through the placement of electrodes on three positions on the
          chest.
        </p>
        <p>
          If you consent to be part of this study, we will use fNIRS/EEG/ECG to
          examine brain activity, cardiac rhythm, and oxygen levels.
          Furthermore, we will examine these measures in relation to
          questionnaires relating to chronic and acute stress, pain, fatigue,
          and concussion history.
        </p>
        <p>
          The EEG, fNIRS, and ECG techniques are very safe and there are no
          risks to them.
        </p>
        <h5 className="lead mt-3">{translate('calgaryConsent.t65')}</h5>
        <p>{translate('calgaryConsent.t66')}</p>
        <p>{translate('calgaryConsent.t67')}</p>
        <p>
          <b>{translate('calgaryConsent.t68')}</b>
        </p>
        <p>{translate('calgaryConsent.t69')}</p>
        <h5 className="lead mt-3">{translate('calgaryConsent.t70')}</h5>
        <p>{translate('calgaryConsent.t71')}</p>
        <p>{translate('calgaryConsent.t72')}</p>
        <h5 className="lead mt-3">{translate('calgaryConsent.t73')}</h5>
        <p>{translate('calgaryConsent.t74')}</p>
        <p>
          We will be offering pizza parties, as well as having random draws for
          participants for U of Calgary Dinos, Calgary Hitmen, or other games.
        </p>
        <h5 className="lead mt-3">{translate('calgaryConsent.t75')}</h5>
        <p>{translate('calgaryConsent.t76')}</p>
        <p>{translate('calgaryConsent.t77')}</p>
        <h5 className="lead mt-3">{translate('calgaryConsent.t78')}</h5>
        <p>{translate('calgaryConsent.t79')}</p>
        <h5 className="lead mt-3">{translate('calgaryConsent.t80')}:</h5>
        <p>{translate('calgaryConsent.t81')}</p>
        <p>
          {translate('calgaryConsent.t82')} {translate('calgaryConsent.t83')}{' '}
          Carolyn Emery 403-220-4608
        </p>
        <p>{translate('calgaryConsent.t84')}</p>
        <hr />
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Parent/Guardian's Name:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_name"
              name="parent_name"
              value={parent_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Parent Email address:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_email"
              name="parent_email"
              value={parent_email}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Parent Phone Number:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_phone_number"
              name="parent_phone_number"
              value={parent_phone_number}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Please check this box if you are willing for your child to be
            contacted for future studies:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="agree_to_be_contacted"
                  id="agree_to_be_contacted"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <hr />
        <p>{translate('calgaryConsent.t85')}</p>
      </div>
    )
  }
}
