import React from 'react'
import { Translate } from 'react-localize-redux'

export class Status extends React.Component {
  render() {
    const { survey } = this.props

    return (
      <div style={{ fontSize: '15px' }}>
        {survey.instrument === 'pbq_sports_enrollment' ? (
          <span className="warning-color">
            <Translate id="status.edit" />
          </span>
        ) : survey.availability && !survey.completed && !survey.partial ? (
          <span className="primary-color">
            <Translate id="status.available" />
          </span>
        ) : survey.availability && !survey.completed && survey.partial ? (
          <span className="warning-color">
            <Translate id="status.notFinished" />
          </span>
        ) : survey.available > 0 ? (
          <span className="muted-color">
            <Translate id="status.availableIn" /> {survey.available}{' '}
            {survey.available > 1 ? (
              <Translate id="status.days" />
            ) : (
              <Translate id="status.day" />
            )}
          </span>
        ) : (
          <span className="success-color">
            <Translate id="status.complete" />
          </span>
        )}
      </div>
    )
  }
}
