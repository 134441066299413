import React from 'react'
import { Link } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
import { Translate, withLocalize } from 'react-localize-redux'

import shred from '../../images/ShredLogos/black_shred_logo.svg'
import shredWhite from '../../images/ShredLogos/white_shred.png'
import globalTranslations from '../../localize/global.json'
import './Navbar.css'

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logoHoverOver: true,
    }

    this.props.addTranslation(globalTranslations)
  }

  render() {
    const { isAuthenticated } = this.props
    const { logoHoverOver } = this.state

    return (
      <nav className="navbar navbar-expand-lg" style={{ height: '75px' }}>
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              className="logo logo-padding"
              alt="shred logo"
              src={logoHoverOver ? shred : shredWhite}
              onMouseOver={() => this.setState({ logoHoverOver: false })}
              onMouseOut={() => this.setState({ logoHoverOver: true })}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          ></button>
          <div className="flex-row-reverse" id="navebarSupportedContent">
            <ul className="navbar-nav">
              {isAuthenticated && (
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={{
                      pathname: '/profile/dashboard',
                      search: '?childTab=0&contentTab=baseline',
                    }}
                  >
                    <Translate id="navBar.myHome" />
                  </Link>
                </li>
              )}
              <li className="collapse navbar-collapse nav-item">
                <Link className="nav-link" to="/about">
                  <Translate id={'navBar.about'} />
                </Link>
              </li>
              <li className="collapse navbar-collapse nav-item">
                <Link className="nav-link" to="/contact-us">
                  <Translate id="navBar.contactUs" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

function mapStateToProps(state) {
  const { isAuthenticated } = state.session

  return {
    isAuthenticated,
  }
}

export default withLocalize(connect(mapStateToProps)(Navbar))
