import React from 'react'

export const EdmontonAssent = () => {
  return (
    <div>
      <h4>ASSENT FORM</h4>
      <h5 className="lead mt-3">TITLE:</h5>
      SHRED - Surveillance in High Schools to Reduce Injury and Concussion
      through Prevention and Management
      <h5 className="lead mt-3">INVESTIGATORS:</h5>
      <p>
        <b>Principal Investigator:</b> Dr. Carolyn Emery
      </p>
      <p>
        <b>Co-Investigators:</b>
        <br />
        Shelina Babul (University of British Columbia), Kathy Belton (University
        of Alberta), Amanda Black (University of Calgary), William Bridel
        (University of Calgary), Lara Boyd (UBC), Brian Brooks (University of
        Calgary), Tyler Cluff (University of Calgary), Chantel Debert
        (University of Calgary), Sean Dukelow (University of Calgary), Paul
        Eliason (UCalgary), Mike Ellis (University of Manitoba), Pierre Frémont
        (Université Laval), Pamela Fuselli (Parachute), Isabelle Gagnon (McGill
        University), Bradley Goodyear (University of Calgary), Claude Goulet
        (Université Laval), Brent Hagel (University of Calgary), Sarah Kenny
        (University of Calgary), Constance Lebrun (University of Alberta),
        Alison Macpherson (York University), Brad McFadden (Université Laval),
        Catherine Mercier (Université Laval), Martin Mrazik (University of
        Alberta), Luc Nadeau (Université Laval), Alberto Nettel-Aguirre
        (University of Calgary), Luz Palacios-Derflingher (University of
        Calgary), William Panenka (UBC), Kati Pasanen (University of Calgary),
        Ian Pike (University of British Columbia), Nick Reed (University of
        Toronto), Kelly Russell (University of Manitoba), Kathryn Schneider
        (University of Calgary), Ian Shrier (McGill University), Jon Smirl
        (University of British Columbia), Cheryl Wellington (University of
        British Columbia), Paul vanDonkelaar (University of British Columbia),
        Jackie Whittaker (University of Alberta), Keith Yeates (University of
        Calgary)`, Roger Zemek (University of Ottawa)
      </p>
      <p>
        <i>
          This consent form is only part of the process of informed conse nt. It
          should give you the basic idea of what the research is about and what
          your participation will involve . if you would like more detail about
          something mentioned here, or information not included here, please
          ask. Take the time to read this carefully and to understand any
          accompanying information. You may choose to download a copy for your
          records
        </i>
      </p>
      <h5 className="lead mt-3">BACKGROUND:</h5>
      <p>
        Sport and recreational activities are good for youth, but there is
        always a chance of getting injured. One of the injuries that can happen
        is a concussion. There has been research on concussion in some Canadian
        youth sports, such as hockey. This type of research has looked at why
        some youth may be more likely to get a concussion, what affects the
        amount of time it takes to recover from a concussion, and ways to
        prevent concussions in sports. There is also a need to research the same
        questions in other sport and recreational activities, and even in
        schools so that it can help lower the risk of concussion in all youth.
        This will help researchers develop strategies that can be used to
        educate teachers, coaches, parents, and students in schools about
        concussions.
      </p>
      <h5 className="lead mt-3">WHAT IS THE PURPOSE OF THIS STUDY?</h5>
      <p>
        There are six major goals of this study: (1) Collect information on
        injuries and concussions in high school students (2) Determine why some
        high school students may have a higher chance of getting a concussion
        than others (3) Look at high school students' recovery after getting a
        concussion (4) Evaluate what high school students, teachers, coaches,
        and parents know about concussions, and how they manage concussions (5)
        Create a concussion education program for high school teachers, coaches,
        and students, and evaluate if it works (6) Create injury prevention
        programs to use in a high school athletics, and evaluate if these
        programs work.
      </p>
      <h5 className="lead mt-3">WHAT WOULD I HAVE TO DO?</h5>
      <div>
        <p>
          At the beginning of the study, you will be asked to complete a survey,
          which asks you about your participation in physical activity, and
          about any past injuries. As part of the baseline assessment, the
          research team will help administer the sport concussion assessment
          tool 5 (a tool used to help recognize and manage concussion). The test
          assesses symptoms, memory, coordination, concentration and balance.
          You will then be asked to report all injuries that you get during the
          school year, even if you already received treatment for it outside of
          the study. You will also be asked to report what sport and physical
          activities you participate in during the study. If you get a
          concussion, you may be tested using the sport concussion assessment
          tool as part of your concussion management. You may be called if you
          do not complete these forms, to help us get complete data.
        </p>
        <p>
          A study therapist (Certified Athletic Therapist and/or a
          physiotherapist) will be visiting your school on a weekly basis. If
          you suffer an injury during the school year, you will be able to have
          your injury assessed by the study therapist at the school. If the
          study therapist suspects that you may have a concussion, you will be
          referred by the study therapist to a local sport medicine doctor. The
          researchers may also choose your school athletic team to evaluate an
          injury prevention program.
        </p>
      </div>
      <h5 className="lead mt-3">WILL I BENEFIT IF I PARTICIPATE?</h5>
      <p>
        There are no direct benefits to you. The information we get from this
        study will give researchers a better understanding of high school
        students' injuries. This information will help develop strategies that
        can be used to prevent sport and recreational injuries in the future.
      </p>
      <p>
        It is possible that you may learn more about concussions and injuries.
        If you get injured during the study, you will be assessed by the study
        therapist. If the study therapist suspects that you may have a
        concussion, they will refer you to a local sport medicine doctor.
      </p>
      <h5 className="lead mt-3">
        WILL I BE PAID FOR PARTICIPATING, OR DO I HAVE TO PAY FOR ANYTHING?
      </h5>
      <div>
        <p>
          You do not have to pay to participate in the study, and you will not
          be paid anything if you decide to participate.
        </p>
      </div>
      <h5 className="lead mt-3">DO I HAVE TO PARTICIPATE?</h5>
      <p>
        No, you do not have to be in the study. Participation in this study is
        voluntary and you may leave the study at any time by contacting the
        study coordinator. If you leave the study, you may also request to
        withdraw your data so long as the request is made before two weeks after
        data collection is complete in the activity you participated in. Your
        involvement with your team and/or class will not be affected if you
        choose not to consent to take part in the study. The researchers will
        tell you if there is new information available through this study
        period.
      </p>
      <p>
        You may be contacted in the future and invited to take part in other
        research studies that are similar to this study, or which look at any
        injuries they may have. Data collected during this study may be combined
        and reported with data from other studies conducted by this research
        team. We will not share your contact information with anyone outside the
        research team.
      </p>
      <h5 className="lead mt-3">WILL MY RECORDS BE KEPT PRIVATE?</h5>
      <p>
        All of the information collected throughout the study period will be
        de-identified and will remain strictly confidential. Only the
        investigators responsible for this study, the research staff, the
        statistician who will analyze the data, the University of Calgary, and
        the Conjoint Health Research Ethics Board will have access to this
        information . Confidentiality will be protected by using only study
        identification numbers in the database. Any results of the study, which
        are reported, will in no way identify you. These online surveys may ask
        for personal identifiers or information that may be used to identify
        you. However, no direct link is made between your information and your
        data.
      </p>
      <h5 className="lead mt-3">
        IF I SUFFER A RESEARCH-RELATED INJURY, WILL I BE COMPENSATED?
      </h5>
      <p>
        In the unlikely event that you suffer an injury because of participating
        in this research, the University of Calgary, or the researchers will
        provide no compensation . You still have all your legal rights . Nothing
        said here will in any way alter your right to seek damages .
      </p>
      <h5 className="lead mt-3">SIGNATURE & AUTHORIZATION:</h5>
      <p>
        Your signature (agreement) on this form tells us that you clearly
        understand the information about your participation in the research
        project and that you agree to be a participant. In no way does this
        waive your legal rights nor release the investigators, or involved
        institutions from their legal and professional responsibilities. You are
        free to leave the study at any time without jeopardizing your health
        care. If you have further questions concerning matters related to this
        research, please contact:
      </p>
      <p>
        Project Research Coordinator: SHRED@ualberta.ca <br />
        or <br />
        Dr. Carolyn Emery (Principal Investigator) 403-220-4608 (Calgary) <br />
        Dr. Martin Mrazik (Co-Investigator) 780-492-8052 (Edmonton)
      </p>
      <p>
        <small>
          The plan for this study has been reviewed by a Research Ethics Board
          at the University of Alberta. If you have any questions about your
          rights or how the research should be conducted, you can call (780)
          492-2615. This office is independent of the researchers.
        </small>
      </p>
    </div>
  )
}
