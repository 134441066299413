import React, { Component } from 'react'

export class WinnipegAssent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      child_name: '',
      child_age: '',
      child_email: '',
      child_cell_number: '',
      name_person_obtaining_assent: '',
    }
  }

  validateComplete = () => {
    const {
      child_name,
      child_age,
      child_email,
      child_cell_number,
      name_person_obtaining_assent,
    } = this.state

    let data = this.state

    if (
      child_name &&
      child_age &&
      child_email &&
      child_cell_number &&
      name_person_obtaining_assent
    ) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (
      [
        'child_name',
        'child_age',
        'child_email',
        'child_cell_number',
        'name_person_obtaining_assent',
      ].includes(name)
    ) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    }
  }

  render() {
    const {
      child_name,
      child_age,
      child_email,
      child_cell_number,
      name_person_obtaining_assent,
    } = this.state

    const { translate } = this.props

    return (
      <div>
        <h4>{translate('winnipegAssent.t1')}</h4>
        <h5 className="lead mt-3">{translate('winnipegAssent.t2')}:</h5>
        {translate('winnipegAssent.t3')}
        <p>
          <h5 className="lead mt-3">{translate('winnipegAssent.t5')}:</h5>
          Dr Kelly Russell <br />
          {translate('winnipegAssent.t6')} <br />
          kryssell@chrim.ca
        </p>
        <h5 className="lead mt-3">{translate('winnipegAssent.t7')}</h5>
        <p>{translate('winnipegAssent.t8')}</p>
        <h5 className="lead mt-3">{translate('winnipegAssent.t9')}</h5>
        <p>{translate('winnipegAssent.t10')}</p>
        <h5 className="lead mt-3">{translate('winnipegAssent.t11')}</h5>
        <div>
          {translate('winnipegAssent.t12')}
          <ol>
            <li>{translate('winnipegAssent.t13')}</li>
            <li>{translate('winnipegAssent.t14')}</li>
            <li>
              We will communicate with you and send you reminders through the
              email address or cell phone number you give us on this form.
            </li>
            <li>
              To thank you for your participation, you will receive a $20 gift
              card after you complete baseline testing. Each week that you
              report your sport participation, you will be entered into a draw
              for two tickets to a local sporting event.
            </li>
          </ol>
        </div>
        <h5 className="lead mt-3">{translate('winnipegAssent.t15')}</h5>
        <p>{translate('winnipegAssent.t16')}</p>
        <p>{translate('winnipegAssent.t17')}</p>
        <h5 className="lead mt-3">{translate('winnipegAssent.t18')}</h5>
        <p>{translate('winnipegAssent.t19')}</p>
        <h5 className="lead mt-3">{translate('winnipegAssent.t20')}</h5>
        <p>{translate('winnipegAssent.t21')}</p>
        <h5 className="lead mt-3">{translate('winnipegAssent.t22')}</h5>
        <p>{translate('winnipegAssent.t23')}</p>
        <h5 className="lead mt-3">{translate('winnipegAssent.t24')}</h5>
        <p>{translate('winnipegAssent.t25')}</p>
        <p>{translate('winnipegAssent.t26')}</p>
        <hr />
        <h6>Assent</h6>
        <br />
        <p>
          I want to take part in this study. I know I can change my mind at any
          time.
        </p>
        <br />
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Child's Name</label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_name"
              name="child_name"
              value={child_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Child's Age</label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_age"
              name="child_age"
              value={child_age}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Child's Email</label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_email"
              name="child_email"
              value={child_email}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Child's Cell Phone Number
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_cell_number"
              name="child_cell_number"
              value={child_cell_number}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <br />
        <br />
        <p>
          I confirm that I have explained the study to the participant to the
          extent compatible with the participants understanding, and that the
          participant has agreed to be in the study.
        </p>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Name of Person obtaining assent
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="name_person_obtaining_assent"
              name="name_person_obtaining_assent"
              value={name_person_obtaining_assent}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <hr />
      </div>
    )
  }
}
