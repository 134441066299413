import React from 'react'

import { Mail } from 'react-feather'
import { Phone } from 'react-feather'
import { Translate } from 'react-localize-redux'

// NOTE: CONTACT US header is split in 2 tags.
// Translation will not always be one to one in french. Not sure how to go about it

export const ContactUs = () => (
  <div className="container mt-4 mb-4">
    <div className="row">
      <div className="col-lg-12">
        <h2 className="heading mb-5">
          <Translate id="contactUs.headerContact" />{' '}
          <span className="text-primary">
            <Translate id="contactUs.headerUs" />
          </span>
        </h2>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <h5 className="mb-4">
          <Translate id="contactUs.howToContact" />:
        </h5>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <address className="bg-light p-3">
          <Translate id="contactUs.studyCoordinator" />: Shane Esau, M.Kin.,
          CSEP-CEP
          <br />
          KNB 3300
          <br />
          <Translate id="contactUs.faculty" />
          <br />
          2500 University Dr. NW
          <br />
          Calgary, Alberta, T2N 1N4
          <br />
          <br />
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Mail className="mr-2" />
            SHRedConcussions@ucalgary.ca
            <br />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Phone className="mr-2" />
            T: 403-220-3113
          </div>
        </address>
      </div>
      <div className="col-lg-6">
        <address className="bg-light p-3">
          <Translate id="contactUs.principalInvestigator" />: Dr. Carolyn Emery
          <br />
          <Translate id="contactUs.faculty" />
          <br />
          2500 University Drive NW
          <br />
          Calgary, AB T2N 1N4
          <br />
          <br />
          <br />
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Mail className="mr-2" />
            caemery@ucalgary.ca
            <br />
          </div>
        </address>
      </div>
    </div>
  </div>
)
