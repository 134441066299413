import { handleAxiosError } from '../helpers'
import { CancelToken } from 'axios'

import { instance } from '../helpers'

export const siteServices = {
  get,
}

function get(cancel) {
  return instance
    .get('/sites', { cancelToken: new CancelToken(c => (cancel.exec = c)) })
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
