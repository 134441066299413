import React from 'react'
import { push } from 'react-router-redux'
import qs from 'qs'

import { withLocalize, Translate } from 'react-localize-redux'

import { Surveys } from '../../Surveys/index'
import { alertActions } from '../../../actions/index'
import { reminderServices } from '../../../services'
import { store } from '../../../helpers'
import { Section } from '../SectionHeading/Section'
import './Parent.css'
import ChildInfo from './ChildInfo'
import { ChildActions } from './ChildActions'
import globalTranslations from '../../../localize/global'

class Athlete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeContentTab: 'baseline',
    }
    this.handleReminder = this.handleReminder.bind(this)
    this.handleNewConcussionReminder = this.handleNewConcussionReminder.bind(
      this
    )
    this.props.addTranslation(globalTranslations)
  }

  componentDidMount() {
    const { location } = this.props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.contentTab) {
        this.setState({
          activeContentTab: search.contentTab,
        })
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.contentTab) {
        state.activeContentTab = search.contentTab
      }
    }

    return state
  }

  handleReminder() {
    const { email } = this.props.record
    const { dispatch } = store

    if (email) {
      reminderServices.post({ email, type: 'reminder' }).then(
        response => {
          dispatch(
            alertActions.success(
              this.props.translate('athlete.reminderSentTo') + email
            )
          )
        },
        error => {
          if (error.response) {
            const errors = error.response.data.errors
            if (errors) {
              errors.forEach(e => {
                dispatch(alertActions.error(e))
              })
            }
          }
        }
      )
    }
  }

  handleNewConcussionReminder() {
    const { email } = this.props.record
    const { dispatch } = store

    if (email) {
      reminderServices.post({ email, type: 'new_injury' }).then(
        response => {
          dispatch(
            alertActions.success(
              this.props.translate('athlete.newConcussionReminderSent') + email
            )
          )
        },
        error => {
          if (error.response) {
            const errors = error.response.data.errors
            if (errors) {
              errors.forEach(e => {
                dispatch(alertActions.error(e))
              })
            }
          }
        }
      )
    }
  }

  handleTabChange = tab => {
    const { dispatch } = store
    const { location } = this.props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.childTab) {
        dispatch(
          push({
            pathname: '/profile/dashboard',
            search: '?childTab=' + search.childTab + '&contentTab=' + tab,
          })
        )

        this.setState({
          activeContentTab: tab,
        })
      }
    }
  }

  render() {
    const { activeChildTab, record, handleChildEdit, role } = this.props
    const { activeContentTab } = this.state
    const surveyTypes = record ? record.surveys[activeContentTab] : null
    const childName = record ? record.first_name : null

    const displayName = this.props.activeLanguage.code === 'en' ? childName : ''

    // Hard coded logic for translation. Not able to use translation placeholders
    const infoHeading =
      this.props.activeLanguage.code === 'en'
        ? childName
          ? childName + "'s Information"
          : 'Child' + (+activeChildTab + 1) + "'s Information"
        : childName
        ? "L'information de " + childName
        : "L'information sur l'enfant " + activeContentTab

    return (
      <div>
        <div className="row parent-survey-tabs mb-2">
          <ul
            className="col-lg-9 col-sm-8 col-6 nav nav-tabs"
            style={{ marginLeft: '14px' }}
          >
            <li className="nav-item">
              <span
                key="baseline"
                className={
                  activeContentTab === 'baseline'
                    ? 'nav-link active'
                    : 'nav-link'
                }
                onClick={() => this.handleTabChange('baseline')}
              >
                <Translate id={'athlete.baselineTab'} />
              </span>
            </li>
            <li className="nav-item">
              <span
                key="concussion"
                className={
                  activeContentTab === 'concussion'
                    ? 'nav-link active'
                    : 'nav-link'
                }
                onClick={() => this.handleTabChange('concussion')}
              >
                <Translate id={'athlete.concussionTab'} />
              </span>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            {surveyTypes && surveyTypes.length ? (
              surveyTypes.map((surveyType, index) => {
                return (
                  <div key={index}>
                    <Surveys
                      key={index}
                      surveyType={surveyType.type_name}
                      surveys={surveyType.surveys}
                      record={record}
                      surveyPath={activeContentTab}
                      role={role}
                      {...this.props}
                    />
                  </div>
                )
              })
            ) : (
              <p className="mt-5">
                {activeContentTab === 'concussion' ? (
                  <span>
                    {childName +
                      this.props.translate('athlete.noCurrentConcussion')}
                    <br />
                    {this.props.translate('athlete.if') +
                      childName +
                      this.props.translate('athlete.hasObtainedNewConcussion') +
                      displayName +
                      this.props.translate('athlete.reportIt')}
                  </span>
                ) : (
                  this.props.translate('athlete.noSurveysAvailable')
                )}
              </p>
            )}
          </div>
          <div className="col-lg-6 col-sm-12 mb-5">
            <Section
              translate={this.props.translate}
              heading={infoHeading}
              role={role}
            />
            <div className="col-lg-12 mb-4">
              <ChildActions
                handleChildEdit={handleChildEdit}
                handleReminder={this.handleReminder}
                handleNewConcussionReminder={this.handleNewConcussionReminder}
              />
            </div>
            <div className="col-lg-12">
              <ChildInfo
                child={record && record.child ? record.child : record}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withLocalize(Athlete)
