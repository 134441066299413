import React from 'react'

import header from '../../images/Consent/Vancouver/header.png'
import table_1 from '../../images/Consent/Vancouver/table_1.png'

export class KelownaAssent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      agree_to_be_contacted: null,
    }
  }

  validateComplete = () => {
    const { agree_to_be_contacted } = this.state

    let data = this.state

    if (agree_to_be_contacted) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (['agree_to_be_contacted'].includes(name)) {
      this.setState(
        {
          [name]: value === 'true',
        },
        this.validateComplete
      )
    }
  }

  render() {
    return (
      <div>
        <img
          src={header}
          alt="header"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
          }}
        />
        <div style={{ textAlign: 'center' }}>
          BC Children’s Hospital Research Institute | The University of British
          Columbia
          <br />
          F508 4480 Oak Street | Vancouver BC | V6H 3V4 Canada
          <br />
          Phone +1 (604) 875-3776 | Fax +1 (604) 875-3569
          <br />
          bcinjury1@cw.bc.ca | www.injuryresearch.bc.ca
        </div>
        <h5 className="lead mt-3">Adolescent Information and Assent Form</h5>
        <h5 className="lead mt-3">Title:</h5>
        <b>
          Surveillance in High Schools to Reduce Concussions and Consequences of
          Concussions in Canadian Youth – SHRed Concussions
        </b>
        <h5 className="lead mt-3">Short Title: </h5>
        <b>SHRed Concussions</b>
        <h5 className="lead mt-3">
          <u>Who is in charge of this study?</u>
        </h5>
        <p>
          The investigator in charge of this study is Dr. Carolyn Emery, at the
          University of Calgary, where all information from the study will be
          electronically stored on a secure server. In BC, the investigators in
          charge of the study are Dr. Ian Pike and Dr. Shelina Babul. They are
          being helped by Dr. Cheryl Wellington, Dr. Paul van Donkelaar, Dr.
          Jackie Whittaker, and Dr. Bruce Bjornson. Information that identifies
          me will be stored in a locked cabinet, in a locked office, at
          UBCO/BrainTrust. I can learn more about how my information will be
          collected and stored in the ‘confidentiality’ section of this form.
          The researchers will answer any questions I have about the study. I
          can call the Study Coordinator, Sierra Sanchez, by phone (250)
          863-0255 or email (sierra.sanchez@ubc.ca) if I have questions or need
          help
        </p>
        <h5 className="lead mt-3">
          <u>Invitation</u>
        </h5>
        <p>
          I am being invited to participate in this study because I am a high
          school student who plays at least one sport that carries a higher risk
          of concussion injury. The following pages explain the study so that I
          can decide if I want to take part or not. It is up to me if I want to
          be in this study. No one will make me be part of the study and no one
          will get mad at me if I don’t want to be part of this study.
        </p>
        <h5 className="lead mt-3">
          <u>Do I have to be in this study?</u>
        </h5>
        <p>
          I do not have to participate in this study if I don’t want to. If I
          choose to participate, I can stop being in the study at any time. My
          doctors will take care of me as they have in the past, regardless of
          whether I am in the study or not.
        </p>
        <p>
          If I want to be in the study, I will be asked to sign this form. My
          parent/guardian will need to sign a consent form before I join the
          study, but I do not have to participate even if they sign the consent
          form. The researchers will not enroll me into the study unless I
          agree.
        </p>
        <p>
          I should take time to read the following information carefully and to
          talk it over with my family, and if I wish, with my doctor, before I
          decide. I understand that I should feel free to talk to the study
          doctors if anything is not clear. I can choose to be in the study, not
          be in the study, or take more time to decide. Even if I agree now to
          be part of the study, I can change my mind later. I can ask the study
          doctor or study coordinator any questions I may have at any time
          during my study participation.
        </p>
        <h5 className="lead mt-3">
          <u>Why are we doing this study?</u>
        </h5>
        <p>
          Concussion is a form of mild traumatic brain injury (TBI). Among
          youth, concussions often happen during sport participation. Past
          studies of concussion among youth athletes are limited and therefore
          it is still not well understood why some youth recover quickly and
          others continue to have symptoms for a long time. Symptoms may include
          headaches, dizziness, foggy thinking, sleep problems, and emotional
          distress. These symptoms can make it difficult for youth to return to
          school and sports. We need better information about sport-related
          concussion in youth to improve the ways we recognize, manage and
          prevent concussions.
        </p>
        <h5 className="lead mt-3">
          <u>Why are you inviting me to be in this study?</u>
        </h5>
        <p>
          I am being invited to be in the study because I am between the ages of
          13 and 16, I attend a public or Catholic or private high school in
          British Columbia, and play at least one of the following sports: :
          basketball, football, ice hockey, ringette, lacrosse, rugby, soccer,
          volleyball, cheerleading, alpine skiing, sledge hockey, or wrestling.
        </p>
        <p>
          The purpose of this study is to establish a national picture of the
          burden of sport-related concussion among youth in Canada, and to
          improve the ways in which concussions are prevented, recognized,
          managed and treated. This study will involve 6,000 high school
          athletes from across Canada (1,200 from BC) who will be in the study
          for 3 years.
        </p>
        <h5 className="lead mt-3">
          <u>What will happen to me in this study?</u>
        </h5>
        <p>
          If I decide to be in the study, the activities that I will be asked to
          do depend on whether or not I get a concussion during the school year.
        </p>
        <p>
          Once I am in the study, I will be given a website link to an online
          site called REDCap. This site (also called a ‘portal’) will let my
          parent make an account, and invite me to make one, so I can fill out
          information about myself, my injury/medical history, and my sport
          participation. Moving forward, this portal will be where I report
          injuries to the SHRed concussion team.
        </p>
        <p>
          I will be asked to complete a baseline testing session at the start of
          each school year for 3 years. This baseline testing session will take
          place at UBCO, BrainTrust Canada, or at participating school/club
          facilities in two separate visits. The first will be at BrainTrust or
          at participating school/club facilities for 2.5 hours and the second
          will be at UBCO for 1.5 hours. The researchers will test my fitness,
          balance, coordination, reaction times and ask me questions about how I
          feel about school, what sports I play and if I’ve had any
          sport-related injuries in the past. I will also be asked if I want to
          do an optional blood test at the baseline testing session. If I choose
          to do the blood test, a nurse will use a needle to take blood (about 2
          teaspoons) from my arm for some tests to measure the level of certain
          proteins in my blood.
        </p>
        <p>
          After the baseline has been done, I will log into an online portal
          once each week to report how much time I spent playing sports that
          week and if I got injured at all. This will take about 5 minutes of my
          time and I can do this at home. If I forget to log in and fill out the
          form, I will get a call, text or email from the study coordinator to
          remind me to fill it out. My parent/guardian will also fill out two
          short questionnaires about me.
        </p>
        <p>
          As a study participant, I will also be able to see an athletic
          therapist or physiotherapist at my school if I get any injuries during
          the school year. They will help me with my injuries. If I get an
          injury that is not a concussion while I am in the study, I should see
          my family doctor for help.
        </p>
        <p>
          Optional blood draws: Blood collection is an optional portion of this
          study meaning I do not have to participate if I do not want to. Blood
          samples will be drawn by a trained phlebotomist or nurse, who can help
          answer any questions, concerns or anxiety I may have about the blood
          test. If I am uncomfortable or anxious about the blood test, or if I
          want to stop for any reason, I can ask the phlebotomist/nurse to stop.
          A maximum of two attempts to draw blood will be made at each session.
        </p>
        <p>
          Blood will be processed within 4 hours of collection into serum and
          plasma. DNA and genetic material will be removed from the sample,
          meaning only the serum and plasma will be used for analysis. The
          serum/plasm samples will be stored in a biobank (a lab space for
          storage of blood and/or it’s components) for analysis. If I consent to
          blood draw, my samples will be stored for up to 7 years following the
          study.
        </p>
        <p>
          Because research on concussions and information from blood is
          developing, and new types of blood tests will be available later,
          SHRed researchers will be asking my permission to store serum/plasma
          specimens in a biobank for as long as they can be used for research.
          If I am interested in allowing my samples to be stored in the biobank,
          I will sign a separate form.
        </p>
        <p>
          <u>If I get a concussion while I’m in the study</u>
          <br />
          <b>
            If I experience any of the following red flag symptoms I should call
            911 immediately:
          </b>
        </p>
        <ul>
          <li>neck pain or tenderness</li>
          <li>double vision</li>
          <li>weakness or tingling/burning in your arms or legs</li>
          <li>severe or increasing headache</li>
          <li>seizure or convulsion</li>
          <li>loss of consciousness</li>
          <li>deteriorating conscious state</li>
          <li>vomiting</li>
          <li>increasingly restless, agitated or combative</li>
        </ul>
        <p>
          These are signs that I need <b>immediate</b> medical attention at the
          nearest Emergency Department. After I get out of the hospital I can
          report my concussion and book my first study visit by calling the
          Study Coordinator.
        </p>
        <p>
          If I get a concussion, I will come to BrainTrust to see the study
          doctor – who is a sports concussion specialist— at the earliest
          possible time after my injury. If my parent and I would like to see my
          family doctor for concussion care, I can come to see the study doctor
          for research appointments only. The study doctors will be giving me
          medical treatment for my concussion and helping collect research data.
          My medical care will <b>always</b> s be their first priority. The
          study doctor will inform me about which assessments are being
          conducted as part of usual clinical concussion care, and which are
          being done for research purposes.
        </p>
        <p>
          If I get a concussion, I will come to BrainTrust to see the study
          doctor as soon as possible after my injury. I will see this doctor 3
          times following my injury: (1) within approximately 72 hours of
          getting a concussion; (b) 1-2 weeks after getting a concussion; and
          (3) and 30 days after getting a concussion. The first and third visit
          will each take 3 hours and the second visit will take one hour. If my
          recovery takes longer than 30 days, I will come back to see the doctor
          every 2 weeks until they think I can return to my activities.
        </p>
        <p>
          If my parent and I choose, the study doctor will take care of all of
          my medical care for my concussion. I will go to my family doctor for
          anything else that is not related to my concussion.
        </p>
        <p>
          Some of the tests I did at the baseline testing session will be
          repeated, and I will be asked if I want to do a scan of my brain. My
          parent/guardian will be asked to fill out the same questionnaires that
          they did at the baseline session at each of the three follow up
          visits. I will continue to report my sport participation and any new
          sport-related injuries online every week. If at any time during the
          testing I feel unwell, or my symptoms increase, the tests will be
          immediately stopped. I can refuse to do any tests I don’t want to do
          and still be part of the study.
        </p>
        <p>
          This table shows the tests I will be asked to do if I decide to be
          part of the study:
        </p>
        <img
          src={table_1}
          alt="table one"
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
        />
        <small>
          <i>
            *I will only be seen every two weeks if I am still not feeling well
            30 days after my concussion
          </i>
          <br />
          <i>
            **A certified athletic therapist or physiotherapist will come to the
            school once a week to assess any reported injuries in person
          </i>
        </small>
        <h5 className="lead mt-3">
          <u>Sub-Studies</u>
        </h5>
        <p>
          There are additional sub-studies I can participate in if I want to.
          For each optional study, I will be provided with an assent form that
          describes the details. I will be asked to sign those forms if I want
          to participate. I can take part in the main study and not take part in
          these optional studies. If I decide not to take part in any or all of
          the optional studies, my participation in this study and my medical
          care will not be affected.
        </p>
        <h5 className="lead mt-3">
          <u>Can anything bad happen?</u>
        </h5>
        <p>
          As with any physical activity, there is the possibility of a muscle
          strain for tests, such as running. Some testing may result in
          dizziness or muscle fatigue for a short time following the tests. If I
          feel unwell, or if I wish to stop the testing for any reason, I should
          let the study staff know right away and the tests will be stopped.
        </p>
        <p>
          Although very rare, there is a possibility of local infection within
          days of having blood taken. There is a possibility of a slight bruise
          at the needle site. There is also a remote possibility of fainting. If
          you dizzy or faint, tell the nurse or phlebotomist right away.
        </p>
        <p>
          Transcranial Doppler sonography is a type of ultrasound imaging that
          produces pictures of the brain and blood flow through the brain. It
          does not hurt at all and there are no associated risks. The technician
          will place a small amount of water-soluble gel on certain portions of
          my face and head to enhance the image, and this gel will wipe off
          easily after the scan is finished.
        </p>
        <h5 className="lead mt-3">
          <u>What are the potential benefits of participating?</u>
        </h5>
        <p>
          No one knows whether or not I will benefit from this study. The study
          doctors hope that the information learned from this study can be used
          in the future to benefit other people who have concussions.
        </p>
        <h5 className="lead mt-3">
          <u>Who will know I am in the study?</u>
        </h5>
        <p>
          The study team will not release any information to anybody else that
          could be used to identify me, unless they are required to do so by
          law. For example, researchers are required to report if a participant
          is believed to be at risk for harming him/herself or others. As part
          of the study I will fill out some questionnaires that will ask me
          about how I am feeling. If the study doctors are worried about me they
          will contact my family doctor if I have given them permission to do so
          and they will contact my parents to make sure that I get any
          additional care that is needed.
        </p>
        <p>
          I will be identified by specific study codes that apply only to me.
          Any paper forms that are part of the study will use this code rather
          than my name. Dr. Paul van Donkelaar will keep the file linking your
          identifying information to this code at the local study site (UBC
          Okanagan), in a locked file cabinet. Computer files are password
          protected and stored in a secure server at UBC Okanagan, and only
          accessible to the research team at this location.
        </p>
        <p>
          For this study, the researchers will be collecting my full name, age,
          month and year of birth, phone number, email, city, and school name
          for the research described in this form. Although steps have been
          taken to protect my identity, this could increase the risk of other
          researchers finding out I am. My information will only be available to
          SHRed researchers at UBCO and the University of Calgary. Researchers
          outside of the local site would not normally have access to my
          personal information; this is unique to this study. The team at
          University of Calgary has signed a confidentiality form, and
          researchers at UBCO have done privacy training. My personal
          information will not be used to contact me about joining future
          research studies without your consent, and all future contact will be
          made directly by the BC research team. All of my identifying
          information will be deleted from the Calgary-based portal by the
          earliest possible date, which is August 31, 2024. The researchers at
          UBCO will keep the file linking my ID to my identifying information in
          a locked cabinet, in a locked office, for 5 years after the study is
          completed.
        </p>
        <h5 className="lead mt-3">
          <u>What will the study cost me?</u>
        </h5>
        <p>
          There will be no financial costs to me as a participant in this study.
          I will be reimbursed for parking or transit when I attend study
          visits. Juice and snacks will be offered to me at the time of each
          blood test. I will not be paid for participating.
        </p>
        <h5 className="lead mt-3">
          <u>
            Who do I contact if I have questions about the study during my
            participation or to report an injury?
          </u>
        </h5>
        <p>
          UBC Main Investigators: Dr. Ian Pike (604) 875-3425; Dr. Shelina Babul
          (604) 875-3682
          <br />
          UBCO Investigator: Dr. Paul van Donkelaar (250) 807-8858
          <br />
          Study Coordinator: Sierra Sanchez (250) 863-0255
        </p>
        <h5 className="lead mt-3">
          <u>
            Who do I contact if I have questions or concerns about my rights as
            a research participant?
          </u>
        </h5>
        <p>
          If I have any concerns or complaints about my rights as a research
          participant and/or my experiences while participating in this study, I
          should contact the Research Participant Complaint Line in the
          University of British Columbia Office of Research Ethics by e-mail at
          RSIL@ors.ubc.ca or by phone at 604-822-8598 (Toll Free:
          1-877-822-8598). I should reference the study number (H19-00037) when
          contacting the Complaint Line so the staff can better assist me.
        </p>
        <hr />
        <p>My signature on this assent form means:</p>
        <ul>
          <li>
            I have read and understood this adolescent information and assent
            form.
          </li>
          <li>
            I have had enough time to consider the information provided and to
            ask for advice if necessary.
          </li>
          <li>
            I have had the opportunity to ask questions and have had acceptable
            answers to my questions.
          </li>
          <li>
            I understand that all of the information collected will be kept
            confidential and that the results will only be used for scientific
            objectives.
          </li>
          <li>
            I understand that my participation in this study is voluntary and
            that I am completely free to refuse to participate or to withdraw
            from this study at any time without changing the quality of care
            that I receive.
          </li>
          <li>
            I understand that I can continue to ask questions, at any time,
            regarding my participation in the study.
          </li>
          <li>
            I understand that if I put my name at the end of this form, it means
            that I agree to be in this study.
          </li>
        </ul>
        <p>
          Are you willing to be contacted by the BC Children’s Hospital
          researchers for future studies?
        </p>
        <div className="form-check" style={{ marginTop: '1rem' }}>
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              name="agree_to_be_contacted"
              id="agree_to_be_contacted_yes"
              value={true}
              onClick={this.handleChange}
            />
            Yes
          </label>
        </div>
        <div className="form-check" style={{ marginBottom: '1rem' }}>
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              name="agree_to_be_contacted"
              id="agree_to_be_contacted_no"
              value={false}
              onClick={this.handleChange}
            />
            No
          </label>
        </div>
        <hr />
      </div>
    )
  }
}
