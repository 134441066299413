import { push } from "react-router-redux";

import { sessionConstants } from "../constants";
import { alertActions, analyticsActions } from "./index";
import { sessionServices } from "../services";

export const sessionActions = {
  create,
  destroy
};

function create(params, setActiveLanguage) {
  return dispatch => {
    dispatch(request());
    sessionServices.create(params).then(
      response => {
        const token = response.data.token;
        sessionStorage.setItem("token", token);
        dispatch(success(token));
        dispatch(
          push({
            pathname: "/profile/dashboard",
            search: "?childTab=0&contentTab=baseline"
          })
        );

        if (
          response.data.language &&
          setActiveLanguage &&
          ["en", "fr"].includes(response.data.language)
        ) {
          setActiveLanguage(response.data.language);

          const cookieValue = response.data.language === "en" ? "en" : "fr";

          document.cookie =
            "redcap-multilanguage-survey=" +
            cookieValue +
            "; path=/; domain=.shredconcussions.ca";
        }
        dispatch(analyticsActions.loginEvent());
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors;
          if (errors) {
            dispatch(failure(errors));
            errors.forEach(e => {
              dispatch(alertActions.error(e));
            });
          }
        }
        sessionStorage.removeItem("token");
      }
    );
  };

  function request() {
    return { type: sessionConstants.LOGIN_REQUEST };
  }

  function success(token) {
    return { type: sessionConstants.LOGIN_SUCCESS, token };
  }

  function failure(errors) {
    return { type: sessionConstants.LOGIN_FAILURE, errors };
  }
}

function destroy() {
  return dispatch => {
    dispatch(request());
    sessionServices.destroy().then(
      response => {
        dispatch(success());
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("records");
        dispatch(push("/"));
      },
      error => {
        dispatch(success());
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("records");
        dispatch(push("/"));
      }
    );
  };

  function request() {
    return { type: sessionConstants.LOGOUT_REQUEST };
  }

  function success() {
    return { type: sessionConstants.LOGOUT_SUCCESS };
  }
}
