import React from "react";

import { withLocalize } from "react-localize-redux";

import SurveyModal from "../Modals/SurveyModal";
import { Status } from "./Status";
import { StatusIcon } from "./StatusIcon";
import { store } from "../../helpers/index";
import { mobileSurveys } from "../../constants";
import { recordsActions, alertActions } from "../../actions";
import { surveyServices } from "../../services/survey.services";
import { surveyLinkServices } from "../../services/surveyLink.services";
import CBSModal from "../Modals/CBSModal";
import WEModal from "../Modals/WeeklyExposure/WEModal";
import PBQModal from "../Modals/PBQ/PBQModal";

class SurveyItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSurveyModal: false,
      showWEModal: false,
      showCBSModal: false,
      showPBQModal: false,
      surveyLink: null
    };

    this.handleAction = this.handleAction.bind(this);
  }

  closeModal = () => {
    this.setState({
      showSurveyModal: false,
      showCBSModal: false,
      showWEModal: false,
      showPBQModal: false
    });
  };

  handleAction() {
    const {
      record,
      survey,
      surveyType,
      surveyPath,
      handleInjuryChange
    } = this.props;
    const { dispatch } = store;
    const baseline_portal_id = record.baseline_portal_id;
    const concussion_portal_id = record.concussion_portal_id;

    let records = JSON.parse(sessionStorage.getItem("records"));
    const index = records.findIndex(
      i => i.baseline_portal_id === baseline_portal_id
    );

    if (index >= 0) {
      const j = records[index].surveys[surveyPath].findIndex(
        i => i.type_name === surveyType
      );

      if (j >= 0) {
        const m = records[index].surveys[surveyPath][j].surveys.findIndex(
          i => i.name === survey.name
        );

        if (m >= 0) {
          const portal_id =
            survey.group === "baseline"
              ? baseline_portal_id
              : concussion_portal_id;
          const params = { survey: survey, portal_id: portal_id };
          surveyServices.update(params).then(
            response => {
              records[index].surveys[surveyPath][j].surveys[m] =
                response.data.survey;
              records[index].sports = response.data.sports;

              dispatch(recordsActions.refresh(records));
            },
            error => {
              if (error.response) {
                const errors = error.response.data.errors;
                if (errors) {
                  errors.forEach(e => {
                    dispatch(alertActions.error(e));
                  });
                }
              }
            }
          );
        }
        if (survey.instrument === "ostrc" && handleInjuryChange)
          handleInjuryChange();
      }
    }
    this.closeModal();
  }

  handleSurveyClick = () => {
    const { survey } = this.props;

    if (survey.availability && !survey.completed) {
      if (
        survey.name ===
        this.props.translate("surveyItems.cambridgeBrainSciences")
      ) {
        this.setState({
          showCBSModal: true
        });
      } else if (
        survey.name === this.props.translate("surveyItems.weeklyExposure")
      ) {
        this.setState({
          showWEModal: true
        });
      } else if (
        survey.name === this.props.translate("surveyItems.pbqSportsEnrollment")
      ) {
        this.setState({
          showPBQModal: true
        });
      } else {
        this.handleRedcapSurveyOpen();
      }
    }
  };

  handleRedcapSurveyOpen = () => {
    const { survey, surveyPath } = this.props;
    this.setState({ surveyLink: null });
    console.log(surveyPath);
    if (survey.availability && !survey.completed) {
      this.setState({ showSurveyModal: true });

      const params = `record_id=${
        survey["link_payload"]["record"]
      }&instrument=${survey["link_payload"]["instrument"]}&event=${
        survey["link_payload"]["event"]
      }&repeat_instance=${survey["link_payload"]["repeat_instance"]}`;

      surveyLinkServices.get(params).then(response => {
        this.setState({ surveyLink: response.data["link"] });
      });
    }
  };

  render() {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
    const {
      showSurveyModal,
      showCBSModal,
      showWEModal,
      showPBQModal,
      surveyLink
    } = this.state;
    const {
      survey,
      id,
      record,
      surveyPath,
      surveyType,
      handleInjuryChange,
      currentSports
    } = this.props;

    return (
      <div className="col-lg-10">
        <SurveyModal
          showModal={showSurveyModal}
          baseline_portal_id={record.baseline_portal_id}
          concussion_portal_id={record.concussion_portal_id}
          link={surveyLink}
          survey={survey}
          surveyType={surveyType}
          surveyPath={surveyPath}
          handleInjuryChange={handleInjuryChange}
          handleAction={this.handleAction}
          onClose={this.closeModal}
        />
        <CBSModal
          showModal={showCBSModal}
          baseline_portal_id={record.baseline_portal_id}
          concussion_portal_id={record.concussion_portal_id}
          survey={survey}
          surveyType={surveyType}
          surveyPath={surveyPath}
          handleAction={this.handleAction}
          onClose={this.closeModal}
        />
        <WEModal
          showModal={showWEModal}
          baseline_portal_id={record.baseline_portal_id}
          currentSports={currentSports}
          handleAction={this.handleAction}
          onClose={this.closeModal}
        />
        <PBQModal
          showModal={showPBQModal}
          baseline_portal_id={record.baseline_portal_id}
          currentSports={currentSports}
          handleAction={this.handleAction}
          onClose={this.closeModal}
        />
        <div
          className="card"
          style={{ width: "100%", borderRadius: 0, cursor: "pointer" }}
          id={id}
          onClick={this.handleSurveyClick}
        >
          <div className="card-body" style={{ padding: "0.6rem" }} id={id}>
            <div className="row">
              <div
                style={{
                  marginLeft: "1.3rem",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <StatusIcon survey={survey} />
                <div style={{ marginLeft: "1.3rem" }}>
                  <h5 style={{ margin: 0 }} id={id}>
                    {isMobile && mobileSurveys[survey.name]
                      ? mobileSurveys[survey.name]
                      : survey.name}
                  </h5>
                  <Status survey={survey} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(SurveyItem);
