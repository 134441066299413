import React from "react";
import { Translate } from "react-localize-redux";
import PhoneInput from "react-phone-number-input";

import { store } from "../../../helpers/index";
import ConsentFormModal from "../../Modals/ConsentFormModal";
import { userServices } from "../../../services";
import { alertActions, userActions } from "../../../actions";

let cancel = { exec: null };

export class Personal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      phone_number: "",
      notify: "",
      frequency: "",
      city: "",
      showConsentModal: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleConsentModal = this.toggleConsentModal.bind(this);
  }

  componentDidMount() {
    const { dispatch } = store;

    userServices.get(cancel).then(
      response => {
        const data = response.data.data;

        this.setState({
          first_name: data.first_name ? data.first_name : "",
          last_name: data.last_name ? data.last_name : "",
          phone_number: data.phone_number ? data.phone_number : "",
          notify: data.notify ? parseInt(data.notify.value, 10) : "",
          frequency: data.frequency ? parseInt(data.frequency.value, 10) : "",
          role: data.role ? data.role : "",
          signature: data.signature ? data.signature : "",
          created: data.created ? data.created : "",
          city: data.city ? data.city : ""
        });
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors;
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e));
            });
          }
        }
      }
    );
  }

  componentWillUnmount() {
    if (cancel.exec) cancel.exec();
  }

  handleChange(event, type = null) {
    let name = "";
    let value = "";

    if (type === "phone_number") {
      name = "phone_number";
      value = event;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      first_name,
      last_name,
      phone_number,
      frequency,
      notify
    } = this.state;
    const { dispatch } = store;

    dispatch(
      userActions.update(
        {
          first_name,
          last_name,
          phone_number,
          notify,
          frequency
        },
        this.props.translate
      )
    );
  }

  toggleConsentModal() {
    const { showConsentModal } = this.state;

    this.setState({
      showConsentModal: !showConsentModal
    });
  }

  render() {
    const {
      first_name,
      last_name,
      phone_number,
      notify,
      frequency,
      showConsentModal,
      signature,
      role,
      created,
      city
    } = this.state;

    return (
      <div className="mt-5 mb-5">
        <ConsentFormModal
          showModal={showConsentModal}
          signatureAllowed={false}
          signature={signature}
          created={created}
          city={city}
          onClose={this.toggleConsentModal}
          role={role}
        />
        {city && (
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <span className="btn-custom" onClick={this.toggleConsentModal}>
                {role === "parent"
                  ? this.props.translate(
                      "profile.settings.personal.reviewAndPrintConsent"
                    ) +
                    " " +
                    this.props.translate("profile.settings.personal.consentTag")
                  : this.props.translate(
                      "profile.settings.personal.reviewAndPrintAssent"
                    ) +
                    " " +
                    this.props.translate(
                      "profile.settings.personal.assentTag"
                    )}{" "}
                <Translate id="profile.settings.personal.formTag" />
              </span>
            </label>
          </div>
        )}
        <h5>
          <Translate id="profile.settings.personal.header" />
        </h5>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <Translate id="profile.settings.personal.form.label.firstName" />
            </label>
            <div className="col-lg-3 mb-2">
              <input
                type="text"
                className="form-control"
                name="first_name"
                value={first_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <Translate id="profile.settings.personal.form.label.lastName" />
            </label>
            <div className="col-lg-3 mb-2">
              <input
                type="text"
                className="form-control"
                name="last_name"
                value={last_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <Translate id="profile.settings.personal.form.label.phoneNumber" />
            </label>
            <div className="col-lg-3 mb-2">
              <PhoneInput
                className="form-control"
                name="phone_number"
                defaultCountry="CA"
                value={phone_number}
                onChange={value => this.handleChange(value, "phone_number")}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <Translate id="profile.settings.personal.form.label.notify" />
            </label>
            <div className="col-lg-3 mb-2">
              <select
                id="notify"
                className="form-control"
                name="notify"
                value={notify}
                onChange={this.handleChange}
              >
                <option value={1}>
                  {this.props.translate(
                    "profile.settings.personal.form.options.email"
                  )}
                </option>
                <option value={2}>
                  {this.props.translate(
                    "profile.settings.personal.form.options.text"
                  )}
                </option>
                <option value={0}>
                  {this.props.translate(
                    "profile.settings.personal.form.options.both"
                  )}
                </option>
                <option value={3}>
                  {this.props.translate(
                    "profile.settings.personal.form.options.never"
                  )}
                </option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <Translate id="profile.settings.personal.form.label.reminder" />
            </label>
            <div className="col-lg-3 mb-2">
              <select
                id="frequency"
                className="form-control"
                name="frequency"
                value={frequency}
                onChange={this.handleChange}
              >
                <option value={1}>
                  {this.props.translate(
                    "profile.settings.personal.form.options.weekly"
                  )}
                </option>
                <option value={0}>
                  {this.props.translate(
                    "profile.settings.personal.form.options.never"
                  )}
                </option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary mt-2">
              <Translate id="profile.settings.personal.form.label.submit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}
