import { recordConstants } from '../constants'

const initialState = {}

export function record(state = initialState, action) {
  switch (action.type) {
    case recordConstants.RECORD_REQUEST:
      let storedRecords = sessionStorage.getItem('records')
      let records = ''
      if (storedRecords) records = JSON.parse(storedRecords)

      return {
        loading: !records,
        error: false,
        records: records
      }
    case recordConstants.RECORD_SUCCESS:
    case recordConstants.RECORD_REFRESH:
      return {
        loading: false,
        error: false,
        records: action.records
      }
    case recordConstants.RECORD_FAILURE:
      return {
        loading: false,
        error: true
      }
    default:
      return state
  }
}
