import React from 'react'
import DatePicker from 'react-datepicker'

export class MontrealAssent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      receive_copy_of_results: null,
      agree_to_be_contacted: null,
      name_of_participant: '',
      name_of_guardian: '',
      assent_of_minor: '',
      participant_dob: null,
      phone_number: '',
      email_address: '',
      name_person_obtaining_assent: '',
      formValid: false,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  validateComplete = () => {
    const {
      receive_copy_of_results,
      agree_to_be_contacted,
      name_of_participant,
      name_of_guardian,
      assent_of_minor,
      participant_dob,
      phone_number,
      email_address,
      name_person_obtaining_assent,
    } = this.state

    let data = this.state

    if (
      name_of_participant &&
      name_of_guardian &&
      assent_of_minor &&
      participant_dob &&
      phone_number &&
      email_address &&
      name_person_obtaining_assent &&
      receive_copy_of_results !== null &&
      agree_to_be_contacted !== null
    ) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (
      [
        'name_of_participant',
        'name_of_guardian',
        'assent_of_minor',
        'phone_number',
        'email_address',
        'name_person_obtaining_assent',
      ].includes(name)
    ) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    } else if (
      ['receive_copy_of_results', 'agree_to_be_contacted'].includes(name)
    ) {
      this.setState(
        {
          [name]: value === 'true',
        },
        this.validateComplete
      )
    }
  }

  handleDobChange = date => {
    this.setState({ participant_dob: date }, this.validateComplete)
  }

  render() {
    const {
      name_of_participant,
      name_of_guardian,
      assent_of_minor,
      participant_dob,
      phone_number,
      email_address,
      name_person_obtaining_assent,
    } = this.state

    const { translate } = this.props

    return (
      <div>
        <h4 style={{ textAlign: 'center' }}>
          {translate('montrealConsent.t1')}
        </h4>
        <h5 style={{ textAlign: 'center' }} className="lead mt-3 center">
          {translate('montrealConsent.t2')}
        </h5>
        <br />
        <br />

        <p>
          <b>{translate('montrealConsent.t3')}</b>
          <br />
          <b>{translate('montrealConsent.t4')}</b>
        </p>

        <p>
          <b>{translate('montrealConsent.t5')}</b>
          <br />
          <b>{translate('montrealConsent.t6')}</b>{' '}
          <span>{translate('montrealConsent.t7')}</span>
          <br />
          <b>{translate('montrealConsent.t8')}</b>{' '}
          <span>{translate('montrealConsent.t9')}</span>
          <br />
          <b>{translate('montrealConsent.t10')}</b>{' '}
          <span>{translate('montrealConsent.t11')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t12')}</b>
          <br />
          <span>{translate('montrealConsent.t13')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t14')}</b>
          <br />
          <p>{translate('montrealConsent.t15')}</p>
          <p>{translate('montrealConsent.t15-1')}</p>
          <p>{translate('montrealConsent.t16')}</p>
        </p>

        <p>
          <b>{translate('montrealConsent.t17')}</b>
          <br />
          <span>{translate('montrealConsent.t18')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t19')}</span>
          <br />
          <ol>
            <li>{translate('montrealConsent.t20')}</li>
            <li>{translate('montrealConsent.t21')}</li>
            <li>{translate('montrealConsent.t22')}</li>
            <li>{translate('montrealConsent.t23')}</li>
            <li>{translate('montrealConsent.t24')}</li>
            <li>{translate('montrealConsent.t25')}</li>
          </ol>
          <span>{translate('montrealConsent.t26')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t27')}</b>
          <br />
          <span>{translate('montrealConsent.t28')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t29')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t30')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t31')}</b>
          <br />
          <ol type="a">
            <li>{translate('montrealConsent.t32')}</li>
            <li>{translate('montrealConsent.t33')}</li>
            <li>{translate('montrealConsent.t34')}</li>
            <li>{translate('montrealConsent.t35')}</li>
            <li>{translate('montrealConsent.t36')}</li>
            <li>{translate('montrealConsent.t37')}</li>
            <li>{translate('montrealConsent.t38')}</li>
            <li>{translate('montrealConsent.t39')}</li>
            <li>{translate('montrealConsent.t40')}</li>
          </ol>
          <span>{translate('montrealConsent.t41')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t42')}</b>
          <br />
          <ul>
            <li>{translate('montrealConsent.t43')}</li>
            <li>{translate('montrealConsent.t43-1')}</li>
          </ul>
          <span>{translate('montrealConsent.t44')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t53')}</b>
          <br />
          <span>{translate('montrealConsent.t54')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t55')}</span>
          <br />
          <ol type="a">
            <li>{translate('montrealConsent.t56')}</li>
            <li>{translate('montrealConsent.t57')}</li>
            <li>{translate('montrealConsent.t58')}</li>
            <li>{translate('montrealConsent.t59')}</li>
            <li>{translate('montrealConsent.t60')}</li>
            <li>{translate('montrealConsent.t61')}</li>
            <li>{translate('montrealConsent.t62')}</li>
            <li>{translate('montrealConsent.t63')}</li>
            <li>{translate('montrealConsent.t63-1')}</li>
            <li>{translate('montrealConsent.t63-2')}</li>
            <li>{translate('montrealConsent.t63-3')}</li>
            <li>{translate('montrealConsent.t63-4')}</li>
          </ol>
        </p>

        <p>
          <b>{translate('montrealConsent.t64')}</b>
          <br />
          <span>{translate('montrealConsent.t65')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t66')}</b>
          <br />
          <span>{translate('montrealConsent.t67')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t68')}</b>
          <br />
          <span>{translate('montrealConsent.t69')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t70')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t71')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t72')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t73')}</b>
          <br />
          <span>{translate('montrealConsent.t74')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t75')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t76')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t77')}</b>
          <br />
          <span>{translate('montrealConsent.t78')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t79')}</b>
          <br />
          <p>{translate('montrealConsent.t80')}</p>
          <p>{translate('montrealConsent.t81')}</p>
          <p>{translate('montrealConsent.t82')}</p>
        </p>

        <p>
          <b>{translate('montrealConsent.t83')}</b>
          <br />
          <span>{translate('montrealConsent.t84')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t85')}</b>
          <br />
          <span>{translate('montrealConsent.t86')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t87')}</b>
          <br />
          <span>{translate('montrealConsent.t88')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t89')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t90')}</span>
        </p>

        <p>
          <b>{translate('montrealConsent.t91')}</b>
          <br />
          <span>{translate('montrealConsent.t92')}</span>
          <br />
          <br />
          <span>{translate('montrealConsent.t93')}</span>
        </p>

        <h5 style={{ textAlign: 'center' }} className="lead mt-3 center">
          {translate('montrealConsent.t94')}
        </h5>

        <p>
          <b>{translate('montrealConsent.t95')}</b>
        </p>

        <p>
          <b>{translate('montrealConsent.t96')}</b>{' '}
          <span>{translate('montrealConsent.t97')}</span>
          <br />
          <br />
          <ol>
            <li>{translate('montrealConsent.t98')}</li>
            <li>{translate('montrealConsent.t99')}</li>
            <li>{translate('montrealConsent.t100')}</li>
            <li>{translate('montrealConsent.t101')}</li>
            <li>{translate('montrealConsent.t102')}</li>
            <li>{translate('montrealConsent.t103')}</li>
            <li>{translate('montrealConsent.t104')}</li>
            <li>{translate('montrealConsent.t105')}</li>
            <li>{translate('montrealConsent.t106')}</li>
            <li>{translate('montrealConsent.t107')}</li>
            <li>
              {translate('montrealConsent.t108')}
              <div className="form-check" style={{ marginTop: '1rem' }}>
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="receive_copy_of_results"
                    id="receive_copy_of_results_yes"
                    value={true}
                    onClick={this.handleChange}
                  />
                  {translate('montrealAssent.t101')}
                </label>
              </div>
              <div className="form-check" style={{ marginBottom: '1rem' }}>
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="receive_copy_of_results"
                    id="receive_copy_of_results_no"
                    value={false}
                    onClick={this.handleChange}
                  />
                  {translate('montrealAssent.t102')}
                </label>
              </div>
            </li>
            <li>
              {translate('montrealConsent.t109')}
              <div className="form-check" style={{ marginTop: '1rem' }}>
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="agree_to_be_contacted"
                    id="agree_to_be_contacted_yes"
                    value={true}
                    onClick={this.handleChange}
                  />
                  {translate('montrealAssent.t101')}
                </label>
              </div>
              <div className="form-check" style={{ marginBottom: '1rem' }}>
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="agree_to_be_contacted"
                    id="agree_to_be_contacted_no"
                    value={false}
                    onClick={this.handleChange}
                  />
                  {translate('montrealAssent.t102')}
                </label>
              </div>
            </li>
          </ol>
        </p>

        <p>
          <small>{translate('quebecCityConsent.t21')}</small>
        </p>
        <hr />

        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            {translate('montrealConsent.t110')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="name_of_participant"
              name="name_of_participant"
              value={name_of_participant}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            <small>{translate('montrealConsent.t111')}</small>
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="assent_of_minor"
              name="assent_of_minor"
              value={assent_of_minor}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            {translate('montrealConsent.t112')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="name_of_guardian"
              name="name_of_guardian"
              value={name_of_guardian}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-4 col-form-label">
            {translate('montrealConsent.t113')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <DatePicker
              id="sessionDate"
              className="form-control"
              name="sessionDate"
              maxDate={new Date()}
              placeholderText="mm/dd/yyyy"
              dropdownMode="select"
              showYearDropdown
              scrollableYearDropdown
              selected={participant_dob}
              onChange={this.handleDobChange}
              onChangeRaw={this.handleDobChange}
              autoComplete="off"
            ></DatePicker>
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            {translate('montrealConsent.t114')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="phone_number"
              name="phone_number"
              value={phone_number}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            {translate('montrealConsent.t115')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="email_address"
              name="email_address"
              value={email_address}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            {translate('montrealConsent.t116')}
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="name_person_obtaining_assent"
              name="name_person_obtaining_assent"
              value={name_person_obtaining_assent}
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    )
  }
}
