import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-number-input";

import { Translate, withLocalize } from "react-localize-redux";
import ReactModal from "react-modal";
import { ClipLoader } from "react-spinners";

import { store } from "../../../helpers/index";
import { FormErrors } from "../../FormErrors";
import {
  recordsActions,
  alertActions,
  request,
  success,
  failure
} from "../../../actions/index";
import { siteServices } from "../../../services";
import globalTranslations from "../../../localize/global";
import { recordServices } from "../../../services";
import { authSuccess } from "../../../actions/index";

let cancel = { exec: null };

class AddChild extends React.Component {
  constructor(props) {
    super(props);

    this.props.addTranslation(globalTranslations);

    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      date_of_birth: "",
      site_name: "",
      phone_number: "",
      formErrors: {
        site_name: "",
        email: "",
        first_name: "",
        last_name: "",
        phone_number: ""
      },
      dobValid: false,
      siteValid: false,
      emailValid: false,
      formValid: false,
      firstNameValid: false,
      lastNameValid: false,
      phoneNumberValid: false,
      sites: [],
      childAddedModalOpen: false,
      loadingChild: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = store;

    siteServices.get(cancel).then(
      response => {
        const data = response.data;

        this.setState({
          sites: data
        });
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors;
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e));
            });
          }
        }
      }
    );
  }

  componentWillUnmount() {
    if (cancel.exec) cancel.exec();
  }

  handleChange(event, type = "") {
    let name = "";
    let value = "";
    if (type === "phone_number") {
      name = "phone_number";
      value = event;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleDateChange(date) {
    if (date) {
      this.setState({
        date_of_birth: date,
        dobValid: true
      });

      this.validateForm();
    }
  }

  handleDateChangeRaw = e => {
    e.preventDefault();
  };

  handleSubmit(event) {
    event.preventDefault();
    const { dispatch } = store;
    const {
      formValid,
      email,
      first_name,
      last_name,
      date_of_birth,
      site_name,
      phone_number
    } = this.state;

    if (formValid) {
      this.setState({ loadingChild: true });

      recordServices
        .create({
          email,
          first_name,
          last_name,
          date_of_birth,
          site_name,
          phone_number
        })
        .then(
          response => {
            if ("token" in response.data) {
              const token = response.data.token;
              sessionStorage.setItem("token", token);
              dispatch(authSuccess(token));
            }
            this.setState({ childAddedModalOpen: true });
          },
          error => {
            this.setState({ loadingChild: false });
            if (error.response) {
              const errors = error.response.data.errors;
              if (errors) {
                errors.forEach(e => {
                  dispatch(alertActions.error(e));
                });
              }
            }
          }
        );
    }

    this.setState({
      email: "",
      first_name: "",
      last_name: "",
      date_of_birth: "",
      site_name: "",
      phone_number: "",
      formErrors: { site_name: "", email: "", first_name: "", last_name: "" },
      dobValid: false,
      siteValid: false,
      emailValid: false,
      firstNameValid: false,
      lastNameValid: false,
      formValid: false
    });
  }

  populateSchools() {
    const { sites } = this.state;

    let items = [];
    if (sites.length) {
      for (let i = 0; i < sites.length; i++)
        items.push(<option key={i}>{sites[i]}</option>);
    }

    return items;
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let {
      dobValid,
      siteValid,
      emailValid,
      firstNameValid,
      lastNameValid,
      phoneNumberValid
    } = this.state;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid
          ? ""
          : this.props.translate("addChild.form.errors.validEmail");
        break;
      case "first_name":
        firstNameValid = value.length && typeof value === "string";
        fieldValidationErrors.first_name = firstNameValid
          ? ""
          : this.props.translate("addChild.form.errors.validFirstName");
        break;
      case "last_name":
        lastNameValid = value.length && typeof value === "string";
        fieldValidationErrors.last_name = lastNameValid
          ? ""
          : this.props.translate("addChild.form.errors.validLastName");
        break;
      case "site_name":
        siteValid = value !== "Select school...";
        fieldValidationErrors.site_name = siteValid
          ? ""
          : this.props.translate("addChild.form.errors.validSchool");
        break;
      case "phone_number":
        phoneNumberValid = value && value.length >= 10;
        break;
      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        dobValid: dobValid,
        siteValid: siteValid,
        emailValid: emailValid,
        firstNameValid: firstNameValid,
        lastNameValid: lastNameValid,
        phoneNumberValid: phoneNumberValid
      },
      this.validateForm
    );
  }

  validateForm() {
    const {
      dobValid,
      siteValid,
      emailValid,
      firstNameValid,
      lastNameValid,
      phoneNumberValid
    } = this.state;

    this.setState({
      formValid:
        dobValid &&
        emailValid &&
        siteValid &&
        firstNameValid &&
        lastNameValid &&
        phoneNumberValid
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  closeModal = () => {
    const { dispatch } = store;
    this.setState({ childAddedModalOpen: false });

    dispatch(request());
    recordServices.get().then(
      response => {
        this.setState({ loadingChild: false });
        const records = response.data.data;
        if (records) {
          sessionStorage.setItem("records", JSON.stringify(records));
          dispatch(success(records));
        }
      },
      error => {
        this.setState({ loadingChild: false });
        if (error.response) {
          const errors = error.response.data.errors;
          if (errors) {
            dispatch(failure(errors));
            errors.forEach(e => {
              dispatch(alertActions.error(e));
            });
          }
        }
      }
    );
    dispatch(alertActions.success(this.props.translate("alerts.childAdded")));
  };

  render() {
    const {
      email,
      first_name,
      last_name,
      date_of_birth,
      site_name,
      phone_number,
      childAddedModalOpen,
      loadingChild,
      formErrors,
      formValid
    } = this.state;

    return (
      <div className="mt-5">
        <ReactModal
          style={{
            overlay: { backgroundColor: "rgba(33, 33, 33, 0.85)" },
            content: {
              maxWidth: 650,
              maxHeight: 300,
              width: "80%",
              margin: "auto"
            }
          }}
          isOpen={childAddedModalOpen}
          onRequestClose={this.closeModal}
        >
          <div style={{ textAlign: "center", paddingTop: "0.5rem" }}>
            <p>
              <b>Thank you for adding your child</b>
            </p>
            <p>
              Your child should receive an account activation email from <br />
              <a href="!#"> no-reply@shredconcussions.ca</a> (please check the
              junk or spam folder) If they do not receive this email you can
              resend it on the edit child page.
            </p>
            <p>
              <b>
                Please complete the baseline surveys for your child by selecting
                their name above
              </b>
            </p>
          </div>
          <div style={{ textAlign: "center", paddingTop: "2rem" }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.closeModal();
              }}
              style={{ width: "75px" }}
            >
              Close
            </button>
          </div>
        </ReactModal>
        {loadingChild ? (
          <ClipLoader
            loading={true}
            color={"#fff"}
            css={{ display: "block", margin: "0 auto", borderColor: "red" }}
            size={45}
          />
        ) : (
          <React.Fragment>
            <h2 className="mb-4">
              <Translate id="addChild.header.add" />
              <span className="text-primary">
                <Translate id="addChild.header.child" />
              </span>
            </h2>
            <div className="mt-2 mb-2">
              <FormErrors formErrors={formErrors} />
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group row">
                <label className="col-lg-2 col-form-label">
                  <Translate id="addChild.form.fields.dateOfBirth" />
                </label>
                <div className="col-lg-3 mb-2">
                  <DatePicker
                    id="date_of_birth"
                    className="form-control"
                    name="date_of_birth"
                    placeholderText="mm/dd/yyyy"
                    maxDate={new Date(2014, 11, 31)}
                    minDate={new Date(1989, 1, 1)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={date_of_birth}
                    onChange={this.handleDateChange}
                    onChangeRaw={this.handleDateChangeRaw}
                    autoComplete="off"
                  ></DatePicker>
                </div>
                <label className="col-lg-2 col-form-label">
                  <Translate id="addChild.form.fields.age" />:{" "}
                  {date_of_birth ? (
                    <b>{moment().diff(date_of_birth, "years")}</b>
                  ) : (
                    ""
                  )}
                </label>
              </div>
              <div className="form-group row">
                <label className="col-lg-2 col-form-label">
                  <Translate id="addChild.form.fields.firstName" />
                </label>
                <div className="col-lg-6 mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name="first_name"
                    value={first_name}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-2 col-form-label">
                  <Translate id="addChild.form.fields.lastName" />
                </label>
                <div className="col-lg-6 mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    value={last_name}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-2 col-form-label">
                  <Translate id="addChild.form.fields.email" />
                </label>
                <div className="col-lg-6 mb-2">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="example@gmail.com"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-2 col-form-label">
                  <Translate id="addChild.form.fields.phoneNumber" />
                </label>
                <div className="col-lg-6 mb-2">
                  <PhoneInput
                    className="form-control"
                    name="phone_number"
                    defaultCountry="CA"
                    value={phone_number}
                    onChange={value => this.handleChange(value, "phone_number")}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-2 col-form-label">
                  <Translate id="addChild.form.fields.school" />
                </label>
                <div className="col-lg-6 mb-2">
                  <select
                    id="site_name"
                    className="form-control"
                    name="site_name"
                    value={site_name}
                    onChange={this.handleChange}
                  >
                    <option defaultValue={true}>
                      {this.props.translate(
                        "addChild.form.fields.selectSchool"
                      )}
                    </option>
                    {this.populateSchools()}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary mt-4"
                  disabled={!formValid}
                >
                  <Translate id="addChild.form.fields.addChild" />
                </button>
                <button
                  className="btn btn-primary mt-4 ml-2"
                  onClick={this.props.handleCancel}
                >
                  <Translate id="addChild.form.fields.cancel" />
                </button>
              </div>
            </form>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withLocalize(AddChild);
