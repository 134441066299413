import React from 'react'

export const OttawaAssent = props => {
  return (
    <div>
      <h4>{props.translate('ottawaAssent.t1')}</h4>
      <p>{props.translate('ottawaAssent.t2')}</p>
      <p>{props.translate('ottawaAssent.t3')}</p>
      <p>{props.translate('ottawaAssent.t4')}</p>
      <p>{props.translate('ottawaAssent.t5')}</p>
      <p>{props.translate('ottawaAssent.t6')}</p>
      <p>{props.translate('ottawaAssent.t7')}</p>
      <p>{props.translate('ottawaAssent.t8')}</p>
    </div>
  )
}
