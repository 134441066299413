import { handleAxiosError } from "../helpers";
import { instance } from "../helpers";

export const surveyLinkServices = {
  get
};

function get(params) {
  return instance
    .get(`/survey-link?${params}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return handleAxiosError(error);
    });
}
