import { sportConstants } from '../constants'

const initialState = {}

export function sport(state = initialState, action) {
  switch (action.type) {
    case sportConstants.SPORT_SUCCESS:
      return {
        sports: action.sports,
      }
    case sportConstants.SPORT_FAILURE:
      return {
        sports: null,
      }
    default:
      return state
  }
}
