import React from 'react'
import { BeatLoader } from 'react-spinners'

export const Spinner = props => {
  return (
    <BeatLoader
      loading={props.loading}
      color={'#e30c00'}
      size={35}
      margin={'15px'}
    />
  )
}
