import { push } from 'react-router-redux'
import { sessionActions } from './session.actions'
import { sessionConstants } from '../constants'
import { userServices } from '../services'
import { alertActions } from './index'

export const userActions = {
  update,
  destroy,
}

function update(params, translate) {
  return dispatch => {
    userServices.update(params).then(
      response => {
        const token = response.data.token
        sessionStorage.setItem('token', token)
        dispatch(authSuccess(token))
        dispatch(alertActions.success(translate('alerts.settingsUpdated')))

        if ('language' in params) {
          window.location.reload()
        }
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }

  //need this to update token values
  function authSuccess(token) {
    return { type: sessionConstants.SESSION_UPDATE_SUCCESS, token }
  }
}

function destroy(type, translate) {
  return dispatch => {
    userServices.destroy(type).then(
      response => {
        dispatch(sessionActions.destroy())
        dispatch(alertActions.success(translate('alerts.accountDeleted')))
        dispatch(push('/'))
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }
}
