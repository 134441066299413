import { sportConstants } from '../constants'
import { alertActions } from './index'
import { sportServices } from '../services'

export const sportActions = {
  get,
}

function get() {
  return dispatch => {
    sportServices.get().then(
      response => {
        const sports = response.data

        dispatch(success(sports))
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
        dispatch(failure())
      }
    )
  }

  function success(sports) {
    return { type: sportConstants.SPORT_SUCCESS, sports }
  }
  function failure() {
    return { type: sportConstants.SPORT_FAILURE }
  }
}
