import { createBrowserHistory as createHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import ReactGA from 'react-ga'

export const history = createHistory()
export const routeMiddleware = routerMiddleware(history)

if (process.env.NODE_ENV !== `development`) {
  history.listen(location => {
    const page = location.pathname + location.search
    ReactGA.set({ page: page })
    ReactGA.pageview(page)
  })
}
