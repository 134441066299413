import React, { Component } from 'react'

import { default as Topbar } from './Topbar'
import { default as Navbar } from './Navbar'

export class Header extends Component {
  render() {
    return (
      <div>
        <div className="header-1">
          <Topbar />
          <Navbar />
        </div>
      </div>
    )
  }
}
