import { combineReducers } from 'redux'

import { session } from './session.reducer'
import { record } from './record.reducer'
import { sport } from './sport.reducer'

const appReducer = combineReducers({
  session,
  record,
  sport,
})

const reducers = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    state = undefined
  }

  return appReducer(state, action)
}

export default reducers
