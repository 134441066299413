import React, { useState } from "react";
import { Transition, animated } from "react-spring/renderprops";
import { Translate } from "react-localize-redux";
import { Collapse } from "reactstrap";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiOutlineWarning } from "react-icons/ai";

import Login from "../../components/Account/Login";
import homeJPG from "../../images/Home/home.jpg";
import homeWEBP from "../../images/Home/home.webp";
import shredBlack from "../../images/Home/shred_black.png";
import shredBus from "../../images/Home/shred_bus.jpg";
import dot1 from "../../images/Misc/siprc_dot1.png";
import dot2 from "../../images/Misc/siprc_dot2.png";
import dot3 from "../../images/Misc/siprc_dot3.png";
import dot4 from "../../images/Misc/siprc_dot4.png";
import "./home.css";

const items = [
  { image: dot1, key: 1 },
  { image: dot2, key: 2 },
  { image: dot3, key: 3 },
  { image: dot4, key: 4 }
];

export const Home = props => {
  const { isAuthenticated } = props;
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

  const [isOneOpen, setIsOneOpen] = useState(false);
  const [isTwoOpen, setIsTwoOpen] = useState(false);
  const [isThreeOpen, setIsThreeOpen] = useState(false);

  // This got messy...
  const toggle = selector => {
    if (selector === "one" && isOneOpen && !isTwoOpen && !isThreeOpen) {
      setIsOneOpen(false);
      return;
    } else if (selector === "two" && isTwoOpen && !isOneOpen && !isThreeOpen) {
      setIsTwoOpen(false);
      return;
    } else if (
      selector === "three" &&
      isThreeOpen &&
      !isOneOpen &&
      !isTwoOpen
    ) {
      setIsThreeOpen(false);
      return;
    } else if (!isOneOpen && !isTwoOpen && !isThreeOpen) {
      if (selector === "one") setIsOneOpen(true);
      else if (selector === "two") setIsTwoOpen(true);
      else setIsThreeOpen(true);
      return;
    } else {
      if (selector === "one") {
        setIsOneOpen(!isOneOpen);
        setIsTwoOpen(false);
        setIsThreeOpen(false);
      } else if (selector === "two") {
        setIsTwoOpen(!isTwoOpen);
        setIsOneOpen(false);
        setIsThreeOpen(false);
      } else {
        setIsThreeOpen(!isThreeOpen);
        setIsOneOpen(false);
        setIsTwoOpen(false);
      }
    }
  };

  const getDots = data => {
    const items = [];
    data.map(item =>
      items.push(
        <img
          key={item.key}
          className="dots dots-mobile m-2"
          src={item.image}
          alt="dot_logo"
        />
      )
    );
    return (
      <Transition
        native={true}
        items={items}
        keys={item => item.key}
        from={{
          transform: "translate3d(0,-50%,0)",
          opacity: "0"
        }}
        enter={{
          transform: "translate3d(0,0,0)",
          opacity: "1"
        }}
        trail={175}
      >
        {item => props => <animated.div style={props}>{item}</animated.div>}
      </Transition>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <picture>
        <source srcSet={homeWEBP} type="image/webp" />
        <source srcSet={homeJPG} type="image/jpeg" />
        <img
          className="home-cover"
          alt="background"
          src={homeJPG}
          style={{
            width: "100%",
            objectFit: "cover",
            borderRadius: `0px`
          }}
        />
      </picture>
      <div
        className="container"
        style={{
          position: "absolute",
          left: "55%",
          top: "22%",
          transform: "translate(-55%, -15vh)",
          zIndex: 1
        }}
      >
        <div className="row">{getDots(items)}</div>
        <div className="row">
          <div
            className="col-lg-7 p-5 mt-2 card"
            style={{
              boxShadow:
                "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"
            }}
          >
            {!isMobile && (
              <div className="mb-4">
                <h1 className="heading m-0">
                  <div
                    className="text-primary"
                    style={{ display: "absolute", bottom: "0px" }}
                  >
                    <img
                      className="dots dots-mobile m-2"
                      style={{ width: "30%", paddingBottom: "10px" }}
                      src={shredBlack}
                      alt="shredBlack"
                    />
                    <Translate id="home.loginTitle" />
                  </div>
                </h1>
                <span className="lead muted-color">
                  <Translate id="home.loginHeader" />
                </span>
              </div>
            )}
            {!isAuthenticated && <Login loginStyle={!isMobile && "home"} />}
            {!isAuthenticated && (
              <React.Fragment>
                <hr />
                <div style={{ maxHeight: "400", overflowY: "auto" }}>
                  <p className="muted-color" style={{ fontWeight: 400 }}>
                    More information about the SHRed Concussions Research
                    Program supported by the NFL Play Smart Play Safe Program
                    can be found{" "}
                    <a
                      target="_blank"
                      href="https://www.ucalgary.ca/sport-injury-prevention-research-centre/research/studies/concussion/shred-concussions"
                    >
                      here
                    </a>
                  </p>
                  <img
                    style={{ width: "65%", paddingBottom: "10px" }}
                    src={shredBus}
                    alt="shredMobile"
                  />
                  <p className="muted-color" style={{ fontWeight: 400 }}>
                    Check out the SHRed Mobile{" "}
                    <a
                      target="_blank"
                      href="https://www.ucalgary.ca/sport-injury-prevention-research-centre/shred-mobile"
                    >
                      here
                    </a>
                  </p>
                  <p className="muted-color" style={{ fontSize: "90%" }}>
                    The Sport Injury Prevention Research Centre is one of the 11
                    International Olympic Committee Research Centres for the
                    Prevention of Injury and Protection of Athlete Health. For
                    more information click{" "}
                    <a
                      target="_blank"
                      href="https://www.ucalgary.ca/sport-injury-prevention-research-centre"
                    >
                      here
                    </a>
                  </p>
                </div>
              </React.Fragment>
            )}
            {!isAuthenticated && (
              <React.Fragment>
                <hr />
                <div style={{ maxHeight: "345px", overflowY: "auto" }}>
                  <h5 className="text-primary">
                    <a
                      style={{ textDecoration: "none" }}
                      href="#!"
                      onClick={() => toggle("one")}
                    >
                      <Translate id="home.collapseOne.title" />{" "}
                      <span class="text-right">
                        {isOneOpen ? (
                          <IoIosArrowUp style={{ verticalAlign: "middle" }} />
                        ) : (
                          <IoIosArrowDown style={{ verticalAlign: "middle" }} />
                        )}
                      </span>
                    </a>
                  </h5>
                  <Collapse isOpen={isOneOpen}>
                    <ul style={{ color: "#665c56" }}>
                      <li>
                        <Translate id="home.collapseOne.p1s1" />
                        <b>
                          <Translate id="home.collapseOne.p1s2" />
                        </b>
                        <Translate id="home.collapseOne.p1s3" />
                      </li>
                      <li style={{ marginTop: "0.5rem" }}>
                        <Translate id="home.collapseOne.p2s1" />
                        <b>
                          <Translate id="home.collapseOne.p2s2" />
                        </b>
                        <Translate id="home.collapseOne.p2s3" />
                      </li>
                      <li style={{ marginTop: "0.5rem" }}>
                        <Translate id="home.collapseOne.p3s1" />
                        <b>
                          <Translate id="home.collapseOne.p3s2" />
                        </b>
                        <Translate id="home.collapseOne.p3s3" />
                      </li>
                    </ul>
                  </Collapse>
                  <h5 class="text-primary">
                    <a
                      style={{ textDecoration: "none" }}
                      href="#!"
                      onClick={() => toggle("two")}
                    >
                      <Translate id="home.collapseTwo.title" />{" "}
                      {isTwoOpen ? (
                        <IoIosArrowUp style={{ verticalAlign: "middle" }} />
                      ) : (
                        <IoIosArrowDown style={{ verticalAlign: "middle" }} />
                      )}
                    </a>
                  </h5>
                  <Collapse isOpen={isTwoOpen}>
                    <ul style={{ color: "#665c56" }}>
                      <li>
                        <Translate id="home.collapseTwo.p1" />
                      </li>
                      <li style={{ marginTop: "0.5rem" }}>
                        <Translate id="home.collapseTwo.p2" />
                      </li>
                      <li style={{ marginTop: "0.5rem" }}>
                        <Translate id="home.collapseTwo.p3" />
                      </li>
                    </ul>
                  </Collapse>
                  <h5 className="text-primary">
                    <a
                      style={{ textDecoration: "none" }}
                      href="#!"
                      onClick={() => toggle("three")}
                    >
                      <Translate id="home.collapseThree.title" />{" "}
                      {isThreeOpen ? (
                        <IoIosArrowUp style={{ verticalAlign: "middle" }} />
                      ) : (
                        <IoIosArrowDown style={{ verticalAlign: "middle" }} />
                      )}
                    </a>
                  </h5>
                  <Collapse isOpen={isThreeOpen}>
                    <p style={{ color: "#665c56" }}>
                      <Translate id="home.collapseThree.p1" />
                    </p>
                    <p style={{ marginTop: "0.5rem", color: "#665c56" }}>
                      <Translate id="home.collapseThree.p2" />
                    </p>
                    <p style={{ marginTop: "0.5rem", color: "#665c56" }}>
                      <Translate id="home.collapseThree.p3" />
                    </p>
                  </Collapse>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
