import { CalgaryConsent } from "./CalgaryConsent";
import { CalgaryAssent } from "./CalgaryAssent";
import { WinnipegConsent } from "./WinnipegConsent";
import { WinnipegAssent } from "./WinnipegAssent";
import { EdmontonConsent } from "./EdmontonConsent";
import { EdmontonAssent } from "./EdmontonAssent";
import { OttawaConsent } from "./OttawaConsent";
import { OttawaAssent } from "./OttawaAssent";
import { MontrealConsent } from "./MontrealConsent";
import { MontrealAssent } from "./MontrealAssent";
import { QuebecCityConsent } from "./QuebecCityConsent";
import { QuebecCityAssent } from "./QuebecCityAssent";
import { VancouverConsent } from "./VancouverConsent";
import { VancouverAssent } from "./VancouverAssent";
import { KelownaConsent } from "./KelownaConsent";
import { KelownaAssent } from "./KelownaAssent";
import { LondonConsent } from "./LondonConsent";
import { LondonAssent } from "./LondonAssent";

export const consentForms = {
  Calgary: CalgaryConsent,
  Winnipeg: WinnipegConsent,
  Edmonton: EdmontonConsent,
  Ottawa: OttawaConsent,
  Montreal: MontrealConsent,
  Quebec: QuebecCityConsent,
  Vancouver: VancouverConsent,
  Kelowna: KelownaConsent,
  London: LondonConsent,
  Whistler_Crankworx: CalgaryConsent
};

export const assentForms = {
  Calgary: CalgaryAssent,
  Winnipeg: WinnipegAssent,
  Edmonton: EdmontonAssent,
  Ottawa: OttawaAssent,
  Montreal: MontrealAssent,
  Quebec: QuebecCityAssent,
  Vancouver: VancouverAssent,
  Kelowna: KelownaAssent,
  London: LondonAssent,
  Whistler_Crankworx: CalgaryAssent
};

export const consentContentRequired = {
  Calgary: true,
  Winnipeg: true,
  Edmonton: true,
  Ottawa: false,
  Montreal: true,
  Quebec: true,
  Vancouver: true,
  Kelowna: true,
  London: true
};

export const assentContentRequired = {
  Calgary: true,
  Winnipeg: true,
  Edmonton: false,
  Ottawa: false,
  Montreal: true,
  Quebec: true,
  Vancouver: false,
  Kelowna: false,
  London: true
};
