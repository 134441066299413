import { handleAxiosError } from '../helpers'
import { CancelToken } from 'axios'

import { instance } from '../helpers'

export const newInjuryServices = {
  create,
  get,
}

function create(params) {
  return instance
    .post('/new-injury', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function get(cancel) {
  return instance
    .get(
      '/new-injury',
      { cancelToken: new CancelToken(c => (cancel.exec = c)) },
      { headers: { Pragma: 'no-cache' } }
    )
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
