import React from 'react'

import { Section } from '../Profile/SectionHeading/Section'
import SurveyList from './SurveyList'

export const Surveys = props => {
  const {
    surveys,
    surveyType,
    record,
    surveyPath,
    handleInjuryChange,
    currentSports,
    role,
    translate,
  } = props

  return (
    <div>
      <Section heading={surveyType} role={role} translate={translate} />
      <SurveyList
        surveys={surveys}
        surveyType={surveyType}
        record={record}
        surveyPath={surveyPath}
        handleInjuryChange={handleInjuryChange}
        currentSports={currentSports}
      />
    </div>
  )
}
