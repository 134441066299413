import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'
import { ClipLoader } from 'react-spinners'

import { store } from '../../helpers'
import { sessionActions } from '../../actions'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleRedirect = this.handleRedirect.bind(this)
  }

  handleChange(event) {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    const { email, password } = this.state
    const { onSubmit } = this.props
    const { dispatch } = store

    event.preventDefault()
    if (email && password) {
      dispatch(
        sessionActions.create({ email, password }, this.props.setActiveLanguage)
      )

      if (onSubmit) this.props.onSubmit()
    }
  }

  handleRedirect() {
    const { onClose } = this.props

    if (onClose) this.props.onClose()
  }

  render() {
    const { email, password } = this.state
    const { loginStyle, translate, loading } = this.props

    const alignment =
      loginStyle === 'home' ? 'float-left' : 'justify-content-center'
    const showHeader = loginStyle !== 'home'

    return (
      <div className={'row flex-row ' + alignment}>
        <div className="col-lg-7">
          {showHeader && (
            <h2 className="mb-4 heading">
              {translate('login.header.login')}{' '}
              <span className="text-primary">
                {translate('login.header.in')}
              </span>
            </h2>
          )}
          <form name="form" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="email"
                placeholder={this.props.translate('login.emailPlaceholder')}
                value={email}
                autoFocus
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder={this.props.translate('login.passwordPlaceholder')}
                value={password}
                onChange={this.handleChange}
              />
            </div>
            <button
              type="submit"
              name="submit"
              className="btn btn-primary mt-3 mb-2"
              disabled={loading}
            >
              <Translate id="login.loginButton" />{' '}
              <ClipLoader loading={loading} size={15} color="#fff" />
            </button>
            <div>
              <Link
                className="btn-custom"
                to="/forgot-password"
                onClick={this.handleRedirect}
                style={{ fontSize: '14px' }}
              >
                <Translate id="login.forgetPassword" />
              </Link>
            </div>
            <div>
              <Link
                className="btn-custom"
                to="/signup"
                onClick={this.handleRedirect}
                style={{ fontSize: '14px' }}
              >
                <Translate id="login.noAccount" />
              </Link>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.session.loading,
  }
}

export default withLocalize(connect(mapStateToProps)(Login))
