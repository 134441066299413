import React from 'react'
import DatePicker from 'react-datepicker'

export class CalgaryAssent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      agree_to_be_contacted: false,
      parent_name: '',
      parent_email: '',
      parent_phone_number: '',
      child_name: '',
      child_email: '',
      child_dob: '',
      delegate_name: '',
      witness_name: '',
    }
  }

  validateComplete = () => {
    const { child_name, child_email, child_dob } = this.state

    let data = this.state

    if (child_name && child_email && child_dob) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (
      [
        'parent_name',
        'parent_email',
        'parent_phone_number',
        'child_name',
        'child_email',
        'delegate_name',
        'witness_name',
      ].includes(name)
    ) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    } else if (['agree_to_be_contacted'].includes(name)) {
      this.setState(
        {
          [name]: event.target.checked,
        },
        this.validateComplete
      )
    }
  }

  handleDobChange = date => {
    this.setState({ child_dob: date }, this.validateComplete)
  }

  render() {
    const {
      child_name,
      child_email,
      child_dob,
      parent_name,
      parent_email,
      parent_phone_number,
      delegate_name,
      witness_name,
    } = this.state

    return (
      <div>
        <h4>Consent Form for Participants</h4>
        <h5 className="lead mt-3">TITLE:</h5>
        <b>
          Surveillance in High Schools to Reduce Concussions and Consequences of
          Concussions in Canadian Youth – SHRed Concussions
        </b>
        <h5 className="lead mt-3">FUNDING:</h5>
        National Football League’s Scientific Advisory Board
        <h5 className="lead mt-3">INVESTIGATORS:</h5>
        <p>Principal Investigator: Dr. Carolyn Emery</p>
        <p>
          <i>
            This consent form is only part of the process of informed consent.
            It should give you the basic idea of what the research is about and
            what your participation will involve. If you would like more detail
            about something mentioned here, or information not included here,
            please ask. Take the time to read this carefully and to understand
            any accompanying information. A copy of this form can be downloaded
            for your records.
          </i>
        </p>
        <h5 className="lead mt-3">BACKGROUND</h5>
        <p>
          Sport is good for youth, but there is always a chance of getting
          injured. One of the injuries that can happen is a concussion. There
          has been research on concussion in some Canadian youth sports, such as
          hockey. This research has looked at why some youth may be more likely
          to get a concussion, what affects the amount of time it takes to
          recover from concussion, and ways to prevent concussions in sports.
          This helps researchers and health professionals develop strategies
          that can be used to educate teachers, coaches, parents, and students
          in schools about concussions.
        </p>
        <h5 className="lead mt-3">WHAT IS THE PURPOSE OF THE STUDY?</h5>
        <p>This study has six major goals:</p>
        <div>
          <ol>
            <li>
              Collect information on injuries and concussions in high school
              students
            </li>
            <li>
              Determine why some high school students may have a higher risk of
              concussion than others
            </li>
            <li>Examine recovery following a concussion</li>
            <li>
              Evaluate what high school students, teachers, coaches, and parents
              know about concussion, and how they manage concussion
            </li>
            <li>
              Create a concussion education program for high school teachers,
              coaches, and students, and evaluate if it works
            </li>
            <li>
              Create sport-related concussion prevention programs and evaluate
              if these programs work
            </li>
          </ol>
        </div>
        <p>
          To accomplish these goals, we will be comparing children who sustain a
          concussion to those who do not through their high school years. These
          comparisons include a number of measures that look at how you feel
          about sport, concentration, physical measures such as flexibility,
          vision, coordination and balance. Biological measures including MRI
          and blood tests will also be done. Testing may occur at school, at the
          University or in a clinic.
        </p>
        <h5 className="lead mt-3">WHAT WOULD I HAVE TO DO?</h5>
        <p>
          We will be asking 6000 high school students participating in at least
          one high-risk concussion sport (e.g. ice hockey, rugby, football,
          soccer, basketball, volleyball, wrestling, ringette, cheerleading,
          lacrosse) across Canada to be in the study.
        </p>
        <p>
          We will follow these participants through their years at high school.
          All participating youth will be asked to attend a baseline testing
          session at the time of study recruitment during which they will be
          asked to complete a variety of measurements. This will take
          approximately 3 hours to complete and will occur in a combination of
          your school location, University of Calgary, Faculty of Kinesiology
          (Sport Medicine Centre and Sport Injury Prevention Research Centre),
          and/or community sport medicine clinic setting. Most of the baseline
          tests/questionnaires will be repeated once per year over 3 years. Some
          of the Psychosocial and sleep questionnaires (items 1c, 1d, 1e, and 1g
          below) may be repeated every 3 months to determine changes in these
          measures during the COVID-19 pandemic.
        </p>
        <p>Measurements for all participants will include:</p>
        <ol>
          <li>
            Completion of the following questionnaires online
            <br />
            <ol type="a">
              <li>
                Baseline Questionnaire (e.g., demographics, medical history,
                sport participation). Completed by you.
              </li>
              <li>
                Weekly self-report of participation hours in sport and any
                physical complaints and injuries that you sustain.
              </li>
              <li>
                Connor-Davidson Resilience Scale (this measures the “ability to
                "thrive in the face of adversity."). Completed by you only.
              </li>
              <li>
                The Strength and Difficulties Questionnaire is a brief emotional
                and behavioral screening questionnaire for children and young
                people.
              </li>
              <li>
                The Pediatric Quality of Life Inventory, (this measures health
                related quality of life in youth and adolescent and is completed
                by you and your parent)
              </li>
              <li>
                Cambridge Brain Sciences (CBS), (this is a computerized test of
                attention and is completed by you)
              </li>
              <li>
                8-item PROMIS sleep-disturbance and sleep-related impairment
                short-forms completed by you.
              </li>
              <li>
                Concussion Knowledge, Beliefs and Behaviour Questionnaire
                (completed by you and your parent)
              </li>
            </ol>
          </li>
          <li style={{ marginTop: '1rem' }}>
            The following tests and physical assessments of you administered by
            qualified research staff when it is deemed feasible and safe based
            on AHS and University of Calgary guidelines.. The location of
            testing will be at the school, university or clinic)
            <br />
            <ol type="a" style={{ marginTop: '1rem' }}>
              <li>
                Sport Concussion Assessment Tool 5 (SCAT5) (school or university
                or clinic)
              </li>
              <li>
                Cervical spine evaluation including measures of range of motion,
                strength, endurance, head and neck position sense and neuromotor
                control
              </li>
              <li>
                Vision assessment including tests of smooth pursuit, saccadic
                eye movement, convergence, optokinetic nystagmus, and stereo
                visual acuity test
              </li>
              <li>
                Vestibular, balance and strength measures will include the head
                thrust test, dynamic visual acuity, the Functional Gait
                Assessment, vertical jump height and grip strength
              </li>
              <li>
                Walking While Talking Test, which involves walking at a normal
                pace, walking and reciting the alphabet, and walking and
                reciting every other letter of the alphabet
              </li>
              <li>
                20-metre shuttle run, which involves travelling 20m distances
                within a set time. The set time decreases as the test
                progresses. You will initially be walking to complete the 20m
                distance, and eventually progress to jogging/running. The test
                ends when you can no longer complete the distance in the set
                time. You will be asked to wear a heart rate monitor during the
                whole 20-metre shuttle run to identify their maximum heart rate
                as well as their heart rate 5 minutes after they have completed
                the test.
              </li>
              <li>
                KINARM Robot assessment, which involves performing arm
                movements, including reaching and target hitting tasks
              </li>
              <li>
                Blood test to help identify presence of biomarkers (chemicals)
                associated with concussion. Blood will be taken by a trained
                phlebotomist or nurse with experience in pediatric phlebotomy,
                who can help answer any questions, concerns or anxiety you may
                have about the blood test. A maximum of two attempts will be
                made at each session. If you are uncomfortable or anxious about
                the blood test, or if you want to stop for any reason, they may
                do so. Your permission is being asked to store blood specimens
                in a biobank for future studies, which may include sharing the
                specimen, stripped of all identifying information, with other
                investigators for research purposes. We will also be asking you
                to have your blood taken in the mid-season of their sport year.
              </li>
            </ol>
            <p style={{ marginTop: '1rem' }}>
              Some measurements and assessments will only be undertaken on
              specific athlete groups:
            </p>
            <ol type="a">
              <li>
                Football, ice hockey, ringette or lacrosse athletes will be
                asked to have their helmet fit assessed. This currently will be
                conducted virtually using MS Teams or Zoom.
              </li>
              <li>
                Rugby and/or football athletes may be asked to participate in a
                study where they will be asked to wear a mouthguard including
                wearable technology to measure body and head impacts
              </li>
            </ol>
          </li>
          <p style={{ marginTop: '1rem' }}>
            A study therapist (Certified Athletic Therapist and/or
            physiotherapist) will be visiting your school or practice on a
            weekly basis. If you suffer any injury during the year, you will be
            able to have your injury assessed by the study therapist. If the
            study therapist suspects that you may have a concussion, they will
            refer you to a study physician with expertise in youth sport-related
            concussion.
          </p>
          <li style={{ marginTop: '1rem' }}>
            For those participants who have sustained a suspected concussion,
            the following examinations and assessments will occur:
          </li>
          <p style={{ marginTop: '1rem' }}>
            If you, your parent, the coach, or other team personnel suspects
            that you have sustained a concussion, you will have the opportunity
            to follow-up with a SHRed aligned physician specializing in
            concussion within 72-hours. The SHRed aligned physician will assess
            and diagnose your injury and recommend what you should do to recover
            from it fully before returning to sports. You can continue seeing
            the physician until cleared to return to playing sports.
          </p>
          <p>
            You will be asked to repeat the baseline measurements described
            above (except the 20m shuttle run). The following additional
            assessments will be conducted:
          </p>
          <ol type="a">
            <li>Illness Perception Questionnaire</li>
            <li>Behavioral Avoidance Scale</li>
            <li>Behavioral Response to Illness Questionnaire</li>
            <li>
              Graded Aerobic Treadmill Test, a standardized incremental
              treadmill test. You may also be asked to have your blood pressure,
              heart and breathing rates monitored before, after and during the
              test. For these tests you will wear a small cuff on your finger,
              three electrodes attached just under your collar bones and near
              the bottom of your ribs (electrocardiography [ECG]), and breathe
              into a mouthpiece.
            </li>
            <li>
              Actigraph which is a wearable technology monitor of physical
              activity to be worn around the waist following initial appointment
              (within 72 hours) with physician following concussion to monitor
              sleep and levels of physical activity (light-moderate- intense).
              You may be asked to wear the Actigraph 30 days after the
              concussion and 6 months after the concussion.
            </li>
            <li>
              Blood tests following concussion (acutely within 72 hours, 1 week,
              and every 2 weeks until physician clearance to return to sport).
              Your permission is being asked to store blood specimens in a
              biobank for future studies, which may include sharing the
              specimen, stripped of all identifying information, with other
              investigators for research purposes.
            </li>
            <li>
              MRI – as soon as possible after the injury and 30 days following
              concussion, you will be asked to undergo an MRI. If you are
              ineligible to undergo an MRI scan (braces or claustrophobic for
              example), they will be offered the ability to have an fNIRS and
              EEG analysis.
            </li>
            <li>
              Finally, you may be invited to participate in interviews or small
              group discussions about their knowledge, beliefs, and behaviours
              towards concussion. Information from interviews and groups
              discussions will be used to help develop a concussion education
              program.
            </li>
            <li>
              Your sport team may be selected to participate in the evaluation
              an injury prevention strategy. In this case, additional
              information will be provided to you and specific consent for
              participation in the program will be sought at that time.
            </li>
          </ol>
          <li style={{ marginTop: '1rem' }}>
            <b>Uninjured/healthy comparison group</b>
          </li>
          <p style={{ marginTop: '1rem' }}>
            We are trying to learn about the differences between children who
            experience a concussion and those who do not. At the time any study
            participant sustains a concussion, an athlete participating in the
            study who has not had a concussion will also be asked to undergo an
            MRI assessment and/or complete the treadmill exercise test and/or
            wear an Actigraph and/or be asked to participate in the KINARM
            assessment. This athlete will be selected to be similar to the
            student with a concussion regarding age, sex, and sport
            participation. This approach will help us to determine if MRI can
            help diagnose concussion. Wearing the Actigraph will help us
            determine the difference in activity and sleep levels between
            individuals who have or have not sustained a concussion. The KINARM
            assessment is a measure of neuro-motor control and will allow us to
            measure any differences between concussed individuals and those who
            did not suffer a concussion.
          </p>
        </ol>
        <h5 className="lead mt-3">WHAT ARE THE RISKS TO ME?</h5>
        <p>
          Due to the COVID-19 pandemic, all of the research, with the exception
          of clinic related activities due to concussion or injury, will be
          moving online. As such, we wanted to inform you that with the shift to
          online, research related risks may include privacy and security of the
          IT/communication platforms used. With the switching to online meeting
          tools the research team will be enhancing the data security provisions
          through the use of institutional online platform accounts which
          require a password for meetings and if recording, ensure data is
          stored locally.
        </p>
        <p>
          As the research team will be instituting virtual meetings with
          participants, there are no new risks related to transmission of the
          COVID-19 virus. If the participant requires clinical assessment due to
          a concussion or other injury, there are risks of transmission of the
          COVID-19 virus. The research team and clinic staff will take all
          precautions necessary, including use of PPE by team members and
          participants to mitigate the possibility of transmission.
        </p>
        <p>
          For those participants who are undergoing concussion or injury
          assessments, and when face to face interactions are allowed by both
          the University of Calgary and your school board or sport governing
          body (where applicable), the following are the risks to participants:
        </p>
        <p>
          COVID-19 - There are risks of transmission of the COVID-19 virius.
          Researchers and clinic staff will mitigate the possibility of
          transmission through:
        </p>
        <ul>
          <li>use of secure, remote interactions/methods where feasible</li>
          <li>screening those people attending in-person appointments</li>
          <li>
            use/provision of PPE for both research staff and research
            participants (e.g., masks, gloves)
          </li>
          <li>
            use/provision of hand sanitizer for both research staff and research
            participants (e.g., masks, gloves)
          </li>
          <li>single use research apparatus where possible</li>
          <li>physical distancing measures</li>
          <li>
            sanitization of surfaces and multi-use equipment between
            patients/participants
          </li>
        </ul>
        <p>
          Physical Assessments – All physical assessments will be done under
          close supervision and every effort will be made to ensure your safety.
          As with any physical activity, there is the possibility of a muscle
          strain for tests, such as running. Some testing may result in
          dizziness or muscle fatigue for a short time following the tests. You
          may also experience mild discomfort when the tester prepares your skin
          for electrode placement. You may also find breathing through a
          mouthpiece while exercising slightly more difficult than breathing
          without a mouthpiece. The risk of injury will be reduced by careful
          supervision by trained research team members during testing
          procedures. The neck, balance, vestibular, eye movement and other
          tests are ones that are typically used in clinical practice. These
          tests will all be done by clinicians who have training in the tests.
          If an increase in discomfort occurs above what is typically expected
          during testing or if you wish to stop testing, you should let the
          tester know. If you do have any symptoms at any time during testing,
          you should let the tester know and the test will be stopped.
        </p>
        <p>
          Blood tests - The blood tests will be done following standardized
          laboratory procedures. The person taking your blood will be a trained
          technician. Although very rare, there is a possibility of local
          infection within days of having blood taken. There is also a remote
          possibility of fainting. There is a possibility of a slight bruise at
          the needle site.
        </p>
        <p>
          Magnetic resonance imaging (MRI) is a technique that uses magnets and
          radio waves, not radiation, to take pictures of the body. MRI has no
          known harmful effects as long as you have none of the risk factors
          that will be screened for by the MRI technologist. Specifically, you
          should not have an MRI if they have a pacemaker or certain other metal
          objects inside their body (including dental braces), because the
          strong magnets in the MR scanner might cause these to heat up or move,
          causing harm. You will also need to remove all metal from their
          clothing and pockets; otherwise these objects could be pulled into the
          magnet and cause harm. No metal can be brought into the magnet room at
          any time, since the magnet is always “on”. During the MRI session, you
          will lie on a padded table and be asked to hold as still as possible
          while pictures are being taken. The MRI technologist will be carefully
          monitoring the session and will answer any questions or concerns that
          you or your parent may have during the session. When the scan begins,
          you will hear a loud knocking noise (like a drum beat) that can change
          at times during the scan. If you cannot lie still enough to complete a
          high-quality scan, are uncomfortable or anxious, or want to stop for
          any reason, you can be removed from the scanner immediately. Further,
          MRI will not be performed if you feel too claustrophobic to enter the
          scanner.
        </p>
        <p>
          Near-Infrared (NIR) light can be used to measure blood flow responses
          in the brain. Near-infrared spectroscopy (NIRS) systems offer a
          non-invasive and safe way to measure oxygen content in the brain. This
          is done by shining light into the tissue. Different light is absorbed
          differently by blood. The light is measured using fiber optics. These
          fiber optics will be placed on the head using a head cap.
        </p>
        <p>
          Electroencephalography (EEG) can be used to measure neuronal activity
          in the brain. EEG offers a non-invasive and safe way to measure brain
          activity. This is done by attaching electrodes to the scalp. These
          electrodes are sensitive to electric signals and thereby record
          electrical signals in their vicinity. The electrodes will be placed on
          the head using a head cap. An electrocardiogram (ECG) monitors heart
          function by measuring electrical activity of the heart. This is
          achieved through the placement of electrodes on three positions on the
          chest.
        </p>
        <p>
          If you consent to be part of this study, we will use fNIRS/EEG/ECG to
          examine brain activity, cardiac rhythm, and oxygen levels.
          Furthermore, we will examine these measures in relation to
          questionnaires relating to chronic and acute stress, pain, fatigue,
          and concussion history.
        </p>
        <p>
          The EEG, fNIRS, and ECG techniques are very safe and there are no
          risks to them.
        </p>
        <h5 className="lead mt-3">WILL I BENEFIT IF I TAKE PART?</h5>
        <p>
          There are some direct benefits to you. The information we get from
          this study will give researchers a better understanding of high school
          students’ injuries. This information will help develop strategies that
          can be used to prevent sport and recreational injuries in the future.
          The schools or clubs of athletes participating in the study will have
          access to a study therapist (athletic therapist or physiotherapist)
          one day per week to facilitate concussion educational opportunities.
          The study therapist will assess any injury sustained by a study
          participant in the previous week and make recommendations for
          follow-up. All study participants will have access to follow-up with a
          study physician with expertise in youth sport-related concussion
          within 72 hours of sustaining a suspected concussion.
        </p>
        <p>
          It is possible that you and/or your parent may learn more about
          injuries and concussions. If you get injured during the study, you
          will be assessed by a study therapist. If the study therapist suspects
          that you may have a concussion, they will refer you to a study
          physician.
        </p>
        <h5 className="lead mt-3">Incidental findings</h5>
        <p>
          In the unlikely scenario that a researcher observes a suspected
          abnormality in your results (i.e. images, blood tests), a study
          physician will be consulted and provided with you and your parent’s
          information. He or she will make a determination of its potential
          significance to your health and welfare. If considered to be a finding
          of potential clinical significance, you will be informed and the
          physician will make recommendations for follow-up.
        </p>
        <h5 className="lead mt-3">DOES I HAVE TO PARTICIPATE?</h5>
        <p>
          No, you do not have to be in the study. Participation in the study is
          voluntary and you may withdraw from the study at any time by
          contacting the study coordinator. If you leave the study, you may also
          request to withdraw your data. You may request to have any stored
          blood specimens destroyed if you decide to withdraw from the study.
          Your involvement in your team or school will not be affected if you
          choose not to consent to take part in the study. You will be informed
          if there is new information available through this study.
        </p>
        <p>
          You may be contacted in the future and be invited to take part in
          other aligned research studies in which separate consent will be
          sought. Data collected during this study may be combined and reported
          with data from other future studies conducted by this research team.
          We will not share your identifying information with anyone outside the
          research team.
        </p>
        <h5 className="lead mt-3">
          WILL THERE BE FINANCIAL COMPENSATION, OR WILL THERE BE COSTS FOR ME?
        </h5>
        <p>
          There will be no financial compensation or costs to you or your parent
          as a participant in this study. At the time of study related visits at
          a university or clinic, parking will be paid for you. In addition,
          juice and snacks will be available to you at the time of blood draw.
        </p>
        <p>
          We will be offering pizza parties, as well as having random draws for
          participants for U of Calgary Dinos, Calgary Hitmen, or other games.
        </p>
        <h5 className="lead mt-3">WILL MY RECORDS BE KEPT PRIVATE?</h5>
        <p>
          All information collected throughout the study period will be
          de-identified and will remain strictly confidential. Only the
          investigators responsible for this study, the research team members
          directly supervised by a study investigator, and the team statistician
          who will analyze the data, the University of Calgary, and the Conjoint
          Health Research Ethics Board will have access to this information.
          Data will be collected primarily through an online web-based
          customized surveillance platform (athlete monitoring) with
          authentication for users, encryption, and password protection in
          accordance with Personal Health Information Protection and Privacy Act
          (HIPPA) guidelines and in accordance with University of Calgary
          information Security Control Requirements approval and stored on an
          OVH Canada dedicated server in compliance with University of Calgary
          requirements.
        </p>
        <p>
          Confidentiality will be protected by using only study identification
          numbers in the database. Any results of the study, which are reported,
          will in no way identify study participants. Online surveys may ask for
          personal identifiers or information that may be used to identify you.
          However, no direct link is made between their information and their
          data. De-identified data may be used in future studies in alignment
          with this project. No medical data outside of study data collection
          will be accessed by the research team.
        </p>
        <h5 className="lead mt-3">
          IF I SUFFER A RESEARCH RELATED INJURY, WILL WE BE COMPENSATED?
        </h5>
        <p>
          In the unlikely event that you suffer an injury because of
          participating in this research, the University of Calgary, or the
          researchers will provide no compensation. You still have all your
          legal rights. Nothing said here will alter your right to seek damages.
        </p>
        <h5 className="lead mt-3">SIGNATURES</h5>
        <p>
          Your signature on this form indicates that you have understood to your
          satisfaction the information regarding you participation in the
          research project. In no way does this waive your legal rights nor
          release the investigators or involved institutions from their legal
          and professional responsibilities. You and/or your parent are free to
          withdraw from the study at any time without jeopardizing health care
          and/or education. If you have further questions related to this
          research, please contact:
        </p>
        <p>
          Study Research Coordinator at SHRedConcussions@ucalgary.ca or Carolyn
          Emery 403-220-4608
        </p>
        <p>
          If you have any questions concerning your rights as a possible
          research participant, or research in general, or if you feel you are
          being mistreated, please contact the Chair of the Conjoint Health
          Research Ethics Board, University of Calgary, at 403-220-7990.
        </p>
        <hr />
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Participant's Name: <span style={{ color: 'red' }}>*</span>
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_name"
              name="child_name"
              value={child_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-4 col-form-label">
            Birthdate: <span style={{ color: 'red' }}>*</span>
          </label>
          <div className={'col-lg-4 mb-2'}>
            <DatePicker
              id="sessionDate"
              className="form-control"
              name="sessionDate"
              maxDate={new Date()}
              placeholderText="mm/dd/yyyy"
              dropdownMode="select"
              showYearDropdown
              scrollableYearDropdown
              selected={child_dob}
              onChange={this.handleDobChange}
              onChangeRaw={this.handleDobChange}
              autoComplete="off"
            ></DatePicker>
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Email: <span style={{ color: 'red' }}>*</span>
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_email"
              name="child_email"
              value={child_email}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <br />
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Investigator/Delegate's Name:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="delegate_name"
              name="delegate_name"
              value={delegate_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">Witness' Name:</label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="witness_name"
              name="witness_name"
              value={witness_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <br />
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Parent/Guardian's Name:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_name"
              name="parent_name"
              value={parent_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Parent Email address:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_email"
              name="parent_email"
              value={parent_email}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Parent Phone Number:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_phone_number"
              name="parent_phone_number"
              value={parent_phone_number}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Please check this box if you are willing to be contacted for future
            studies:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="agree_to_be_contacted"
                  id="agree_to_be_contacted"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <hr />
        <p>
          The University of Calgary Conjoint Health Research Ethics Board has
          approved this research study.
        </p>
      </div>
    )
  }
}
