import React from 'react'

import moment from 'moment'
import { Trash2 } from 'react-feather'
import { Translate, withLocalize } from 'react-localize-redux'

import './WE.css'

class WESportForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    const { sportName, handleChange } = this.props

    const params = {
      sportName: sportName,
      field: name,
      value: value,
    }

    handleChange(params)
  }

  render() {
    const { sportName, otherSports, handleRemove, translate } = this.props

    return (
      <div>
        <div className="card we-card" style={{ padding: '30px' }}>
          <div>
            <Trash2
              size={26}
              className="fail-color"
              style={{
                cursor: 'pointer',
                float: 'right',
              }}
              onClick={() => handleRemove(sportName)}
            />
          </div>
          {sportName === 'other' ? (
            <h4>
              <select
                style={{ maxWidth: '15rem' }}
                className="primary-color form-control"
                name="sport_name"
                onChange={this.handleChange}
              >
                <option>{translate('WESportForm.selectSport')}</option>
                {otherSports &&
                  otherSports.map((sport, index) => {
                    return (
                      <option value={sport.name} key={sport.id}>
                        {sport.name}
                      </option>
                    )
                  })}
              </select>
            </h4>
          ) : (
            <h4 className="primary-color">{sportName}</h4>
          )}
          <span className="lead muted-color">
            {translate('WESportForm.sevenDayPeriod') +
              ': ' +
              moment()
                .subtract(1, 'weeks')
                .startOf('isoWeek')
                .format('dddd MMM Do') +
              ' - ' +
              moment()
                .subtract(1, 'weeks')
                .endOf('isoWeek')
                .format('dddd MMM Do')}
          </span>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <br />
            <div className="form-group row">
              <label className="col-lg-8 col-form-label">
                <Translate id="WESportForm.numberTrainingSessions" />:
              </label>
              <div className="col-lg-4">
                <input
                  className="form-control"
                  name="total_training"
                  onChange={this.handleChange}
                ></input>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-8 col-form-label">
                <Translate id="WESportForm.numberTrainingHours" />:
              </label>
              <div className="col-lg-4">
                <input
                  className="form-control"
                  name="total_training_hours"
                  onChange={this.handleChange}
                ></input>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-8 col-form-label">
                <Translate id="WESportForm.numberGames" />:
              </label>
              <div className="col-lg-4">
                <input
                  className="form-control"
                  name="total_games"
                  onChange={this.handleChange}
                ></input>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-8 col-form-label">
                <Translate id="WESportForm.numberGameHours" />:
              </label>
              <div className="col-lg-4">
                <input
                  className="form-control"
                  name="total_game_hours"
                  onChange={this.handleChange}
                ></input>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withLocalize(WESportForm)
