import React from 'react'
import ReactModal from 'react-modal'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { XCircle } from 'react-feather'
import { Translate, withLocalize } from 'react-localize-redux'

import { store } from '../../../helpers/index'
import { sportsEnrollmentServices } from '../../../services'
import '../Modal.css'
import { sportActions, alertActions } from '../../../actions'

class PBQModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedSports: [],
    }

    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  componentDidMount() {
    Modal.setAppElement('body')
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = store
    const { showModal, currentSports, allSports } = this.props

    if (showModal && !prevProps.showModal) {
      if (!allSports) dispatch(sportActions.get())

      let sports = []
      for (let i = 0; i < currentSports.length; i++)
        sports.push(currentSports[i].id)
      this.setState({
        selectedSports: sports,
      })
    }
  }

  handleCheckboxClick(e) {
    const { target } = e
    const { selectedSports } = this.state
    let newList = selectedSports

    if (target.id && target.checked === true) {
      newList.push(parseInt(target.id))
    } else if (target.id && target.checked === false) {
      newList.splice(newList.indexOf(parseInt(target.id)), 1)
    }
    this.setState({
      selectedSports: newList,
    })
  }

  handleSave() {
    const { dispatch } = store
    const { selectedSports } = this.state
    const { baseline_portal_id } = this.props

    const params = { sports: selectedSports, portal_id: baseline_portal_id }

    sportsEnrollmentServices.post(params).then(
      response => {
        dispatch(
          alertActions.success(
            this.props.translate('PBQModal.sportsEnrollmentSubmitted')
          )
        )
        this.props.handleAction()
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }

  render() {
    const { showModal, onClose, allSports } = this.props
    const { selectedSports } = this.state
    const getSportList = data => {
      const items = []
      if (data && data.length > 0) {
        data.map((sport, index) =>
          items.push(
            <div
              className="form-check"
              key={index}
              style={{ marginTop: '5px' }}
            >
              <input
                type="checkbox"
                className="form-check-input"
                id={sport.id}
                onChange={this.handleCheckboxClick}
                checked={selectedSports.includes(sport.id)}
              />
              <label className="form-check-label">{sport.name}</label>
            </div>
          )
        )
      }
      return items
    }

    return (
      <div>
        <ReactModal
          style={{
            overlay: { backgroundColor: 'rgba(33, 33, 33, 0.85)' },
            content: {
              maxWidth: 600,
              width: '80%',
              margin: 'auto',
            },
          }}
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          closeTimeoutMS={200}
          onRequestClose={onClose}
        >
          <XCircle
            size={32}
            onClick={onClose}
            style={{ cursor: 'pointer', float: 'right' }}
          />
          <div className="container mt-5">
            <h4>
              <Translate id="PBQModal.whichSportsDoYouPlay" />
            </h4>
            <br />
            {getSportList(allSports)}
            <br />
            <br />

            <button
              className="btn btn-primary col-lg-3 text-center"
              onClick={this.handleSave}
            >
              <Translate id="PBQModal.saveBtn" />
            </button>
          </div>
        </ReactModal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const allSports = state.sport.sports

  return {
    allSports,
  }
}

export default withLocalize(connect(mapStateToProps)(PBQModal))
