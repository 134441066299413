import React from 'react'
import { XCircle } from 'react-feather'

export const FormErrors = ({ formErrors }) => (
  <div>
    {Object.keys(formErrors).map((fieldName, i) => {
      if (formErrors[fieldName].length > 0) {
        return (
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className="alert-danger p-2"
            key={i}
          >
            <XCircle size={24} className="mr-2" />
            {formErrors[fieldName]}
          </div>
        )
      } else {
        return ''
      }
    })}
  </div>
)
