import { handleAxiosError } from '../helpers'
import { staticFileInstance } from '../helpers'

export const consentFormServices = {
  create,
}

function create(params) {
  return staticFileInstance
    .post('/consent-form', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
