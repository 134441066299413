import { handleAxiosError } from '../helpers'
import { instance } from '../helpers'

export const confirmationServices = {
  create,
  update,
}

function create(token) {
  return instance
    .post('/confirmation/' + token)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function update(token) {
  return instance
    .put('/confirmation/' + token)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
